import { useMemo, useState, useEffect } from 'react';
import { useRouteData } from '../../../providers/DataProvider/RouteDataContext';
import { FETCH_STATUS } from '../../../types/types';
import { setClientRouteDataKeyValue } from '../../../providers/DataProvider/RouteDataProvider.server';

type UseRouteDataSelectionHook = [any, FETCH_STATUS];

type UseRouteDataSelectionCallback = () => Promise<any>;

export function useRouteDataSelection<TDto = any>(
    key: string,
    callback?: UseRouteDataSelectionCallback,
    deps: any[] = [],
    refreshOnMount = false
): UseRouteDataSelectionHook {
    const routeData = useRouteData();

    const initialData = useMemo((): TDto => {
        return routeData[key];
    }, [routeData, key]);

    const [status, setStatus] = useState<FETCH_STATUS>(initialData ? FETCH_STATUS.COMPLETE : FETCH_STATUS.LOADING);

    const [data, setData] = useState<TDto>(initialData);

    useEffect(() => {
        setData(initialData);
        if (initialData) {
            setStatus(FETCH_STATUS.COMPLETE);
        }
    }, [initialData]);

    useEffect(() => {
        if ((initialData && !refreshOnMount) || !callback) {
            return;
        }

        if (!initialData) {
            setStatus(FETCH_STATUS.LOADING);
        }

        callback()
            .then((dataResponse) => {
                setClientRouteDataKeyValue(key, dataResponse);
                setData(dataResponse);
                setStatus(FETCH_STATUS.COMPLETE);
            })
            .catch((err) => {
                if (!initialData) {
                    setStatus(FETCH_STATUS.ERROR);
                }
            });
    }, [...deps, initialData, refreshOnMount, key]);

    return [data, status];
}

import { createServerCollectionAPI, API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { MediaDto } from '../../dtos/MediaDto';
import { CollectionStore } from './CollectionStore';

const API = createServerCollectionAPI<MediaDto>(API_COLLECTIONS.UPLOADS);

export class AttachmentsStore extends CollectionStore<MediaDto> {
    constructor() {
        super(API_COLLECTIONS.UPLOADS, 'id');
    }

    refresh() {
        return API.getAll()
            .then((attachments) => {
                this.initialized = true;
                this.state = attachments as unknown as MediaDto[];
                this.saveAll(attachments);
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

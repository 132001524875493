import { ContactDto, GuestDto } from '../../dtos/ContactDto';
import { getDtoMap } from '../../utils/getDtoMap';
import { uniqueArray } from '../../utils/uniqArray';

export const mapInvitesToGuest = (invites: number[], guests: GuestDto[], contacts: ContactDto[]) => {
    if (!guests.length || !contacts.length) {
        return [];
    }

    const guestsWithContact = guests
        .map((g) => {
            const contact = contacts.find((c: any) => c.guests.includes(g.id));
            if (contact) {
                return { ...g, contact };
            }
            return false;
        })
        .filter(Boolean);

    const guestsMap = getDtoMap(guestsWithContact, 'id');

    return invites
        .filter((gId) => !!guestsMap[gId])
        .map((gId) => {
            const guest = guestsMap[gId] as any;
            const contact = guest.contact;
            const contactGuests = contact.guests.filter((id) => !!guestsMap[id]).map((id) => guestsMap[id]);
            const allEmails = uniqueArray([
                (contact as ContactDto).emailAddress,
                ...contactGuests.map((g) => g.emailAddress),
            ]);

            return { ...guest, allEmails, contactGuests: contactGuests.map((g) => ({ ...g, allEmails })) };
        });
};

import { FEATURE_ACTIONS } from 'store/actionTypes';

export function featuresReducer(state = {}, action) {
    switch (action.type) {
        case FEATURE_ACTIONS.SET_FEATURES: {
            const { payload = {} } = action;
            return payload;
        }
        case FEATURE_ACTIONS.SET_FEATURE: {
            if (action.payload) {
                const { id } = action.payload;
                return {
                    ...state,
                    [id]: action.payload,
                };
            }
            return state;
        }
        default:
            return state;
    }
}

import React from 'react';
import loadable from '@loadable/component';
import { Box } from '@mui/material';
import styles from './RsvpPage.module.scss';
import { RsvpPageWrapper } from '../../components/RsvpPageWrapper/RsvpPageWrapper';

const RSVPPage = loadable(() => import(/* webpackChunkName: "RSVPPage" */ './RsvpPage'));

export function LazyRsvpPage() {
    return (
        <RsvpPageWrapper>
            <RSVPPage />
        </RsvpPageWrapper>
    );
}

import loadable from '@loadable/component';
import { GetDataOptions } from 'types/types';
import { LazyLabelTool } from 'views/Labels/LazyLabelTool';
import { LazyLoginPage } from 'views/LoginPage/LoginPage';
import { AddPage } from 'views/AddPage';
import { Pages } from 'store/actions/pages';
import { Stats } from 'views/Stats/Stats';
import { NotFound } from 'views/NotFound/NotFound';
import { LazyGuestsView } from 'views/GuestsView/LazyGuestsView';
import { LazyContactsList } from 'views/ContactsView/ContactsList/LazyContactsList';
import { LazyRsvpPage } from 'views/RsvpPage';
import { RoomsManager } from '../views/Rooms';
import { LazyHouses } from '../views/Rooms/Houses/LazyHouses';
import { LodgingSingle } from 'views/LodgingSingle/LodgingSingle';
import { RsvpContactPage } from '../views/RsvpContactPage';
import { ConfirmView } from '../views/RsvpPage/ConfirmView';
import RSVPSView from '../views/AllRsvps';
import { RehearsalDinner } from '../views/RehearsalDinner/RehearsalDinner';

const AddressCollector = loadable(
    () =>
        import(
            /* webpackChunkName: "AddressCollector" */
            'views/AddressCollector/AddressCollector'
        )
);

// This dynamic import will not be processed server-side
const ReactPageDemo = loadable(
    () =>
        import(
            /* webpackChunkName: "ReactPage" */
            'views/ReactPage/ReactPage'
        )
);

ReactPageDemo.getData = async ({ store, match }: GetDataOptions) => {
    return await store.dispatch(Pages.GET(match?.params?.slug || 'homepage'));
};

export const clientRoutes = [
    { path: '/rd', component: RehearsalDinner, exact: true },
    { path: '/', component: ReactPageDemo, exact: true },
    { path: '/lodging/:slug', component: LodgingSingle, exact: true },
    { path: '/onsite', component: LazyHouses, exact: true },
    { path: '/houses', component: RoomsManager, exact: false },
    { path: '/address', component: AddressCollector },
    { path: '/rsvp', component: LazyRsvpPage, exact: true },
    { path: '/rsvp/confirm/:guestId', component: ConfirmView, exact: true },
    { path: '/rsvp/:contactId', component: RsvpContactPage, exact: true },
    // { path: '/rsvp/:id?', component: LazyRsvpPage },

    { path: '/login', component: LazyLoginPage, exact: true },
    { path: '/tools/add-page', component: AddPage, exact: true },
    { path: '/tools/rsvps', component: RSVPSView, exact: true },
    { path: '/tools/labels', component: LazyLabelTool, exact: true },
    { path: '/guests', component: LazyGuestsView, exact: true },
    { path: '/contacts', component: LazyContactsList, exact: true },
    { path: '/stats', component: Stats, exact: false },

    {
        path: '/404',
        component: NotFound,
        exact: false,
    },
    {
        path: '/:slug',
        component: ReactPageDemo,
        exact: true,
    },
];

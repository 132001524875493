import { InputComponentProps } from 'components/InputText/InputComponentProps';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { PRIMARY_COLOR } from 'theme';
import inputStyles from '../../components/InputText/InputText.module.scss';
import styles from '../AddressField/AddressField.module.scss';

import { Box } from '@mui/material';
import clsx from 'clsx';
import { GuestDto } from 'dtos/GuestDto';
import { useGuestsContext } from '../../views/ContactsView/ContactsList/GuestsProvider';

const getTheme = (theme: any) => {
    return {
        ...theme,
        primary: PRIMARY_COLOR,
        primary25: PRIMARY_COLOR,
        primary50: PRIMARY_COLOR,
        primary75: PRIMARY_COLOR,
    };
};

interface GuestFieldProps extends InputComponentProps<GuestDto[]> {
    options: any[];
    tabIndex: number;
    hideValues?: boolean;
}

const isNumber = (val: any) => {
    return typeof val === 'number';
};

const getContactIds = (value: Array<GuestDto | number>): number[] => {
    if (Array.isArray(value)) {
        return value.map((val) => {
            if (isNumber(val)) {
                return val;
            }
            return (val as GuestDto).id;
        }) as number[];
    }
    return [];
};

export function GuestsField({
    field,
    labelProps,
    helpers,
    focused,
    label,
    meta,
    tabIndex,
    hideValues,
}: GuestFieldProps) {
    const { guests, createGuest } = useGuestsContext();
    const { value, onFocus, onBlur } = field;
    const { setValue } = helpers;
    const ref = useRef<any>(null);
    const [valueIds, setValueIds] = useState(getContactIds(value));

    const [inputValue, setInputValue] = useState<string>('');

    const onInputChange = useCallback((newValue: string) => {
        setInputValue(newValue);
    }, []);

    const selectedOptions = useMemo(() => {
        return guests.filter((option) => {
            return valueIds && valueIds.some((val) => val === option.value);
        });
    }, [guests, valueIds]);

    const onSelectionChange = useCallback(
        (options: any[]) => {
            const newVals = options.map((option) => option.value);
            setValueIds(newVals);
            setValue(newVals);
        },
        [setValue]
    );

    const onCreateGuest = useCallback(
        async (fullName: string) => {
            const data = await createGuest(fullName);
            if (data) {
                const newValues = [...valueIds, data.value] as any;
                setValueIds(newValues);
                setValue(newValues);
            }
        },
        [valueIds, setValue, createGuest]
    );

    return (
        <Box
            tabIndex={tabIndex}
            className={clsx(inputStyles.textInput, styles.addressField, inputStyles.labelTop, styles.guestsField, {
                [styles.focused]: focused,
                [styles.hasError]: meta.hasError,
            })}
            sx={{ 'input[role="combobox"]': { opacity: '1 !important' } }}
            ref={ref}
            position="relative"
        >
            <span className={clsx(inputStyles.label, styles.label)} {...labelProps}>
                {label}
            </span>
            <CreatableSelect
                isMulti
                name={field.name}
                value={selectedOptions}
                tabIndex={tabIndex}
                onChange={onSelectionChange}
                onCreateOption={onCreateGuest}
                controlShouldRenderValue={!hideValues}
                // getOptionValue={(guest) => }
                classNamePrefix="hl"
                onFocus={onFocus}
                onInputChange={onInputChange}
                onBlur={onBlur}
                inputValue={inputValue}
                placeholder={label}
                theme={getTheme}
                styles={{
                    container: (base, props) => {
                        return { ...base, height: '48px' };
                    },
                    dropdownIndicator: () => {
                        return { display: 'none' };
                    },
                    indicatorSeparator: () => {
                        return { display: 'none' };
                    },
                    control: (base, props) => {
                        const overrides = props.isFocused
                            ? {
                                  '&:hover': { borderColor: PRIMARY_COLOR },
                                  'borderColor': PRIMARY_COLOR,
                                  'boxShadow': `0 0 0 1px ${PRIMARY_COLOR}`,
                              }
                            : {};
                        return { ...base, ...overrides, height: '100%' };
                    },
                }}
                options={guests}
            />
        </Box>
    );
}

import { PAGE_ACTIONS } from 'store/actionTypes';

export default function pagesReducer(state = {}, action) {
    switch (action.type) {
        case PAGE_ACTIONS.SET_PAGES: {
            return action.payload;
        }
        case PAGE_ACTIONS.SET_PAGE:
        case PAGE_ACTIONS.SET_SINGLE_PAGE: {
            if (action.payload) {
                const { slug, id } = action.payload;
                return {
                    ...state,
                    [slug || id]: action.payload,
                };
            }
            return state;
        }
        case PAGE_ACTIONS.SET_PAGE_TYPE: {
            const { slug, pageType } = action.payload;
            const page = state[slug];
            if(page){
                return {
                    ...state,
                    [slug]: {
                        ...page,
                        pageType,
                    }
                }
            }
            return state;
        }
        default:
            return state;
    }
}

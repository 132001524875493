import { BOOK_ACTIONS } from "store/actionTypes";

export default function booksReducer(state = { data: [], meta: {} }, action) {
	switch (action.type) {
		case BOOK_ACTIONS.SET_BOOKS: {
			return action.payload;
		}
		default:
			return state;
	}
}

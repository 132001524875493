import React, { useCallback } from 'react';
import loadable from '@loadable/component';
import { Card, CardContent, Container, CardHeader } from '@mui/material';
import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import { renderLabel } from 'utils/renderLabel';

const AddPageForm = loadable(
    () =>
        import(
            /* webpackChunkName: "AddPageForm" */
            "./AddPageForm/AddPageForm"
        )
);

export function AddPage() {
    const adminUser = useLoggedInAdmin();

    const renderFormMarkup = useCallback(() => {
        if (!adminUser) {
            return <b>{renderLabel('AUTH_NEEDED')}</b>
        }
        return <AddPageForm />
    }, [adminUser])

    return (
        <Container sx={{ my: 3 }} maxWidth="sm">
            <Card variant='outlined'>
                <CardHeader title={renderLabel('ADD_PAGE')} />
                <CardContent>
                    {renderFormMarkup()}
                </CardContent>
            </Card>
        </Container>
    )
}

import DownloadIcon from '@mui/icons-material/Download';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/CalendarMonth';

const GOOGLE = "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240629%2F20240630&details=Cody%20Suher%20and%20Henry%20Latour%20are%20getting%20married%20on%20June%2029%2C%202024%20at%20the%20Wilburton%20Inn%20in%20Manchester%2C%20VT.%20We%20are%20very%20excited%20to%20share%20our%20special%20day%20with%20friends%20and%20family.%0A%0A257%20Wilburton%20Drive%20Manchester%2C%20VT&location=Wilburton%20Inn%2C%20257%20Wilburton%20Drive%20Manchester%2C%20VT&text=Cody%20and%20Henry%27s%20Big%20Day";

const OUTLOOK = "https://outlook.live.com/calendar/0/deeplink/compose?body=Cody%20Suher%20and%20Henry%20Latour%20are%20getting%20married%20on%20June%2029%2C%202024%20at%20the%20Wilburton%20Inn%20in%20Manchester%2C%20VT.%20We%20are%20very%20excited%20to%20share%20our%20special%20day%20with%20friends%20and%20family.%0A%0A257%20Wilburton%20Drive%20Manchester%2C%20VT&enddt=20240630&location=Wilburton%20Inn%2C%20257%20Wilburton%20Drive%20Manchester%2C%20VT&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=20240629&subject=Cody%20and%20Henry%27s%20Big%20Day";

const DOWNLOAD = "https://codyandhenry.com/uploads/wedding_573fe7af63.ics?updated_at=2023-10-18T18:56:16.462Z";

const ICONS = {
    GOOGLE: GoogleIcon,
    OUTLOOK: MicrosoftIcon,
    DOWNLOAD: DownloadIcon,
}

const LABELS = {
    GOOGLE: 'Add to Google Calendar',
    OUTLOOK: 'Add to Outlook Calendar',
    DOWNLOAD: 'Add to Other Calender',
}

const LINKS  = {
    GOOGLE,
    OUTLOOK,
    DOWNLOAD,
};

const ITEMS = Object.keys(LINKS);

export const CALENDER_ITEMS = ITEMS.map(key => ({
    href: LINKS[key],
    Icon: ICONS[key],
    label: LABELS[key],
    key,
}));
import { EventDto } from 'dtos/EventDto';
import React, { useState } from 'react';
import { renderLabel } from 'utils/renderLabel';
import styles from './Event.module.scss';
import { EventActions } from './EventActions';
import { FoodSelectionsField } from '../../../../views/RsvpContactPage/FoodSelectionsField';
import { Collapse, Box, Button } from '@mui/material';
import { formatIsoDate } from '../../../../utils/formatDate';

interface EventProps {
    event: EventDto;
}

export function Event({ event }: EventProps) {
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const { title, venue, venueLink, description, dressCode, eventName, eventDate, eventDateString, location } = event;

    const toggleDetails = () => setShowDetails(!showDetails);

    const renderDate = () => {
        if (eventDateString) {
            return eventDateString;
        }
        return formatIsoDate(eventDate.toString());
    };

    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <h1>{title}</h1>
            </div>
            <div className={styles.details}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <span className={styles.subHeader}>{renderDate()}</span>
                    <Button onClick={toggleDetails} color="primary" className={styles.toggle} variant="text">
                        {showDetails ? 'Hide details' : 'See event details'}
                    </Button>
                </Box>
            </div>
            <Collapse in={showDetails}>
                <div className={styles.body}>
                    <p>
                        <a href={venueLink} target="_blank" rel="noreferrer">
                            {venue}
                        </a>{' '}
                        | {location}
                    </p>
                    <p>{description}</p>
                    <p>{renderLabel('DRESS_CODE', dressCode)}</p>
                </div>
            </Collapse>
            <div className={styles.actions}>
                <EventActions name={eventName} />
                {event.eventName === 'reception' && <FoodSelectionsField />}
            </div>
        </div>
    );
}

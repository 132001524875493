import { createSelector } from 'reselect';
import { getState } from './state';
import { ACTIVITIES_INITIAL_STATE } from 'store/reducers/activitiesReducer';

export const selectActivitiesState = createSelector(getState, (state) => state.activities || ACTIVITIES_INITIAL_STATE);

export const selectActivities = createSelector(selectActivitiesState, (activities) => activities?.data || []);

export const selectActivitiesPagination = createSelector(selectActivitiesState, (state) => state.pagination || {});

export const selectActivitiesFilters = createSelector(selectActivitiesState, (state) => state.filters);

export const selectActivitiesPopulation = createSelector(selectActivitiesState, (state) => state.populate || []);

export const selectActivitiesApiParams = createSelector(
    [selectActivitiesPagination, selectActivitiesFilters, selectActivitiesPopulation],
    (pagination, filters, populate) => ({ pagination, filters, populate })
);

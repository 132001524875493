import { useCookie } from "hooks/useCookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initializeUser } from "store/actions/user";

export function useInitializeUser() {
  const [jwt] = useCookie("auth");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!jwt) {
      return;
    }
    dispatch(initializeUser(jwt));
  }, [jwt, dispatch]);
}

import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { selectIsMobile } from 'store/selectors/env';

function isHashMatch(hashToCheck: string) {
    return window.location.hash.indexOf(hashToCheck) > -1;
}

export function useHashToggle(hashToCheck: string) {
    const location = useLocation();
    const history = useHistory();
    const isMobile = useSelector(selectIsMobile);
    const [open, setOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(isMobile);

    const openForm = useCallback(() => {
        history.push({ ...location, hash: hashToCheck });
    }, [history, location, hashToCheck]);

    const closeForm = useCallback(() => {
        history.push({ ...location, hash: '' });
    }, [location, history]);

    useEffect(() => {
        const isOpen = isHashMatch(hashToCheck);
        setOpen(isOpen);
        setIsFullScreen(window.innerWidth < 748);

        const onHashChange = () => {
            const isOpen = isHashMatch(hashToCheck);
            setOpen(isOpen);
        };

        window.addEventListener('hashchange', onHashChange, false);

        return () => {
            window.removeEventListener('hashchange', onHashChange, false);
        };
    }, [location, hashToCheck]);

    return { open, openForm, isFullScreen, closeForm };
}

import React, { CSSProperties, MouseEvent, useEffect, useRef, useState } from 'react';
import { CtaDto } from 'dtos/components/CtaDto';
import { Box, ButtonProps } from '@mui/material';
import { CtaButton } from 'components/CtaButton/CtaButton';
import { useLocation } from 'react-router';
import useConstructibleRef from '../../hooks/useConstructibleRef';
import { createPortal } from 'react-dom';
import canUseDOM from '../../helpers/canUseDom';
import { useIsMobile } from '../../hooks/useIsMobile';

interface CtaButtonListProps {
    itemFontSize?: number;
    className?: string;
    ctas: CtaDto[];
    bold?: boolean;
    btnProps?: Partial<ButtonProps>;
    isMainNav?: boolean;
    showHoverIndicator?: boolean;
}

export function CtaButtonList({
    className,
    ctas,
    itemFontSize,
    bold,
    btnProps = {},
    isMainNav,
    showHoverIndicator = false,
}: CtaButtonListProps) {
    const isMobile = useIsMobile();
    const activeRef = useRef<HTMLAnchorElement>();
    const location = useLocation();
    const [activeIndicatorStyles, setActiveIndicatorStyles] = useState<CSSProperties>({});

    const APP_HEADER = useConstructibleRef(
        () => canUseDOM() && (document.getElementById('APP_HEADER') as HTMLDivElement)
    );

    useEffect(() => {
        if (!activeRef.current || isMobile || !showHoverIndicator) {
            setActiveIndicatorStyles({});
            return;
        }

        const handleResize = () => {
            const { width, left } = activeRef.current.getBoundingClientRect();
            setActiveIndicatorStyles({ width, left });
        };

        handleResize();

        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize, false);
        };
    }, [location.pathname, isMobile, showHoverIndicator]);

    const renderIndicators = () => {
        if (!APP_HEADER || !showHoverIndicator) {
            return null;
        }

        return (
            <>
                {createPortal(
                    <Box
                        id="activeIndicator"
                        sx={{ background: 'var(--blue-300)', transition: 'left ease-in-out .25s', borderRadius: 1 }}
                        position="absolute"
                        height={1.5}
                        bottom={10}
                        style={activeIndicatorStyles}
                    />,
                    APP_HEADER
                )}
            </>
        );
    };

    return (
        <Box
            component="nav"
            className={className}
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop={2}
            paddingBottom={2}
            gap={3}
        >
            {ctas.map((cta) => {
                return (
                    <CtaButton
                        activeRef={activeRef}
                        key={cta.id || cta.label}
                        bold={bold}
                        {...cta}
                        btnProps={btnProps}
                        fontSize={cta.fontSize || itemFontSize}
                        isInList
                        isMainNav={isMainNav}
                    />
                );
            })}
            {renderIndicators()}
        </Box>
    );
}

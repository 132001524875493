import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import React, { useEffect, useState, lazy, Suspense } from 'react';

const ApiMessages = lazy(() => import('./ApiMessages'));

export function LazyApiMessages() {
    const [showMessages, setShowMessages] = useState(false);
    const isAdmin = useLoggedInAdmin();

    useEffect(() => {
        setShowMessages(isAdmin);
    }, [isAdmin]);

    if (!showMessages) {
        return null;
    }

    return (
        <Suspense fallback={null}>
            <ApiMessages />
        </Suspense>
    );
}

import { getState } from './state';
import { createSelector } from 'reselect';

export const selectRouter = createSelector(getState, (state) => state.router);

export const selectLocation = createSelector(selectRouter, (state) => state.location);

export const selectSearchString = createSelector(selectLocation, (state) => state.search);

export const selectFilterQuery = createSelector(selectLocation, (state) => state.query);

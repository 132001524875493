import canUseDOM from '../../helpers/canUseDom';
import { isProduction } from '../../helpers/env';
import { BaseDto } from '../../types/types';
import { AttachmentsStore } from './AttachmentsStore';
import { getCollectionPluralName } from './CollectionStore';
import { ContactsStore } from './ContactsStore';
import { EventsStore } from './EventsStore';
import { GuestsStore } from './GuestsStore';
import { HousesStore } from './HousesStore';
import { LabelsStore } from './LabelsStore';
import { MealsStore } from './MealsStore';
import { NavsStore } from './NavsStore';
import { PagesStore } from './PagesStore';
import { RsvpStore } from './RsvpsStore';
import { SessionsStore } from './SessionsStore';

export const services = {
    uploads: new AttachmentsStore(),
    pages: new PagesStore(),
    navs: new NavsStore(),
    labels: new LabelsStore(),
    houses: new HousesStore(),
    guests: new GuestsStore(),
    contacts: new ContactsStore(),
    rsvps: new RsvpStore(),
    events: new EventsStore(),
    sessions: new SessionsStore(),
    meals: new MealsStore(),
};

export async function setupServices() {
    const promises = Object.keys(services).map(async (key) => {
        const service = services[key];
        if (isProduction()) {
            try {
                await service.refresh();
            } catch (err) {
                console.log(err);
                await service.initialize();
            }
        } else {
            await service.initialize();
        }
    });

    await Promise.all(promises);

    return services;
}

export interface WebhookRequest {
    event: 'entry.update' | 'entry.create' | 'media.create' | 'media.update' | 'media.delete';
    model: string;
    uid: string;
    entry: BaseDto;
}

export const refreshService = (requestBody: WebhookRequest) => {
    const { model, entry, event } = requestBody;
    const serviceName = getCollectionPluralName(model as any);
    let service = services[serviceName];

    if (!service && serviceName === 'react-pages') {
        service = services.pages;
    }

    if (event.includes('media')) {
        service = services.uploads;
    }

    if (service) {
        service.refresh(entry);
    }
};

if (canUseDOM()) {
    throw new Error('this is server only code');
}

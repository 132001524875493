import { GuestDto } from 'dtos/GuestDto';
import { ListPayload } from 'dtos/ListPayload';
import { GUEST_ACTIONS } from 'store/actionTypes';

export const GUESTS_INITIAL_STATE: ListPayload<GuestDto[]> = {
    data: [],
    filters: {},
    pagination: {
        page: 1,
        pageCount: 1,
        pageSize: 50,
        total: 0,
    },
    populate: ['contactDetails', 'contact', 'contact.contactDetails'],
    sort: ['contact.id:asc', 'contact.createdAt:desc'],
};

export function guestsReducer(state = GUESTS_INITIAL_STATE, action) {
    switch (action.type) {
        case GUEST_ACTIONS.GET_ALL: {
            return action.payload;
        }
        case GUEST_ACTIONS.SET_GUEST: {
            const { id } = action.payload;
            const { data } = state;

            return {
                ...state,
                data: data.map((activity) => {
                    return activity.id === id ? action.payload : activity;
                }),
            };
        }
        case GUEST_ACTIONS.DELETE_GUEST: {
            const { data } = state;
            return {
                ...state,
                data: data.filter((activity) => activity.id !== action.payload),
            };
        }
        case GUEST_ACTIONS.ADD_GUEST: {
            const { data } = state;

            return {
                ...state,
                data: [...data, action.payload],
            };
        }

        case GUEST_ACTIONS.SET_GUESTS_FILTERS: {
            const { filters } = state;
            console.log(action);
            return {
                ...state,
                filters: { ...filters, ...action.payload },
            };
        }

        case GUEST_ACTIONS.SET_PAGE_NUMBER: {
            const { pagination } = state;

            return {
                ...state,
                pagination: {
                    ...pagination,
                    page: action.payload,
                },
            };
        }

        default:
            return state;
    }
}

import { USER_ACTIONS } from "store/actionTypes";

export default function loggedInUserReducer(state = false, action) {
	switch (action.type) {
		case USER_ACTIONS.REGISTER:
		case USER_ACTIONS.LOGIN:
		case USER_ACTIONS.SET_USER: {
			return action.payload;
		}
		case USER_ACTIONS.LOGOUT: {
			return false;
		}
		default:
			return state;
	}
}

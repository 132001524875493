import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { useLodgingItem } from './useLodgingItem';
import { GetDataOptions } from 'types/types';
import { LodgingAPI } from 'api/LodgingAPI';
import { Box } from '@mui/material';
import { useIsMobile } from 'hooks/useIsMobile';

const LodgingSingleMobile = loadable(
    () =>
        import(
            /* webpackChunkName: "LodgingSingleMobile" */
            'views/LodgingSingle/MobileSingle/MobileSingle'
        )
);

const LodgingSingleDesktop = loadable(
    () =>
        import(
            /* webpackChunkName: "LodgingSingleDesktop" */
            'views/LodgingSingle/DesktopSingle/DesktopSingle'
        )
);

export function LodgingSingle() {
    const { item, setItem, status } = useLodgingItem();
    const isMobile = useIsMobile();

    const SingleComponent = useMemo(() => {
        return isMobile ? LodgingSingleMobile : LodgingSingleDesktop;
    }, [isMobile]);

    if (!item) {
        return null;
    }

    return (
        <Box minHeight={`calc(100vh - 50px)`}>
            <SingleComponent item={item} setItem={setItem} />
        </Box>
    );
}

LodgingSingle.getData = async ({ store, match }: GetDataOptions) => {
    const slug = match?.params?.slug;
    const data = await store.dispatch(LodgingAPI.get(slug));
    return data;
};

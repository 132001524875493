export interface BaseDto {
    id: number;
}

export function getDtoId(dto?: BaseDto | number | null): number | null {
    return dto && typeof dto === 'object' ? dto.id : (dto as number | null);
}

export function getDtoIds(dtos: Array<BaseDto | number>): number[] {
    if (!dtos) {
        return [];
    }
    return dtos.map(getDtoId);
}

import { API_COLLECTIONS, createServerCollectionAPI } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';
import { GuestDto } from '../../dtos/GuestDto';
import { stringSimilarity } from '../../utils/stringSimilarity';
import { services } from './services';
import { isProduction } from 'helpers/env';

interface GuestWithSearchSimilarity {
    similarity: number;
    guest: GuestDto;
}

const getSimilaritiesForSearch = (guests: GuestDto[], search: string, isFiltered: boolean) => {
    const THRESHOLD = !isFiltered ? 0.5 : 0.25;

    const mapBySimilarity = (guest: GuestDto): GuestWithSearchSimilarity => {
        const { fullName } = guest;
        const similarity = stringSimilarity(fullName, search);
        return { similarity, guest };
    };

    const filterBySimilarity = (item: GuestWithSearchSimilarity) => {
        return item.similarity > THRESHOLD;
    };

    const sortBySearchSimilarity = (item1: GuestWithSearchSimilarity, item2: GuestWithSearchSimilarity) => {
        return item1.similarity > item2.similarity ? -1 : 1;
    };

    return guests
        .map(mapBySimilarity)
        .filter(filterBySimilarity)
        .sort(sortBySearchSimilarity)
        .map(({ guest, similarity }) => ({ ...guest, similarity }));
};

export class GuestsStore extends CollectionStore<GuestDto> {
    constructor() {
        super(API_COLLECTIONS.GUESTS, 'id', { url: '/api/store/guests' });
    }

    getGuestsWithContact() {
        return this.data.filter((g) => !!g.contact);
    }

    getGuestsWithoutContact() {
        return this.data.filter((g) => !g.contact);
    }

    getOne(guestId: number | string) {
        const guest = this.stateMap[guestId] as unknown as GuestDto;
        const contactId = guest?.contact?.id;

        if (contactId) {
            return { ...guest, contact: services.contacts.getOne(contactId) };
        }

        return guest;
    }

    find(search: string) {
        const allGuests = this.getGuestsWithContact();

        const filteredGuests = allGuests.filter((g) => {
            const { fullName } = g;
            const fName = fullName.toLowerCase();
            return fName.includes(search);
        });

        const guestsForSearch = filteredGuests.length ? filteredGuests : allGuests;

        const results = getSimilaritiesForSearch(guestsForSearch, search, filteredGuests.length > 0);

        if (results.length) {
            const guest = results[0];
            return this.getOne(guest.id);
        }

        return undefined;
    }
}

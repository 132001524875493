import { FieldValidator } from 'formik';
import React, { ForwardedRef, forwardRef } from 'react';
import { useFormField } from './useFormFieldProps';
import { InputComponentOptionalProps, InputComponentProps } from './InputComponentProps';
import { InputText } from './InputText';

export interface WrappedFormFieldProps<TValue> extends InputComponentOptionalProps {
    name: string;
    label: string;
    submitOnBlur?: boolean;
    component?: React.FC<InputComponentProps<TValue>>;
    validate?: FieldValidator;
}

const FieldWithFormik = forwardRef(function WrappedFormField<TValue>(
    {
        name,
        label,
        validate,
        submitOnBlur,
        component: InputComponent = InputText,
        ...componentProps
    }: WrappedFormFieldProps<TValue>,
    ref?: ForwardedRef<unknown>
) {
    const formFieldProps = useFormField<TValue>(name, { validate, autoSave: submitOnBlur });

    return <InputComponent {...formFieldProps} {...componentProps} ref={ref} label={label} />;
});

export const WrappedFormField = FieldWithFormik as <TValue = string>(
    props: WrappedFormFieldProps<TValue> & { ref?: React.Ref<HTMLInputElement> }
) => ReturnType<typeof FieldWithFormik>;

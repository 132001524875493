import { MediaDto } from '../dtos/MediaDto';

const getApiUrl = (src?: string) => {
    return src;
};

const sizes = ['large', 'medium', 'small', 'thumbnail'];

export const getSrcSet = (formats: any = {}) => {
    return sizes
        .reduce((filterMap: string[], s) => {
            const format: any = formats[s];
            if (format) {
                filterMap.push(`${getApiUrl(format.url)} ${format.width}w`);
            }
            return filterMap;
        }, [])
        .join();
};

export enum SizeTypes {
    THUMB = 'thumbnail',
    SMALL = 'small',
    LARGE = 'large',
    MEDIUM = 'medium',
    DEFAULT = 'default',
}

export function getImageProps(upload?: any | string, size: SizeTypes | string = SizeTypes.DEFAULT): any {
    if (!upload) {
        return undefined;
    }
    if (typeof upload === 'string') {
        return {
            src: upload,
            alt: upload,
        };
    }
    const { url, name, alternativeText, id } = upload;
    const formats: any = upload.formats || {};
    const props = {
        'alt': alternativeText || name,
        'src': getApiUrl(url),
        'srcSet': getSrcSet(formats),
        'data-asset-id': id,
        id,
    };
    if (size && formats[size]) {
        props.src = getApiUrl(formats[size].url);
    }
    return props;
}

export function getSmallestFormat(image: MediaDto): string {
    const formats = image?.formats || {};
    const key = sizes.reverse().find((size) => !!formats[size]);
    if (key) {
        return image.formats[key].url;
    }
    return image.url;
}

import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';
import { EventDto } from '../../dtos/EventDto';

export class EventsStore extends CollectionStore<EventDto> {
    constructor() {
        super(API_COLLECTIONS.EVENTS, 'id');
    }

    getAll() {
        return this.state.sort((a: EventDto, b: EventDto) => (a.eventDate > b.eventDate ? 1 : -1));
    }
}

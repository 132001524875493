import { BREAKPOINTS } from './SxResponsiveField';
import { SxProps } from '@mui/material';
import { ResponsiveFieldValue, SxItemProps } from './SxFieldItem';

export const isObject = (value: any) => typeof value === 'object';

export const getType = (value: any) => {
    if (isObject(value)) {
        const [firstVal] = Object.values(value).filter(Boolean);
        return firstVal ? typeof firstVal : 'string';
    }

    return value ? typeof value : 'string';
};

export const buildSxPropsArray = (sxProps: SxProps = {}, withBlank = true) => {
    const items = Object.keys(sxProps)
        .sort()
        .reduce((items: SxItemProps[], key, index) => {
            const value = sxProps[key];
            return [
                ...items,
                {
                    id: index + 1,
                    values: { key, value },
                    type: getType(value),
                    responsive: isObject(value),
                },
            ];
        }, []);
    if (items.length === 0 && withBlank) {
        return [{ id: 1, type: 'string', values: {} }];
    }
    return items;
};

const isEmptyObject = (obj: ResponsiveFieldValue = {}) => Object.keys(obj).length === 0;

const convertResponsiveValues = (values: ResponsiveFieldValue) => {
    const responsiveValues = BREAKPOINTS.reduce((nextValues: ResponsiveFieldValue, key) => {
        const val = values[key];
        if (val !== undefined) {
            return { ...nextValues, [key]: convertValue(val) };
        }
        return nextValues;
    }, {});
    if (isEmptyObject(responsiveValues)) {
        return undefined;
    }
    return responsiveValues;
};

const convertStringValue = (str: string) => {
    if (str.length === 0) {
        return undefined;
    }
    try {
        const num = Number(str);
        return !isNaN(num) ? num : str;
    } catch (err) {
        return str;
    }
};

export const convertValue = (value: SxItemProps['values']['value']) => {
    if (isObject(value)) {
        return convertResponsiveValues(value as ResponsiveFieldValue);
    }

    if (!value) {
        return undefined;
    }

    return convertStringValue(value.toString());
};

export const buildSxPropsFromArray = (items: SxItemProps[]) => {
    return items.reduce((sxProps: SxProps, item) => {
        const { key, value } = item.values;
        const newValue = convertValue(value);

        if (key === 'backgroundColor') {
            console.log({ key, value, newValue });
        }

        if (key && newValue) {
            return {
                ...sxProps,
                [key]: newValue,
            };
        }
        return sxProps;
    }, {});
};

export const getValueFromResponsiveField = (value: ResponsiveFieldValue) => {
    return BREAKPOINTS.reduce((newValue: number | string, breakpoint) => {
        if (!newValue && value[breakpoint]) {
            newValue = value[breakpoint];
        }
        return newValue;
    }, undefined);
};

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { CircularProgress, Container } from '@mui/material';

const HouseProvider = lazy(() => import('./HouseProvider'));

export function RoomsManager() {
	const [showRooms, setShowRooms] = useState(false);

	useEffect(() => {
		setShowRooms(true);
	}, []);

	const loader = (
		<Container sx={{ minHeight: 600, my: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<CircularProgress />
		</Container>
	);

	if (!showRooms) {
		return loader
	}
	
	return (
		<Suspense fallback={loader}>
			<HouseProvider />
		</Suspense>	
	)
}
import React from 'react';
import { InputComponentProps } from 'components/InputText/InputComponentProps';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { WrappedFormField } from 'components/InputText/WrappedInputText';
import styles from './Event.module.scss';
import { Box, Button } from '@mui/material';
import { renderLabel } from 'utils/renderLabel';
import clsx from 'clsx';

function Actions({ field, helpers, label }: InputComponentProps<boolean>) {
    const value = field.value;

    const onNoClick = () => {
        helpers.setValue(false);
    };

    const onYesClick = () => {
        helpers.setValue(true);
    };

    const yesIcon = value ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />;
    const noIcon = !value ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />;

    return (
        <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
        >
            <Box component="span" fontSize={13} fontWeight={100}>
                RSVP
            </Box>
            <Box justifyContent="center" gap={1} alignItems="center" display="flex">
                <Button
                    className={clsx(styles.btn, { [styles.btnSelected]: value })}
                    onClick={onYesClick}
                    color="primary"
                    size="small"
                    startIcon={yesIcon}
                    variant="text"
                >
                    Yes
                </Button>
                <Button
                    className={clsx(styles.btn, { [styles.btnSelected]: !value, [styles.btnSelectedError]: !value })}
                    onClick={onNoClick}
                    color="primary"
                    startIcon={noIcon}
                    variant="text"
                >
                    No
                </Button>
            </Box>
        </Box>
    );
}

export function EventActions({ name }: { name: string }) {
    return <WrappedFormField component={Actions} name={name} label={renderLabel('ATTENDING')} />;
}

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { Link } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { FETCH_STATUS, GetDataOptions } from '../../types/types';
import styles from './RsvpContactPage.module.scss';
import { setSsrRouteDataKeyValue } from '../../providers/DataProvider/RouteDataProvider.server';
import { useRouteDataSelection } from '../ReactPage/hooks/useRouteDataSelection';
import { NewRsvpForm } from './NewRsvpForm';
import { RsvpPageWrapper } from '../../components/RsvpPageWrapper/RsvpPageWrapper';
import { RsvpConfirmedView } from './RsvpConfirmedView/RsvpConfirmedView';
import { getRsvpFormData, RsvpFormDataProps } from 'api/RsvpFormAPI';

interface RouteParams {
    contactId?: string;
}

const getContactKey = (id: number | string) => `contact${id}`;

export function RsvpContactPage() {
    const { contactId } = useParams<RouteParams>() || {};
    const [rsvpFormData, status] = useRouteDataSelection(
        getContactKey(contactId),
        () => {
            return getRsvpFormData(contactId);
        },
        [contactId]
    );

    const [error, setError] = useState<Error>();

    const renderLoader = useCallback(() => {
        return (
            <Box className={styles.loader}>
                <CircularProgress sx={{ fontSize: 36 }} />
            </Box>
        );
    }, []);

    const renderRsvpForm = useCallback(() => {
        const { contact } = rsvpFormData;
        if (contact?.rsvp?.id) {
            return <RsvpConfirmedView {...rsvpFormData} />;
        } else if (contact) {
            return <NewRsvpForm {...rsvpFormData} />;
        }
        return null;
    }, [rsvpFormData]);

    const renderError = useCallback(() => {
        if (error) {
            return (
                <Box className={styles.error}>
                    <Box>{error?.toString()}</Box>
                    <Box>
                        <pre>{JSON.stringify(error, null, 2)}</pre>
                    </Box>
                </Box>
            );
        }
        return null;
    }, [error]);

    const renderContent = () => {
        switch (status) {
            case FETCH_STATUS.ERROR:
                return renderError();
            case FETCH_STATUS.COMPLETE:
                return renderRsvpForm();
            case FETCH_STATUS.LOADING:
            default:
                return renderLoader();
        }
    };

    return <RsvpPageWrapper isOutlined={false}>{renderContent()}</RsvpPageWrapper>;
}

RsvpContactPage.getData = async ({ match, store, $ }: GetDataOptions) => {
    const contactId = parseInt(match.params?.contactId);
    if (contactId) {
        const { services } = require('server/collections/services');
        setSsrRouteDataKeyValue(getContactKey(contactId), {
            contact: services.contacts.getOne(contactId),
            meals: services.meals.data,
            events: services.events.data,
        });
    }
};

import { Box, BoxProps, SxProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { DEFAULT_BORDER } from '../../../theme';

interface DataRowProps extends BoxProps {
    label: ReactNode;
    total: ReactNode;
    fontSize?: number;
    noBorder?: boolean;
    className?: string;
    boldedTitle?: boolean;
    totalSx?: SxProps;
    padding?: any;
}

export function DataRow({
    label,
    total,
    fontSize = 14,
    noBorder,
    className,
    padding = 1,
    boldedTitle = true,
    totalSx,
    ...boxProps
}: DataRowProps) {
    return (
        <Box
            padding={padding}
            {...boxProps}
            className={className}
            display="flex"
            alignItems="center"
            gap={2}
            justifyContent="space-between"
            fontSize={fontSize}
            borderTop={!noBorder && DEFAULT_BORDER}
        >
            <Box>{label}</Box>
            <Box fontWeight={boldedTitle && 'bold'} sx={totalSx}>
                {total}
            </Box>
        </Box>
    );
}

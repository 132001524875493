import React, { useState, useEffect, ReactNode } from 'react';
import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import loadable from '@loadable/component';

interface AdminOnlyProps {
    children?: ReactNode | ReactNode[];
    importFile?: string;
    showFallback?: boolean;
}

const AsyncComponent = loadable((props) => import(`${props.importFile}`));

export function AdminOnly({ children, importFile, showFallback }: AdminOnlyProps) {
    const isAdmin = useLoggedInAdmin();
    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
        setShowComponent(isAdmin);
    }, [isAdmin]);

    if (!isAdmin && showFallback) {
        return <b>You must be logged in to see this content</b>;
    }

    if (!showComponent) {
        return null;
    }

    if (importFile) {
        return <AsyncComponent importFile={importFile} />;
    }

    return <>{children}</>;
}

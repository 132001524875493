import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Loader } from './components/Loader/Loader';
import { useLoggedInAdmin } from 'hooks/useLoggedInUser';

const StatsProvider = lazy(() => import('./providers/StatsProvider'));

export function Stats() {
    const [showStats, setShowStats] = useState(false);
    const admin = useLoggedInAdmin();

    useEffect(() => {
        if (!admin) {
            window.location.hash = '#login';
        } else {
            setShowStats(true);
        }
    }, [admin]);

    if (!showStats) {
        return <Loader />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <StatsProvider />
        </Suspense>
    );
}

import { AnyAction } from 'redux';
import { ROUTER_ACTIONS } from 'store/actionTypes';

export function routerReducer(state = {}, action: AnyAction) {
    switch (action.type) {
        case ROUTER_ACTIONS.SET_ROUTER_LOCATION: {
            return action.payload;
        }
        default:
            return state;
    }
}

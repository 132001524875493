import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';
import { ReactPageDto } from '../../dtos/ReactPageDto';
import { getDtoIds } from '../../utils/getDtoId';

export class PagesStore extends CollectionStore<ReactPageDto> {
    constructor() {
        super(API_COLLECTIONS.REACT_PAGES, 'slug', { populate: 'attachments' });

        this.setupOne = this.setupOne.bind(this);
    }

    async refresh(item?: ReactPageDto): Promise<void> {
        try {
            await super.refresh();
            const allPages = this.state;

            if (item && item.slug) {
                await this.refreshOne(item);
                return;
            }

            const promises = allPages.map(this.setupOne);
            await Promise.all(promises);
        } catch (err) {
            console.log(err);
        }
    }

    async refreshOne(page: ReactPageDto) {
        await this.setupOne(page);
    }

    async getOne(key: string | number) {
        const filePath = this.getFilePath(key);
        return this.loadFileData(filePath);
    }

    async setupOne(data: ReactPageDto) {
        const { slug } = data;
        try {
            const data = await this.API.get(slug as any, { url: `/api/page/${slug}`, populate: 'deep,6' }).then(
                this.transformSingleResponse as any
            );
            return this.saveOne(slug, data);
        } catch (err) {
            console.log(err);
        }
    }

    transformListResponse(items: ReactPageDto[]) {
        return items.map((item) => ({
            ...item,
            attachments: getDtoIds(item?.attachments),
        }));
    }
}

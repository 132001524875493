import React, { MouseEvent } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { renderLabel } from 'utils/renderLabel';
import { useHashToggle } from 'hooks/useHashToggle';

export function AddLabelButton({ variant = 'outlined', size = 'small', color = 'primary' }: ButtonProps) {
    const { openForm } = useHashToggle('addLabel');

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        openForm();
    };

    return (
        <Button onClick={handleClick} variant={variant} size={size} color={color}>
            {renderLabel('ADD_LABEL')}
        </Button>
    );
}

import { PagesAPI } from 'api/PagesAPI';
import { Attachments } from './attachments';
import { PAGE_ACTIONS } from 'store/actionTypes';
import { ReactPageDto } from 'dtos/ReactPageDto';
import { AppThunk } from 'types/types';
import { ActivityActions } from 'actions/Activities';
import { selectPages } from 'store/selectors/pages';
import { LodgingAPI } from 'api/LodgingAPI';
import { Cell, Row } from '@react-page/editor';

function SET_PAGE(page: ReactPageDto): AppThunk<Promise<void>> {
    return (dispatch, _getState) => {
        if (page && page.slug) {
            const attachments = page.attachments || [];

            if (attachments?.length) {
                dispatch(Attachments.ADD(page.attachments));
            }

            dispatch({
                type: PAGE_ACTIONS.SET_PAGE,
                payload: page,
            });
        }
        return Promise.resolve();
    };
}

function SAVE(page: ReactPageDto): AppThunk<Promise<ReactPageDto>> {
    return async (dispatch) => {
        const payload = await PagesAPI.savePage({ ...page, pageData: fixPageData(page.pageData) });
        return payload;
    };
}

const fixCell = (cell: Cell) => {
    const { plugin, rows, dataI18n = {} } = cell;
    const { default: defaultState = {} } = dataI18n;
    const { slate = [] } = defaultState as any;

    if (plugin?.id === 'ory/editor/core/content/slate') {
        return {
            ...cell,
            dataI18n: {
                ...dataI18n,
                default: {
                    ...dataI18n.default,
                    slate: slate?.map((slate) => {
                        const { type = 'PARAGRAPH/PARAGRAPH', children = [] } = slate;

                        return {
                            type,
                            children: children.filter((c) => !!c.text),
                        };
                    }),
                },
            },
        };
    }

    if (rows && rows.length) {
        return {
            ...cell,
            rows: fixRows(rows),
        };
    }

    return cell;
};

const fixRows = (rows: Row[]) => {
    if (!rows?.length) {
        return undefined;
    }
    return rows?.map((row) => {
        const { cells } = row;

        return {
            ...row,
            cells: Boolean(cells?.length) ? cells?.map(fixCell) : undefined,
        };
    });
};

type PageData = {
    id: string;
    rows: Row[];
};

const fixPageData = (pageData: PageData[]) => {
    if (!pageData?.length) {
        return [];
    }
    return pageData?.map((data) => ({
        ...data,
        rows: fixRows(data.rows),
    }));
};

function GET(slug: string): AppThunk<Promise<ReactPageDto>> {
    return async (dispatch) => {
        const page = await PagesAPI.getPage(slug);

        if (page.error) {
            return Promise.reject(new Error(page.error));
        }

        if (page.hasActivities) {
            await dispatch(ActivityActions.GET_ALL());
        }

        if (page.hasHotels) {
            await dispatch(LodgingAPI.getAll('HOTEL'));
        }

        if (page.hasHouses) {
            await dispatch(LodgingAPI.getAll('HOUSE'));
        }

        try {
            dispatch(SET_PAGE({ ...page, pageData: fixPageData(page.pageData) }));
        } catch (err) {
            dispatch(SET_PAGE(page));
            console.log(err.toString());
        }

        return page;
    };
}

function SET_PAGE_TYPE(slug: string, pageType: ReactPageDto['pageType']): AppThunk<Promise<ReactPageDto>> {
    return async (dispatch, getState) => {
        dispatch({ type: PAGE_ACTIONS.SET_PAGE_TYPE, payload: { slug, pageType } });
        if (pageType === 'ACTIVITIES') {
            await dispatch(ActivityActions.GET_ALL());
        }
        const pages = selectPages(getState());
        return pages[slug] as ReactPageDto;
    };
}

export const Pages = {
    SET_PAGE,
    SAVE,
    GET,
    SET_PAGE_TYPE,
};

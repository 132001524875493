import React from 'react';
import loadable from '@loadable/component';
import { AdminOnly } from '../../AdminOnly';

const Settings = loadable(
    () =>
        import(
            /* webpackChunkName: "FontSettings" */
            './SettingsDrawer'
        )
);

export function FontSettings() {
    return (
        <AdminOnly>
            <Settings />
        </AdminOnly>
    );
}

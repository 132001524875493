import canUseDOM from 'helpers/canUseDom';
import loadable from '@loadable/component';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsMobileEnv } from '../selectors';

export const ResponsiveMenu = loadable(
    () =>
        import(
            /* webpackChunkName: "ResponsiveMenu" */
            './ResponsiveMenu'
        )
);

export function LazyResponsiveMenu() {
    const isMobile = useSelector(getIsMobileEnv);

    if (!canUseDOM() && !isMobile) {
        return null;
    }
    return <ResponsiveMenu isMobile={isMobile} />;
}

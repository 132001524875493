import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';
import { RsvpDto } from '../../dtos/RsvpDto';
import { services } from './services';
import sendEmail from '../services/sendEmail';

const formatMealSelections = (mealSelections: RsvpDto['mealSelections']) => {
    const guests = services.guests.stateMap;
    const meals = services.meals.stateMap;
    return mealSelections.map((selection) => {
        const { mealId, guestId } = selection;
        return {
            ...selection,
            meal: meals[mealId],
            guest: guests[guestId],
        };
    });
};
export class RsvpStore extends CollectionStore<RsvpDto> {
    constructor() {
        super(API_COLLECTIONS.RSVPS, 'id', { populate: ['mealSelections'] });

        // this.refresh();
    }

    async refresh(rsvp: RsvpDto) {
        await super.refresh();
        if (rsvp?.contactId) {
            const contact = services.contacts.getOne(rsvp.contactId);
            if (contact?.rsvp) {
                sendEmail(contact as any);
            }
            return contact;
        }
    }

    getAll() {
        const contacts = services.contacts.stateMap;
        const rsvps = super.getAll();

        return rsvps.map((r) => ({
            ...r,
            contact: contacts[r.contactId],
            meals: formatMealSelections(r.mealSelections),
        }));
    }
}

import { Typography, Box } from '@mui/material';
import { CtaButton } from 'components/CtaButton/CtaButton';
import { NavDto } from 'dtos/NavDto';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMenu } from 'store/actions/getMenus';
import { DEFAULT_FONT } from '../../theme';

interface AppFooterMenuProps {
    menuId?: string;
    menu: NavDto;
    className?: string;
}

export function AppFooterMenu({ menu, menuId, className }: AppFooterMenuProps) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!menu && menuId) {
            dispatch(getMenu(menuId));
        }
    }, [menuId, menu, dispatch]);

    if (!menu) {
        return null;
    }
    const { title, items } = menu;

    return (
        <Box>
            <Typography
                sx={{
                    fontFamily: DEFAULT_FONT,
                    textDecoration: 'underline',
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 'bold',
                }}
            >
                {title}
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', margin: 0, padding: 0 }} className={className}>
                {items.map((item) => {
                    return (
                        <li key={item.id}>
                            <CtaButton {...item} unstyled linkClassName="footerLink" />
                        </li>
                    );
                })}
            </Box>
        </Box>
    );
}

import { Box, Card, CircularProgress, Container } from '@mui/material';
import React, { ReactNode } from 'react';

interface LoaderProps {
    inLayout?: boolean;
    children?: ReactNode | ReactNode[];
}

export function Loader({ inLayout = true, children }: LoaderProps) {
    const loader = (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} minHeight={400}>
            <CircularProgress />
            {children && <div>{children}</div>}
        </Box>
    );

    if (!inLayout) {
        return loader;
    }

    return (
        <Box my={4}>
            <Container maxWidth="xl">
                <Card sx={{ backgroundColor: '#fff' }} elevation={1}>
                    {loader}
                </Card>
            </Container>
        </Box>
    );
}

import path from 'path';
import { isProduction } from 'helpers/env';

export function getDirname() {
    if (isProduction()) {
        return path.join(process.cwd(), 'build');
    } else {
        return path.join(process.cwd(), 'dist');
    }
}

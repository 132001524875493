import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { NavDto } from '../../dtos/NavDto';
import { CollectionStore } from './CollectionStore';

export class NavsStore extends CollectionStore<NavDto> {
    constructor() {
        super(API_COLLECTIONS.NAVS, 'slug');
    }

    refresh(): Promise<void> {
        return this.API.getAll({ populate: ['items'] })
            .then(({ data }) => {
                this.state = data as any;
                this.saveAll(this.state);
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

import { ReactPageDto } from 'dtos/ReactPageDto';
import { getFetcher } from 'store/actions/fetcher';

function getPage(slug: string): Promise<ReactPageDto> {
    return getFetcher().get(`/api/page/${slug}`);
}

function getPageByID(id: number): Promise<ReactPageDto> {
    return getFetcher()
        .get(`/api/react-pages/${id}`, {
            params: {
                populate: 'deep,2',
            },
        })
        .then(({ data }) => data);
}

function getAll(): Promise<ReactPageDto[]> {
    return getFetcher()
        .get('/api/react-pages')
        .then(({ data }) => data);
}

function createPage(page: Omit<ReactPageDto, 'id'>): Promise<ReactPageDto> {
    return getFetcher().post('/api/react-pages', { data: page });
}

function savePage(page: ReactPageDto): Promise<ReactPageDto> {
    return getFetcher()
        .put(`/api/react-pages/${page.id}`, { data: page })
        .then(({ data }) => data);
}

export const PagesAPI = {
    getAll,
    getPage,
    getPageByID,
    createPage,
    savePage,
};

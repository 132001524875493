import canUseDOM from 'helpers/canUseDom';

const replaceParam = (message, search, replace) => {
    const re = new RegExp(`\\{${search}\\}`, 'g');
    return message.replace(re, replace);
};

const getLabels = () => {
    if (canUseDOM()) {
        return window.labels || {};
    }
    const { services } = require('server/collections/services');
    return services.labels.state;
};

const createMessageFromParams = (message, dynamicParams) => {
    let newMessage = message;

    if (typeof dynamicParams[0] === 'object') {
        const params = dynamicParams[0];
        Object.keys(params).forEach((key) => {
            newMessage = replaceParam(newMessage, key, params[key]);
        });
    }

    dynamicParams.forEach((arg, index) => {
        newMessage = replaceParam(newMessage, index, arg);
    });

    return newMessage;
};

export const renderLabel = (key, ...dynamicParams) => {
    const labels = getLabels();

    let message;

    // eslint-disable-next-line no-prototype-builtins
    if (labels.hasOwnProperty(key)) {
        message = labels[key];

        if (dynamicParams.length > 0) {
            return createMessageFromParams(message, dynamicParams);
        }

        return message;
    }

    return key;
};

import { FETCH_STATUS_ACTIONS } from 'store/actionTypes';
import { AppThunk, FETCH_STATUS } from 'types/types';

function START(type: string): AppThunk<void> {
    return (dispatch) => {
        dispatch({
            type: FETCH_STATUS_ACTIONS.SET_STATUS,
            payload: {
                type,
                status: FETCH_STATUS.LOADING,
            },
        });
    };
}

function ERROR(type: string, error: Error): AppThunk<void> {
    return (dispatch) => {
        dispatch({
            type: FETCH_STATUS_ACTIONS.SET_STATUS,
            payload: {
                type,
                status: FETCH_STATUS.ERROR,
                error,
            },
        });
    };
}

function COMPLETE(type: string, data: any): AppThunk<void> {
    return (dispatch) => {
        dispatch({
            type: FETCH_STATUS_ACTIONS.SET_STATUS,
            payload: {
                type,
                status: FETCH_STATUS.COMPLETE,
                // data,
            },
        });
    };
}

export const createFetchActionHooks = (type: string, dispatch: any) => {
    return {
        START: () => dispatch(START(type)),
        COMPLETE: (data: any) => dispatch(COMPLETE(type, data)),
        ERROR: (err: Error) => dispatch(ERROR(type, err)),
    };
};

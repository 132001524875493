import { useCallback, useState, useRef, useMemo } from 'react';
import { useField, FieldValidator, useFormikContext } from 'formik';
import {
    InputComponentProps,
    InputAriaProps,
    InputComponentLabelProps,
    InputComponentMetaProps,
} from './InputComponentProps';
import { GuestRow } from 'views/GuestList/GuestList';

export interface UseFormFieldOptions {
    validate?: FieldValidator;
    autoSave?: boolean;
}

const BASE_ID = 'lf';

export function getFieldId(name: string) {
    return `${BASE_ID}-${name}`;
}

export function getLabelId(name: string) {
    return `${getFieldId(name)}-label`;
}

export function getAriaLabelledById(name: string) {
    return `${getFieldId(name)}-label`;
}

export function getErrorId(name: string) {
    return `${getFieldId(name)}-error`;
}

export function getLabelProps(name: string): InputComponentLabelProps {
    return {
        id: getLabelId(name),
        htmlFor: getFieldId(name),
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getInputAriaProps<Value = any>(name: string, meta: InputComponentMetaProps<Value>): InputAriaProps {
    const { error, submitCount, hasError } = meta;

    const hasHelper = !!error && submitCount > 0;

    const ariaProps: InputAriaProps = {
        'id': getFieldId(name),
        'aria-invalid': hasError,
        'aria-labelledby': getLabelId(name),
    };

    if (hasHelper) {
        ariaProps['aria-describedby'] = getErrorId(name);
    }

    return ariaProps;
}

export function useFormField<TValue>(
    fieldName: string,
    { validate, autoSave }: UseFormFieldOptions = {}
): InputComponentProps<TValue> {
    const [focused, setFocused] = useState<boolean>(false);
    const { submitCount, isSubmitting, submitForm } = useFormikContext<GuestRow>();
    const [fieldProps, metaProps, fieldHelpers] = useField<TValue>({
        name: fieldName,
        validate,
    });
    const { value } = fieldProps;
    const prevValue = useRef(fieldProps.value);

    const onFocus = useCallback(() => {
        setFocused(true);
    }, []);

    const onBlur = useCallback(() => {
        setFocused(false);
        fieldHelpers.setTouched(true, !!validate);
        if (autoSave && !isSubmitting && prevValue.current !== value) {
            prevValue.current = value;
            submitForm();
        }
    }, [autoSave, submitForm, isSubmitting, value, fieldHelpers, validate]);

    const meta = useMemo(() => {
        const { touched, error } = metaProps;
        const hasError = !!error && (touched || submitCount > 0);
        return {
            ...metaProps,
            hasError,
            submitCount,
        };
    }, [metaProps, submitCount]);

    const field = useMemo(() => {
        const ariaProps = getInputAriaProps(fieldName, meta);
        return { ...fieldProps, ...ariaProps, onBlur, onFocus };
    }, [fieldName, fieldProps, meta, onBlur, onFocus]);

    const labelProps = useMemo(() => {
        return getLabelProps(fieldName);
    }, [fieldName]);

    const helpers = useMemo(
        () => ({
            ...fieldHelpers,
            submitForm,
        }),
        [fieldHelpers, submitForm]
    );

    return {
        focused,
        field,
        meta,
        helpers,
        labelProps,
    };
}

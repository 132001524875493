import canUseDOM from 'helpers/canUseDom';
import { useEffect, useRef } from 'react';
import styles from './StickyHeader.module.scss';

const SET_STICKY = 'SET_STICKY';

export function useStickHeaderListener(setIsSticky: (sticky: boolean) => void) {
    const setIsStickyRef = useRef(setIsSticky).current;

    useEffect(() => {
        const onSetSticky = (e: any) => {
            setIsStickyRef(e.detail);
        };
        window.addEventListener(SET_STICKY, onSetSticky, false);

        return () => {
            window.removeEventListener(SET_STICKY, onSetSticky, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

const dispatchSetSticky = (sticky: boolean) => {
    const evt = new CustomEvent(SET_STICKY, { detail: sticky });
    window.dispatchEvent(evt);
};

const toggleStickyClass = () => {
    const el = document.getElementById('APP_HEADER');
    if (el) {
        el.classList.toggle(styles.forcedWhiteHeader);
    }
};

export function useStickyHeaderDispatch() {
    let ranOnce = useRef(false);
    if (canUseDOM() && !ranOnce.current) {
        toggleStickyClass();
        ranOnce.current = true;
    }
    useEffect(() => {
        dispatchSetSticky(true);
        return () => {
            dispatchSetSticky(false);
            // toggleStickyClass();
        };
    }, []);
}

import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';
import { MealDto } from '../../dtos/MealDto';
import { getDtoMap } from '../../utils/getDtoMap';

export class MealsStore extends CollectionStore<MealDto> {
    constructor() {
        super(API_COLLECTIONS.MEALS, 'id');
    }

    get stateMap() {
        const { state } = this;
        return getDtoMap(state as any, 'mealId');
    }

    getAll() {
        return this.state.sort((a, b) => a.mealId - b.mealId);
    }
}

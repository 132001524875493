import { ListPayload } from 'dtos/ListPayload';
import { MediaDto } from 'dtos/MediaDto';
import { getProxyUrl, isProduction } from 'helpers/env';
import { getFetcher } from 'store/actions/fetcher';
import { BaseDto } from '../../types/types';

export enum API_COLLECTIONS {
    GUESTS = 'guests',
    ACTIVITIES = 'activities',
    CONTACTS = 'contacts',
    GALLERIES = 'galleries',
    FEATURES = 'features',
    NAVS = 'navs',
    REACT_PAGES = 'react-pages',
    UPLOADS = 'upload',
    LABELS = 'labels',
    HOUSES = 'houses',
    RSVPS = 'rsvps',
    EVENTS = 'events',
    SESSIONS = 'sessions',
    MEALS = 'meals',
}

const isUploads = (collection: API_COLLECTIONS) => collection === API_COLLECTIONS.UPLOADS;

interface AnyDTO {
    id?: number;
    attachments?: MediaDto[];
}

export type GetParams<TDto> = Partial<Omit<ListPayload<TDto>, 'data'>> & { url?: string };

const BASE_URL = getProxyUrl();

export const headers = { authorization: `Bearer ${process.env.REACT_APP_TOKEN}` };

const getFetchURL = (url: string) => {
    const fetchUri = new URL(url, BASE_URL);
    return fetchUri.toString();
};

export function createServerCollectionAPI<TDto extends AnyDTO>(collection: API_COLLECTIONS) {
    const saveMethods = {
        update(data: TDto): Promise<TDto> {
            return getFetcher()
                .put(`${BASE_URL}/api/${collection}/${data.id}`, { data }, { headers })
                .then((result) => result.data);
        },
        create(data: TDto): Promise<TDto> {
            return getFetcher()
                .post(`${BASE_URL}/api/${collection}`, { data }, { headers })
                .then(({ data: resultData }) => {
                    return { ...data, id: resultData.id };
                });
        },
    };

    const extraPath = isUploads(collection) ? '/files' : '';

    const API = {
        getAll(options: GetParams<TDto> = {}): Promise<ListPayload<TDto[]>> {
            const { url, ...params } = options;
            const fetchURL = getFetchURL(url || `/api/${collection}${extraPath}`);
            return getFetcher().get(fetchURL, {
                headers,
                params,
            });
        },
        get(id: number, options: GetParams<TDto> = { populate: ['deep,2'] }): Promise<ListPayload<TDto>> {
            const { url, ...params } = options;
            const fetchURL = getFetchURL(url || `/api/${collection}${extraPath}/${id}`);
            return getFetcher().get(fetchURL, {
                headers,
                params,
            });
        },
        save(data: TDto): Promise<TDto> {
            const saveMethod = data.id ? saveMethods.update : saveMethods.create;
            return saveMethod(data);
        },
        delete(id: number): Promise<TDto> {
            return getFetcher().delete(`${BASE_URL}/api/${collection}/${id}`);
        },
    };

    return API;
}

export class ClientAPI<TDto = any> {
    collection: string;

    constructor(collection: API_COLLECTIONS) {
        this.collection = collection;
        this._update = this._update.bind(this);
        this._create = this._create.bind(this);
    }

    _update(data: TDto): Promise<TDto> {
        return getFetcher()
            .put(`/api/${this.collection}/${(data as any).id}`, { data }, { headers })
            .then((result) => result.data);
    }

    _create(data: TDto): Promise<TDto> {
        return getFetcher()
            .post(`/api/${this.collection}`, { data }, { headers })
            .then(({ data: resultData }) => {
                return { ...data, id: resultData.id };
            });
    }
    getAll(options: GetParams<TDto> = {}): Promise<ListPayload<TDto[]>> {
        const { collection } = this;
        const { url, ...params } = options;
        return getFetcher().get(`/api/${collection}`, {
            headers,
            params,
        });
    }
    get(id: number | string, options: GetParams<TDto> = { populate: ['deep,2'] }): Promise<ListPayload<TDto>> {
        const { collection } = this;
        const { url, ...params } = options;
        return getFetcher().get(`/api/${collection}/${id}`, {
            headers,
            params,
        });
    }
    save(data: any): Promise<TDto> {
        const saveMethod = data.id ? this._update : this._create;
        return saveMethod(data);
    }
    delete(id: number): Promise<TDto> {
        const { collection } = this;
        return getFetcher().delete(`/api/${collection}/${id}`);
    }
}

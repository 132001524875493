import { Box, CircularProgress } from '@mui/material';
import { getIsUserAdmin, useLoggedInAdmin } from 'hooks/useLoggedInUser';
import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { API_COLLECTIONS, createCollectionApi } from '../../../utils/createCollectionApi';
import { ContactDto } from '../../../dtos/ContactDto';
import { setSsrRouteDataKeyValue } from '../../../providers/DataProvider/RouteDataProvider.server';
import { useRouteDataSelection } from '../../ReactPage/hooks/useRouteDataSelection';
import { FETCH_STATUS, GetDataOptions } from '../../../types/types';

const ContactsList = lazy(() => import(/* webpackChunkName: "ContactsList" */ './ContactsList'));

const API = createCollectionApi<ContactDto>(API_COLLECTIONS.CONTACTS);

export function LazyContactsList() {
    const [showList, setShowList] = useState(false);
    const adminUser = useLoggedInAdmin();
    const [contacts, status] = useRouteDataSelection('contacts', adminUser && API.getAll, [adminUser]);
    const [guests] = useRouteDataSelection('guests');

    useEffect(() => {
        if (!guests && !localStorage.getItem('reload')) {
            window.location.reload();
            localStorage.setItem('reload', 'true');
        } else {
            localStorage.removeItem('reload');
        }
    }, [guests]);

    useEffect(() => {
        setShowList(status === FETCH_STATUS.COMPLETE);
        if (!adminUser) {
            window.location.hash = '#login';
        }
    }, [status, adminUser]);

    const loader = (
        <Box display="flex" minHeight={600} alignItems="center" justifyContent="center">
            <CircularProgress size={44} />
        </Box>
    );

    if (adminUser && showList) {
        return (
            <Suspense fallback={loader}>
                <ContactsList contacts={contacts} guests={guests || {}} />
            </Suspense>
        );
    }
    return loader;
}

LazyContactsList.getData = async ({ store }: GetDataOptions) => {
    const user = store.getState().loggedInUser;
    const isAdmin = getIsUserAdmin(user);
    const { services } = require('../../../server/collections/services');

    if (isAdmin) {
        setSsrRouteDataKeyValue('contacts', await API.getAll());
        setSsrRouteDataKeyValue('guests', services.guests.stateMap);
    }
};

import { createSelector } from 'reselect';
import { getState } from './state';
import { GUESTS_INITIAL_STATE } from 'store/reducers/guestsReducer';

export const selectGuests = createSelector(getState, (state) => state.guests || GUESTS_INITIAL_STATE);

export const selectGuestList = createSelector(selectGuests, (state) => {
    return state.data;
});

export const selectGuestPagination = createSelector(selectGuests, (state) => state.pagination || {});

export const selectGuestsAPIPagination = createSelector(selectGuestPagination, ({ page, pageSize }) => ({
    page,
    pageSize,
}));

export const selectGuestFilters = createSelector(selectGuests, (state) => state.filters);

export const selectGuestColumns = createSelector(selectGuests, (state) => state.populate || []);

export const selectGuestSort = createSelector(selectGuests, (state) => state.sort || {});

export const selectGuestsApiParams = createSelector(
    [selectGuestColumns, selectGuestFilters, selectGuestsAPIPagination, selectGuestSort],
    (populate, filters, pagination, sort) => ({
        pagination,
        filters,
        populate,
        sort,
    })
);

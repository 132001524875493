import { FETCH_STATUS_ACTIONS } from 'store/actionTypes';

export function fetchStatusReducer(state = {}, action) {
    switch (action.type) {
        case FETCH_STATUS_ACTIONS.SET_STATUS: {
            const { type } = action.payload;
            if (type) {
                return {
                    ...state,
                    [type]: action.payload,
                };
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

import React, { useMemo, useState } from 'react';
import { ContactDto } from '../../../dtos/ContactDto';
import { RsvpDto } from '../../../dtos/RsvpDto';
import { Box, Button } from '@mui/material';
import { CustomCard } from '../../../components/CustomCard/CustomCard';
import { renderLabel } from 'utils/renderLabel';
import { formatDate } from '../../../utils/formatDate';
import { NewRsvpForm } from '../NewRsvpForm';
import { AddToCalendarToggle } from '../../../components/AddToCalendarToggle/AddToCalendarToggle';
import styles from './RsvpConfirmedView.module.scss';
import { DataRow } from '../../Rooms/Houses/DataRow';
import { MealDto } from '../../../dtos/MealDto';
import { getDtoMap } from '../../../utils/getDtoMap';
import { GuestDto } from '../../../dtos/GuestDto';
import { DEFAULT_BORDER } from '../../../theme';
import { EventDto } from 'dtos/EventDto';

interface RsvpConfirmedViewProps {
    contact: ContactDto & { rsvp?: RsvpDto };
    meals: MealDto[];
    events: EventDto[];
    showAdminButton?: boolean;
}

enum VIEWS {
    EDIT = 'EDIT',
    CONFIRM = 'CONFIRM',
}

const getDefaultView = (contact: RsvpConfirmedViewProps['contact']): VIEWS => {
    return !!contact?.rsvp ? VIEWS.CONFIRM : VIEWS.EDIT;
};

export function RsvpConfirmedView({ contact, meals, events, showAdminButton = true }: RsvpConfirmedViewProps) {
    const [view, setView] = useState<VIEWS>(() => getDefaultView(contact));
    const { rsvp, guests } = contact;

    const mealsMap = useMemo(() => {
        return getDtoMap<MealDto>(meals || []);
    }, [meals]);

    const onEditClick = () => setView(VIEWS.EDIT);

    const onUpdate = () => {
        window.location.reload();
        setView(VIEWS.CONFIRM);
    };

    const getBooleanLabel = (val: boolean) => {
        const label = val ? 'Yes' : 'No';
        const valClassName = val ? styles.valueYes : styles.valueNo;
        return <b className={valClassName}>{label}</b>;
    };

    const getMealValue = (guest: GuestDto) => {
        const { mealSelections = [] } = rsvp;
        const selection = mealSelections.find((m) => m.guestId === guest.id);

        if (selection) {
            const { mealId } = selection;
            const meal = mealsMap[mealId];
            if (meal) {
                return <DataRow fontSize={12} key={guest.id} label={guest.fullName} total={meal.title} />;
            }
        }

        return null;
    };

    const renderConfirmView = () => {
        const { updatedAt, welcomeDrinks, reception, sundayBrunch, lodging, comments } = rsvp;
        return (
            <CustomCard title={renderLabel('THANK_YOU')} px={2} py={1}>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: renderLabel('RSVP_CONFIRMATION', `<b>${formatDate(updatedAt)}</b>`),
                    }}
                />
                <Box px={2} mt={2}>
                    <AddToCalendarToggle variant="text" label="Add wedding to your calendar" />
                </Box>
                <Box bgcolor="#fafafa" border={DEFAULT_BORDER} margin="0 auto" mt={2} p={2} m={2}>
                    <DataRow noBorder label="Welcome drinks" total={getBooleanLabel(welcomeDrinks)} />
                    <Box>
                        <DataRow noBorder label="Ceremony & reception" total={getBooleanLabel(reception)} />
                        <Box textAlign="left" fontSize={14} pb={1} ml={2}>
                            {guests.map(getMealValue)}
                        </Box>
                    </Box>
                    <DataRow noBorder label="Sunday brunch" total={getBooleanLabel(sundayBrunch)} />

                    <DataRow noBorder boldedTitle={false} label="Lodging" total={lodging} />

                    <Box p={1} textAlign="left" fontSize={14}>
                        <p>Comments:</p>
                        <p>{comments}</p>
                    </Box>

                    <Box mt={2}>
                        <Button onClick={onEditClick} variant="contained" color="primary" fullWidth>
                            Edit your RSVP
                        </Button>
                    </Box>
                </Box>
            </CustomCard>
        );
    };

    switch (view) {
        case VIEWS.EDIT:
            return <NewRsvpForm contact={contact} events={events} meals={meals} onUpdate={onUpdate} />;
        case VIEWS.CONFIRM:
            return renderConfirmView();
    }
}

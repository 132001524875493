import { Button, ButtonProps } from "@mui/material";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "store/actions/user";

interface LogoutButtonProps {
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  children?: ReactNode;
  label?: string;
}

export function LogoutButton({
  variant = "outlined",
  color = "primary",
  children,
  label,
}: LogoutButtonProps) {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser());
  };

  const markup = children || label || "Logout";

  return (
    <Button color={color} variant={variant} onClick={logout}>
      {markup}
    </Button>
  );
}

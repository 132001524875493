import path from 'path';
import fs from 'fs';
import { getDirname } from 'utils/serverDirectory';

const capitalizeFirstLetter = (w = '') => w.charAt(0).toUpperCase() + w.slice(1);

export function toCamelCase(str = '') {
    const stringSplitted = str.split(/[_-]+/g);
    const camelized = stringSplitted.map((w, i) => (i > 0 ? capitalizeFirstLetter(w) : w));
    return camelized.join('');
}

const PACKAGES = `${getDirname().split('packages')[0]}/packages`;

export function getBackendDirectoryPath(...args: string[]) {
    return path.join(PACKAGES, 'backend', 'src', ...args);
}

export function getBackendDirPath() {
    return getBackendDirectoryPath('api');
}

export function getBackendComponentDirPath() {
    return getBackendDirectoryPath('components');
}

export function getServerDirectory(...args: string[]) {
    return path.join(PACKAGES, 'website', 'src', 'server', ...args);
}

export function getBundlesDirPath(...args: string[]) {
    return path.join(PACKAGES, 'bundles', ...args);
}

export function getLabelsPath() {
    return path.join(getBundlesDirPath(), 'labels.json');
}

function setupLabelsBundle() {
    try {
        const data = fs.readFileSync(getLabelsPath(), { encoding: 'utf-8' });
        global.labels = JSON.parse(data);
    } catch (err) {
        console.log(err);
    }
}

setupLabelsBundle();

import { NavDto } from "dtos/NavDto";
import { getFetcher } from "store/actions/fetcher";
import { RootState } from '../store/selectors/state';

function getMenuBySlug(slug: string): Promise<NavDto> {
    return getFetcher().get(`/api/menu/${slug}`);
}

const DefaultMenu: NavDto = { id: 0, items: [] };

async function getAllSiteMenus(): Promise<RootState['menus']> {
    const primary = await getMenuBySlug('primary').catch(() => DefaultMenu);
    const user = await getMenuBySlug('user').catch(() => DefaultMenu);
    return { primary, user };
}

const MenusAPI = { getMenuBySlug, getAllSiteMenus };

export default MenusAPI;
import { Theme, responsiveFontSizes } from '@mui/material';
import { createTheme, ComponentsOverrides } from '@mui/material/styles';
import shadows from './shadows';
import { light, dark } from './palette';

/*
font-family: 'Cardo', serif;
font-family: 'Josefin Sans', sans-serif;
font-family: 'Josefin Slab', serif;
font-family: 'Roboto Serif', serif;

*/

export const DEFAULT_FONT = `'Roboto Serif', serif`;
export const HEADING_FONT = `La Luxes Serif`;
export const CARD_FONT = `'Josefin Slab', serif`;
export const BUTTON_FONT = DEFAULT_FONT;
export const APP_FONT = DEFAULT_FONT;
export const SUCCESS_COLOR = '#357960';
export const PRIMARY_COLOR = '#454939';
export const ERROR_ALERT_BG = 'hsla(0, 100%, 40%, 0.096)';
export const ERROR_ALERT_COLOR = 'rgb(109, 3, 3)';

export const FONTS = {
    ARIAL: 'Arial, Helvetica, sans-serif',
    DEFAULT: DEFAULT_FONT,
    HEADING: HEADING_FONT,
    CARD: CARD_FONT,
};

export const DEFAULT_BORDER = '1px solid rgba(0, 0, 0, 0.09)';
export const DEFAULT_BORDER_DARK = '1px solid rgba(255, 255, 255, 0.09)';
export const DEFAULT_FIELD_BORDER = '1px solid hsl(207, 17%, 80%)';

export const PRIMARY_COLOR_LIGHT = '#828475';

export const PAPER_BG = '#FFFBF7';
export const HEADER_BG = '#828475';
export const WHITE = '#FFFFFF';

export const COLORS = ['#000000', PRIMARY_COLOR, '#556055', PRIMARY_COLOR_LIGHT, PAPER_BG, WHITE];
export const ERROR_COLOR = '#CC0000';

const getTheme = (mode: string): Theme =>
    responsiveFontSizes(
        createTheme({
            palette: mode === 'light' ? light : dark,
            shadows: shadows(mode),
            typography: {
                fontSize: 16,
                fontFamily: 'var(--body-font-family)',
                button: {
                    textTransform: 'none',
                    fontWeight: 400 as React.CSSProperties['fontWeight'],
                },
                caption: {
                    fontSize: 24,
                    fontWeight: 700,
                },
                body1: {
                    fontWeight: 600,
                    fontFamily: 'arial',
                    fontSize: 14,
                    lineHeight: 1.25,
                },
                body2: {
                    fontFamily: 'arial',
                    fontSize: 12,
                    lineHeight: 1.25,
                },
            },
            zIndex: {
                appBar: 1200,
                drawer: 1300,
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            // minHeight: 44,
                            fontWeight: 400,
                            // borderRadius: 5,
                        },
                        outlinedInherit: {
                            '&:hover': {
                                background: '#fff',
                                color: '#000',
                                textShadow: 'none',
                            },
                        },
                        containedSecondary: mode === 'light' ? { color: 'white' } : {},
                    } as ComponentsOverrides['MuiButton'],
                },
                // MuiInputBase: {
                //   styleOverrides: {
                //     root: {
                //       borderRadius: 5,
                //     },
                //   } as ComponentsOverrides["MuiInputBase"],
                // },
                // MuiOutlinedInput: {
                //   styleOverrides: {
                //     root: {
                //       borderRadius: 5,
                //     },
                //     input: {
                //       borderRadius: 5,
                //     },
                //   } as ComponentsOverrides["MuiOutlinedInput"],
                // },
                // MuiCard: {
                //   styleOverrides: {
                //     root: {
                //       borderRadius: 8,
                //     },
                //   } as ComponentsOverrides["MuiCard"],
                // },
            },
            // themeToggler,
        })
    );

export default getTheme;

import React from 'react';

type Props = {
    fullName: string;
};

export const RehearsalDinnerTemplate: React.FC<Props> = ({ fullName }) => {
    return (
        <div
            style={{
                marginTop: 10,
                textAlign: 'center',
                padding: '0.5em',
                border: '1px solid #D4AF37',
                borderRadius: '6px',
                background: '#F8F5F2',
                color: '#333333',
            }}
        >
            <div
                style={{
                    padding: '1em',
                    borderRadius: '4px',
                    background: '#F8F5F2',
                }}
            >
                <p>
                    <b>{fullName},</b>
                </p>
                <p>You're Invited! Please join us for a Wedding Rehearsal Dinner in honor of</p>
                <p>
                    <b>Cody Suher & Henry Latour</b>
                </p>
                <img
                    style={{
                        maxWidth: 300,
                        height: 'auto',
                        borderRadius: '4px',
                        border: '1px solid #D4AF37',
                        background: '#fff',
                        boxShadow: '0px 8px 40px -12px rgba(0, 0, 0, 0.3);',
                    }}
                    alt="codyHenry.png"
                    src="https://codyandhenry.com/uploads/cody_Henry_d4c58cb1c0.png"
                    srcSet="https://codyandhenry.com/uploads/large_cody_Henry_d4c58cb1c0.png 759w,https://codyandhenry.com/uploads/medium_cody_Henry_d4c58cb1c0.png 569w,https://codyandhenry.com/uploads/small_cody_Henry_d4c58cb1c0.png 380w,https://codyandhenry.com/uploads/thumbnail_cody_Henry_d4c58cb1c0.png 118w"
                />
                <p>
                    on <b>Friday, June 28th, 2024</b> at <b>5:00PM</b> - <b>8:00PM</b>
                </p>
                <p>
                    <div>
                        <b>The Wilburtion Inn Terrace</b>
                    </div>
                    <div>257 Wilburton Drive</div>
                    <div>Manchester, VT</div>
                </p>
                <p>
                    <b>
                        <i>Dress code: </i>
                    </b>
                    cabana shirts & khakis for men and sundresses for women.
                </p>
                <p>
                    If for whatever reason you cannot make it, please let us know by May 30th by responding to this
                    email.
                </p>
                <p>We look forward to celebrating with you!</p>
                <p>
                    <i>Henry & Cody</i>
                </p>
            </div>
        </div>
    );
};

import { BaseDto, DtoMap } from '../types/types';

export function getDtoMap<TDto = BaseDto>(items: TDto[], primaryKey: string = 'id'): DtoMap<TDto> {
    if (!Array.isArray(items)) {
        return items || {};
    }
    return items.reduce((prevMap, item) => {
        const key = item[primaryKey];
        if (key) {
            return { ...prevMap, [key as string]: item };
        }
        return prevMap;
    }, {} as DtoMap<TDto>);
}

import React, { useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { useAppBarStyles } from './useAppBarStyles';
import { CtaButtonList } from 'components/CtaButtonList/CtaButtonList';
import { LazyResponsiveMenu } from './ResponsiveMenu/LazyResponsiveMenu';
import { Button } from '@mui/material';
import { renderLabel } from 'utils/renderLabel';
import { useHistory } from 'react-router-dom';
import { FontSettings } from './SettingsDrawer';

export function AppHeaderResponsive({ pages }: { pages: any[] }) {
    const { ref, appBarStyles, isSticky } = useAppBarStyles();

    const history = useHistory();

    const onMobileMenuClick = useCallback(() => {
        history.push({ hash: 'menu' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppBar id="APP_HEADER" ref={ref} elevation={isSticky ? 1 : 0} sx={appBarStyles} position="static">
            <Container maxWidth="lg">
                <Toolbar className="appToolbar" disableGutters>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={onMobileMenuClick}
                            startIcon={<MenuIcon />}
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            {renderLabel('MENU')}
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CtaButtonList
                            showHoverIndicator
                            ctas={pages}
                            itemFontSize={20}
                            className="headerLinks"
                            isMainNav
                            btnProps={{
                                className: 'nav-item',
                            }}
                        />
                        <FontSettings />
                    </Box>
                </Toolbar>
            </Container>
            <LazyResponsiveMenu />
        </AppBar>
    );
}

import React, { useEffect, useState } from 'react';
import { MediaDto } from 'dtos/MediaDto';
import styles from './Image.module.scss';
import clsx from 'clsx';

const sizes = ['large', 'medium', 'small', 'thumbnail'];

const smallestFirst = [...sizes].reverse();

function getNextLargestSrcUrl(image?: MediaDto, smallest = false) {
    if (!image) {
        return undefined;
    }
    const { formats = {} } = image;
    const formatKeys = smallest ? smallestFirst : sizes;
    const key = formatKeys.find((size) => !!formats[size]);
    if (key) {
        return formats[key]?.url;
    }
    return image.url;
}

interface ImageOptionalProps {
    rounded?: boolean;
    elevated?: boolean;
    className?: string;
    maxWidth?: string;
    maxHeight?: string;
    defaultSize?: string;
    useOriginal?: boolean;
}

export function Image(props: MediaDto & ImageOptionalProps) {
    const fallback = getNextLargestSrcUrl(props, true);
    const [loaded, setLoaded] = useState(false);

    const {
        id,
        formats = {},
        url,
        name,
        rounded = false,
        elevated = true,
        maxWidth,
        // maxHeight,
        defaultSize,
        className,
    } = props;

    let imgSrc = defaultSize && formats[defaultSize] ? formats[defaultSize].url : url;

    if (!props.useOriginal && !props.defaultSize) {
        imgSrc = getNextLargestSrcUrl(props);
    }

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    const onLoad = (e) => {
        setLoaded(true);
    };

    return (
        <picture
            data-upload-id={id}
            className={clsx(
                styles.imgWrapper,
                {
                    [styles.loaded]: loaded,
                    [styles.rounded]: rounded,
                    [styles.elevated]: elevated,
                },
                className
            )}
            style={{
                maxWidth,
                backgroundImage: `url("${fallback}")`,
            }}
            onLoad={onLoad}
        >
            {sizes.map((size) => {
                const format = formats[size];
                if (format && format.width) {
                    return <source key={size} media={`(max-width: ${format.width}px)`} srcSet={format.url} />;
                }
                return null;
            })}
            <img className={styles.original} src={imgSrc} alt={name} onLoad={onLoad} />
        </picture>
    );
}

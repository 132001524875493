import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button, ButtonProps } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { CalendarDrawer } from '../MobileAppFooter/CalenderDrawer';

interface AddToCalendarToggleProps extends ButtonProps {
    label?: ReactNode | ReactNode[];
}

export function AddToCalendarToggle({
    label,
    fullWidth = true,
    variant = 'outlined',
    color = 'primary',
    ...buttonProps
}: AddToCalendarToggleProps) {
    const [open, setOpen] = useState<boolean>(false);
    const btnContent = label || 'Add to calender';

    const onClick = () => setOpen(!open);

    return (
        <>
            <Button
                {...buttonProps}
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                onClick={onClick}
                startIcon={<CalendarMonthIcon />}
            >
                {btnContent}
            </Button>
            <CalendarDrawer open={open} onClose={onClick} />
        </>
    );
}

import { match } from 'react-router';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Store, AnyAction, Action } from 'redux';
import { CheerioAPI } from 'cheerio';

interface ServerRequest {
    $: CheerioAPI;
    cookies: string;
}

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, any, unknown, AnyAction>;

export type AppThunkDispatch<A extends Action = Action> = ThunkDispatch<any, void, A>;

export interface RootStore<S = any> extends Store<S> {
    dispatch: AppThunkDispatch;
    getState: () => S;
}

export interface GetDataOptions extends ServerRequest {
    store: RootStore<any>;
    match: match<any>;
}

export interface PageRouteParams {
    slug: string;
}

export enum FETCH_STATUS {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
}

export interface BaseDto {
    id: BaseDto;
}

export interface DtoMap<TDto = BaseDto> {
    [key: string | number]: TDto;
}

import { ActivityDto } from 'dtos/ActivityDto';
import { ListPayload } from 'dtos/ListPayload';
import { HOUSE_ACTIONS } from 'store/actionTypes';

export const HOUSES_INITIAL_STATE: ListPayload<ActivityDto[]> = {
    data: [],
    filters: {},
    pagination: {
        page: 1,
        pageCount: 1,
        pageSize: 15,
        total: 0,
    },
    sort: [],
    populate: ['categories', 'images', 'ctas', 'details'],
};

export function housesReducer(state = HOUSES_INITIAL_STATE, action) {
    switch (action.type) {
        case HOUSE_ACTIONS.GET_ALL_HOUSES: {
            return action.payload;
        }
        case HOUSE_ACTIONS.SET_HOUSE: {
            const { id } = action.payload;

            const { data } = state;

            return {
                ...state,
                data: data.map((activity) => {
                    return activity.id === id ? action.payload : activity;
                }),
            };
        }
        case HOUSE_ACTIONS.DELETE_HOUSE: {
            const { data } = state;
            return {
                ...state,
                data: data.filter((activity) => activity.id !== action.payload),
            };
        }
        case HOUSE_ACTIONS.ADD_HOUSE: {
            const { data } = state;

            return {
                ...state,
                data: [...data, action.payload],
            };
        }
        default:
            return state;
    }
}

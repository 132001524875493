import loggedInUserReducer from 'store/reducers/loggedInUserReducer';
import { createStore as createReduxStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router';
import siteConfigReducer from './reducers/siteConfigReducer';
import booksReducer from './reducers/booksReducer';
import pagesReducer from './reducers/pagesReducer';
import envReducer from './reducers/envReducer';
import menus from './reducers/menusReducer';
import usersReducer from './reducers/usersReducer';
import { attachmentsReducer } from './reducers/attachmentsReducer';
import { activitiesReducer } from './reducers/activitiesReducer';
import { guestsReducer } from './reducers/guestsReducer';
import { fetchStatusReducer } from './reducers/fetchStatusReducer';
import { routerReducer } from './reducers/routerReducer.server';
import { hotelsReducer } from './reducers/hotelsReducer';
import { housesReducer } from './reducers/housesReducer';
import { featuresReducer } from './reducers/featuresReducer';


const createStore = (initialState = {}, middleware = [], history) => {
    return createReduxStore(
        combineReducers({
            router: history ? connectRouter(history) : routerReducer,
            guests: guestsReducer,
            fetchStatus: fetchStatusReducer,
            activities: activitiesReducer,
            hotels: hotelsReducer,
            houses: housesReducer,
            features: featuresReducer,
            menus,
            loggedInUser: loggedInUserReducer,
            users: usersReducer,
            siteConfig: siteConfigReducer,
            books: booksReducer,
            pages: pagesReducer,
            attachments: attachmentsReducer,
            env: envReducer,
        }),
        initialState,
        compose(applyMiddleware(thunk, ...middleware)),
    );
};

export default createStore;

import { AppThunk } from '../../types/types';
import { MENU_ACTIONS } from 'store/actionTypes';
import { getFetcher } from './fetcher';
import MenusAPI from 'api/MenusAPI';

export function getMenus(): AppThunk<Promise<any>> {
    return async function fetchAndSetMenus(dispatch: Function) {
        const primary = await getFetcher().get('/collections/menus/primary.json');
        const user = await getFetcher().get('/collections/menus/user.json');
        const payload = { primary, user };
        dispatch({
            type: MENU_ACTIONS.SET_MENUS,
            payload,
        });
        return payload;
    };
}

export function getMenu(menuId: string): AppThunk<Promise<any>> {
    return async function fetchAndSetMenus(dispatch: Function) {
        const menu = await MenusAPI.getMenuBySlug(menuId);
        dispatch({
            type: MENU_ACTIONS.SET_MENU,
            payload: menu,
        });
        return menu;
    };
}

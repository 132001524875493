import React, { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useRsvpFormContext } from './RsvpFormProvider';
import { useField, useFormikContext } from 'formik';
import { Box, Button, Collapse } from '@mui/material';
import { RsvpOptionsDto } from '../../dtos/components/RsvpOptionsDto';
import { MealDto } from '../../dtos/MealDto';
import styles from './RsvpContactPage.module.scss';
import { DEFAULT_BORDER } from '../../theme';
import { renderLabel } from 'utils/renderLabel';
import clsx from 'clsx';
import { WrappedFormField } from '../../components/InputText/WrappedInputText';
import { TextareaField } from '../../components/InputText/TextareaField';

type Props = {
    guestId: number;
    meals: MealDto[];
    selections: Omit<RsvpOptionsDto, 'id'>[];
    index: number;
    label?: string;
};

function MealSelectField({ guestId, meals, selections = [], index, label }: Props) {
    const realIndex = useMemo(() => {
        const idx = selections.findIndex((s) => s.guestId === guestId);
        return idx > -1 ? idx : index;
    }, [selections, guestId]);
    const { submitCount } = useFormikContext();
    const [field, meta, helpers] = useField({ name: `mealSelections[${realIndex}]` });
    const { value: fieldValue = { guestId, mealId: -200 } } = field;

    const createOptionClickHandler = (mealId: number) => (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        helpers.setValue({ ...fieldValue, guestId, mealId });
    };

    const isOptionChecked = (mealId: number) => {
        return fieldValue.mealId === mealId;
    };

    const isGuestNotAttending = (mealId: number) => {
        return isOptionChecked(mealId) && mealId < 0;
    };

    return (
        <Box className="meal-choices">
            {meals.map((meal) => {
                const { mealId, id, title } = meal;
                const isChecked = isOptionChecked(mealId || id);
                const isNotAttending = isGuestNotAttending(mealId);
                return (
                    <Box textAlign="left" key={mealId || id}>
                        <Button
                            fullWidth
                            color="inherit"
                            disableFocusRipple
                            disableRipple
                            className={clsx('checkbox-button', {
                                'checked-button': isChecked,
                                'checked-button-error': isNotAttending,
                            })}
                            sx={{
                                whiteSpace: 'normal',
                                textAlign: 'left',
                                justifyContent: 'flex-start',
                                fontSize: 15,
                            }}
                            startIcon={isChecked ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                            onClick={createOptionClickHandler(mealId)}
                        >
                            <div className="checkbox-label">{title}</div>
                        </Button>
                    </Box>
                );
            })}
        </Box>
    );
}

export function FoodSelectionsField() {
    const [field, , helpers] = useField({ name: 'mealSelections' });
    const { value: selections } = field;
    const [{ value: isAttending }] = useField('reception');
    const { contact, meals } = useRsvpFormContext();

    const { guests = [] } = contact;

    useEffect(() => {
        if (!guests?.length) {
            return;
        }

        if (!selections) {
            helpers.setValue(
                guests.map((g) => ({
                    guestId: g.id,
                    mealId: 1,
                }))
            );
        }
    }, [selections, guests]);

    const shouldShowAllergyField = (guestId: number) => {
        const selection = selections?.find((selection) => selection.guestId === guestId);

        return selection && selection.mealId !== -200;
    };

    const getAllergyInfoFieldName = (index: number) => {
        return `mealSelections[${index}].allergyInfo`;
    };

    const getAllergyInfoLabel = (fullName: string) => {
        return `Does ${fullName} have any food allergies that we should know about?`;
    };

    return (
        <Collapse in={isAttending}>
            <Box pt={4}>
                <Box fontWeight={700} mb={2}>
                    {renderLabel('MEAL_PREFERENCES')}
                </Box>
                <Box display="flex" justifyContent="center" flexDirection="column" gap={2} py={2} px={1}>
                    {guests.map((guest, index) => {
                        const showAllergyInfoField = shouldShowAllergyField(guest.id);

                        return (
                            <Box className="guest-meal-selection" key={guest.id}>
                                <Box
                                    component="label"
                                    className={clsx(styles.fieldLabel, 'meal-selection-label')}
                                    px={{ md: 2 }}
                                    fontSize={16}
                                >
                                    {guest.fullName}
                                </Box>
                                <Box flex={1} textAlign={{ xs: 'center', md: 'left' }}>
                                    <MealSelectField
                                        index={index}
                                        meals={meals}
                                        guestId={guest.id}
                                        selections={selections}
                                        label={guest.fullName}
                                    />
                                </Box>
                                <Collapse in={showAllergyInfoField}>
                                    <Box mt={2}>
                                        <Box className="allergy-info-label" component="small">
                                            {getAllergyInfoLabel(guest.fullName)}
                                        </Box>
                                        <WrappedFormField
                                            component={TextareaField}
                                            name={getAllergyInfoFieldName(index)}
                                            label=""
                                        />
                                    </Box>
                                </Collapse>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Collapse>
    );
}

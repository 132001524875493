import fs from 'fs';
import path from 'path';
import { getBackendComponentDirPath, getBackendDirPath } from 'server/helpers';

function toPascalCase(string) {
    return `${string}`
        .toLowerCase()
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(new RegExp(/\s+(.)(\w*)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
        .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}

const getFileJson = (filePath) => {
    return JSON.parse(fs.readFileSync(filePath, { encoding: 'utf-8' }));
};

const convertOutput = (obj) => {
    return Object.keys(obj).reduce((prevValues, key) => {
        try {
            const { src } = obj[key][0];
            if (src) {
                const schema = getFileJson(src);
                const schemaKey = schema?.info?.pluralName || key;
                return { ...prevValues, [schemaKey]: schema };
            }
            return prevValues;
		} catch (err) {
			console.log(err);
            return prevValues;
        }
    }, {});
};

const toHyphenCase = (str) => str.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();

const convertComponentOutput = (obj) => {
    return Object.keys(obj).reduce((prevValues, key) => {
        try {
            const compSchemas = obj[key];

            if (Array.isArray(compSchemas)) {
                compSchemas.forEach((config) => {
                    const { src, title } = config;

					const compKey = `${key}.${toHyphenCase(title)}`;
                    if (src) {
                        const schema = getFileJson(src);
						prevValues[compKey] = schema;
                    }
                });
            }
            return prevValues;
        } catch (err) {
            return prevValues;
        }
    }, {});
};

const getSchemaPaths = (isComponents = false) => {
    const getLastPath = (str) => {
        const [withoutExt] = str.split('.');
        const fields = withoutExt.split('/');
        const fileName = fields[fields.length - 1];
        return fileName;
    };

    const fromDir = (startPath, filter, callback, directory) => {
        //console.log('Starting from dir '+startPath+'/');

        if (!fs.existsSync(startPath)) {
            console.log('no dir ', startPath);
            return;
        }

        var files = fs.readdirSync(startPath);
        for (var i = 0; i < files.length; i++) {
            var filename = path.join(startPath, files[i]);
            var stat = fs.lstatSync(filename);
            if (stat.isDirectory()) {
                const directory = getLastPath(filename);
                fromDir(filename, filter, callback, directory); //recurse
            } else if (filter.test(filename)) callback(filename, directory);
        }
    };

    const output = {};

    const handleResult = (filename, directory) => {
        if (!output[directory]) {
            output[directory] = [];
        }
        const item = {
            src: filename,
            title: toPascalCase(getLastPath(filename)),
        };

        output[directory].push(item);
    };

    const dirPath = !isComponents ? getBackendDirPath() : getBackendComponentDirPath();

    const regex = !isComponents ? /schema\.json$/ : /\.json$/;

    fromDir(dirPath, regex, handleResult);

    return output;
};

export const getSchemaFilePaths = () => {
    const files = getSchemaPaths();
    return convertOutput(files);
};

export const getComponentSchemas = () => {
	const files = getSchemaPaths(true);
	
	return convertComponentOutput(files);
};

import { LabelDto } from '../../dtos/LabelDto';
import { DtoMap } from '../../types/types';
import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';

export class LabelsStore extends CollectionStore<LabelDto, DtoMap<LabelDto>> {
    constructor() {
        super(API_COLLECTIONS.LABELS);
        //  this.refresh();
    }

    refresh(): Promise<void> {
        return this.API.getAll({ url: '/api/bundles/labels' })
            .then((data) => {
                this.state = data as any;
                this.saveAll(this.state);
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

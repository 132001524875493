import { CollectionStore } from './CollectionStore';
import { HousingItem } from '../../widgets/hotels/types/types';
import { API_COLLECTIONS } from '../utils/createServerCollectionAPI';
import { services } from './services';
import { uniqueArray } from '../../utils/uniqArray';
import { MediaDto } from '../../dtos/MediaDto';

const getAttachmentIdsForItem = (house: HousingItem) => {
    const { attachments, roomAttachments } = house;
    const attachmentsMap = services.uploads.stateMap;
    return uniqueArray([...attachments, ...roomAttachments])
        .map((id) => {
            return attachmentsMap[id];
        })
        .filter(Boolean);
};

export class HousesStore extends CollectionStore<HousingItem> {
    constructor() {
        super(API_COLLECTIONS.HOUSES, 'slug');
        // this.refresh();
        this.setupOne = this.setupOne.bind(this);
    }

    async refresh(item?: HousingItem): Promise<void> {
        await super.refresh();
        const allHouses = this.state;

        if (item && item.slug) {
            await this.refreshOne(item);
            return;
        }

        const promises = allHouses.map(this.setupOne);
        await Promise.all(promises);
    }

    async refreshOne(house: HousingItem) {
        await this.setupOne(house);
    }

    async setupOne(house: HousingItem) {
        const { data, attachments } = (await this.API.get(house.slug as any)) as any;
        this.saveOne(data.slug, { data, attachments });
    }

    getOne(key: string | number) {
        const roomData = super.getOne(key);
        const { rooms } = roomData.data;
        if (!rooms?.length) {
            return roomData;
        }

        const contacts: any = services.contacts.stateMap;

        const mapBed = (bed: any) => {
            if (!bed?.guests?.length) {
                return bed;
            }
            return {
                ...bed,
                guests: bed.guests?.map((g) => {
                    const { contact } = g;
                    if (contact?.id) {
                        return {
                            ...g,
                            contact: {
                                ...contact,
                                phoneNumber: contacts[contact.id]?.contactDetails?.phoneNumber,
                            },
                        };
                    }
                    return g;
                }),
            };
        };

        const updatedRooms = rooms.map((room) => {
            const { beds } = room;
            if (!beds?.length) {
                return room;
            }
            return {
                ...room,
                beds: beds.map(mapBed),
            };
        });
        return {
            ...roomData,
            data: {
                ...roomData.data,
                rooms: updatedRooms,
                contacts,
            },
        };
    }

    getAllAttachments(houses = this.state): MediaDto[] {
        return houses.reduce((attachments: MediaDto[], house) => {
            const houseAttachments = getAttachmentIdsForItem(house);

            return uniqueArray([...attachments, ...houseAttachments]);
        }, []);
    }

    filterHouses(type: string) {
        if (!type) {
            return this.state;
        }
        switch (type) {
            case 'HOUSE':
                return this.state.filter((h) => h.type === type);
            default:
                return this.state.filter((h) => h.type !== type);
        }
    }

    getAll(type: string) {
        const houses = this.filterHouses(type);
        const attachments = this.getAllAttachments(houses);
        return {
            data: houses,
            attachments,
        };
    }
}

import { Link, useLocation } from 'react-router-dom';
import { CtaDto } from 'dtos/components/CtaDto';
import React from 'react';
import { isInternalLink } from 'utils/isInternalLink';
import styles from './CtaButton.module.scss';
import clsx from 'clsx';

interface CommonProps {
    [key: string]: any;
}

const getBtnClasses = ({ color, variant, size, isMainNav }: CtaDto & CommonProps, isActive: boolean) => {
    return clsx(styles.btn, {
        [styles[variant]]: !!variant,
        [styles[color]]: !!color,
        [styles[color]]: !!size,
        [styles['isActive']]: isActive,
        [styles['isMainNav']]: isMainNav,
    });
};

export function CtaButton(props: CtaDto & CommonProps) {
    const { href, label, activeRef, onMouseEnter, onMouseLeave } = props;
    const location = useLocation();
    const isInternal = isInternalLink(href);
    const isActive = href === location.pathname;
    const className = getBtnClasses(props, isActive);

    if (isInternal) {
        return (
            <Link
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                ref={isActive ? activeRef : undefined}
                className={className}
                to={href}
            >
                {label}
            </Link>
        );
    }
    return (
        <a
            ref={isActive ? activeRef : undefined}
            className={className}
            href={href}
            target="_blank"
            rel="noreferrer noopener"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {label}
        </a>
    );
}

import React, { ReactNode } from 'react';
import { RouteDataContext } from './RouteDataContext';
import { INITIAL_DATA } from '../../utils/createInitialDataScript';

const getRouteData = () => {
    return (window as any)[INITIAL_DATA] || {};
};

interface ClientRouteDataProviderProps {
    children: ReactNode | ReactNode[];
}

export function ClientRouteDataProvider({ children }: ClientRouteDataProviderProps) {
    const data = getRouteData();
    return <RouteDataContext.Provider value={data}>{children}</RouteDataContext.Provider>;
}

import React, { ReactNode, createContext } from 'react';

type Device = 'desktop' | 'mobile';

export const DeviceContext = createContext<Device>('desktop');

interface DeviceProviderProps {
    device: Device;
    children: ReactNode | ReactNode[];
}

export function DeviceProvider({ device, children }: DeviceProviderProps) {
    return <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>;
}

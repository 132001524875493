export function toListString(items: string[]) {
    let output = '';
    if (!Array.isArray(items)) {
        return output;
    }
    const strArray = items.map((str, index) => {
        if (index && index === items.length - 1) {
            return `and ${str}`;
        }
        return str;
    });

    const JOINER = strArray.length > 2 ? ', ' : ' ';

    return strArray.join(JOINER);
}

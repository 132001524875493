import { createSelector } from "reselect";
import { getState } from "./state";
import { MediaDto } from "dtos/MediaDto";

export const selectAttachments = createSelector(getState, (state): MediaDto[] => state.attachments || []);

export interface MediaDtoMap {
    [key: number]: MediaDto | undefined;
}

export function toAttachmentMap(attachments: MediaDto[]): MediaDtoMap {
    return attachments.reduce((attachmentMap: MediaDtoMap, attachment) => {
        attachmentMap[attachment.id] = attachment;
        return attachmentMap;
    }, {});
}

export const selectAttachmentsMap = createSelector(selectAttachments, toAttachmentMap);
import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { ContactDto, GuestDto } from 'dtos/ContactDto';
import { RsvpDto } from 'dtos/RsvpDto';
import { CustomCard } from 'components/CustomCard/CustomCard';
import { renderLabel } from 'utils/renderLabel';
import { formatDate, formatIsoDate } from 'utils/formatDate';
import { EventDto } from 'dtos/EventDto';
import { MealDto } from '../../dtos/MealDto';
import { getDtoMap } from '../../utils/getDtoMap';
import { BoxProps } from '@mui/material';
import { DEFAULT_BORDER } from '../../theme';

interface DataRowProps extends BoxProps {
    label: ReactNode;
    total: ReactNode;
    fontSize?: number;
    noBorder?: boolean;
    className?: string;
    boldedTitle?: boolean;
    totalSx?: CSSProperties;
    padding?: any;
}

export function DataRow({ label, total, fontSize = 14, noBorder, padding = 1, boldedTitle = true }: DataRowProps) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                borderTop: !noBorder ? DEFAULT_BORDER : undefined,
                fontSize,
                justifyContent: 'space-between',
                padding: padding * 8,
            }}
        >
            <div>{label}:</div>
            {boldedTitle ? <b style={{ marginLeft: 16 }}>{total}</b> : <div style={{ marginLeft: 16 }}>{total}</div>}
        </div>
    );
}

interface EmailTemplateProps {
    contact: ContactDto & { rsvp?: RsvpDto };
    meals: MealDto[];
    events: EventDto[];
    showAdminButton?: boolean;
}

export function EmailTemplate({ contact, meals, events, showAdminButton = true }: EmailTemplateProps) {
    const { rsvp, guests, addressHeading, fullName } = contact;

    const mealsMap = useMemo(() => {
        return getDtoMap<MealDto>(meals || []);
    }, [meals]);

    const getBooleanLabel = (val: boolean) => {
        const label = val ? 'Yes' : 'No';
        return <b style={{ color: val ? 'green' : 'red' }}>{label}</b>;
    };

    const getMealValue = (guest: GuestDto) => {
        const { mealSelections = [] } = rsvp;
        const selection = mealSelections.find((m) => m.guestId === guest.id);

        if (selection) {
            const { mealId } = selection;
            const meal = mealsMap[mealId]?.title || 'Not attending';

            return <DataRow fontSize={12} key={guest.id} label={guest.fullName} total={meal} boldedTitle />;
        }

        return null;
    };

    const renderConfirmView = () => {
        const { updatedAt, welcomeDrinks, reception, sundayBrunch, lodging, comments } = rsvp;
        return (
            <div style={{ backgroundColor: '#eee', padding: 24 }}>
                <p>
                    Hi <b>{fullName}!</b>
                </p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: renderLabel('RSVP_CONFIRMATION', `<b>${formatDate(updatedAt)}</b>`),
                    }}
                />
                <div style={{ backgroundColor: '#fafafa', border: DEFAULT_BORDER, margin: '0 auto' }}>
                    <DataRow noBorder label="Welcome drinks" total={getBooleanLabel(welcomeDrinks)} />
                    <div>
                        <DataRow noBorder label="Ceremony & reception" total={getBooleanLabel(reception)} />
                        <div style={{ textAlign: 'left', fontSize: 14, paddingBottom: 16, marginLeft: 32 }}>
                            {guests.map(getMealValue)}
                        </div>
                    </div>
                    <DataRow noBorder label="Sunday brunch" total={getBooleanLabel(sundayBrunch)} />

                    {lodging && <DataRow noBorder boldedTitle={false} label="Lodging" total={lodging} />}

                    {comments && (
                        <div style={{ textAlign: 'left', fontSize: 14 }}>
                            <p>Comments:</p>
                            <p>{comments}</p>
                        </div>
                    )}
                </div>
                <div style={{ marginTop: 24 }}>
                    <a href={`https://codyandhenry.com/rsvp/${contact.id}`}>Click here to edit your RSVP</a>
                </div>
                <p>Thanks again!</p>
                <p>
                    <b>Cody & Henry</b>
                </p>
            </div>
        );
    };

    return renderConfirmView();
}

import { GuestDto } from 'dtos/GuestDto';
import { ACTIVITY_ACTIONS, GUEST_ACTIONS } from 'store/actionTypes';
import { GUESTS_INITIAL_STATE } from 'store/reducers/guestsReducer';
import { selectGuestsApiParams } from 'store/selectors/guests';
import { AppThunk } from 'types/types';
import { API_COLLECTIONS, createCollectionApi } from 'utils/createCollectionApi';
import { createFetchActionHooks } from './ApiStatus';
import { FiltersDto } from 'dtos/ListPayload';

const GuestsAPI = createCollectionApi<GuestDto>(API_COLLECTIONS.GUESTS);

function GET_ALL(filters: FiltersDto = {}): AppThunk<ReturnType<typeof GuestsAPI.getAll>> {
    return async (dispatch, getState) => {
        const apiHooks = createFetchActionHooks('GET_ALL_GUESTS', dispatch);
        apiHooks.START();
        const params = selectGuestsApiParams(getState());
        const { search, ...otherFilters } = filters;
        const data = await GuestsAPI.getAll({ ...params, filters: otherFilters, search, version: 'v2' } as any)
            .then((results) => {
                apiHooks.COMPLETE(results);
                return results;
            })
            .catch((error) => {
                apiHooks.ERROR(error);
                return GUESTS_INITIAL_STATE;
            });
        dispatch({
            type: GUEST_ACTIONS.GET_ALL,
            payload: data,
        });
        return data;
    };
}

function SAVE(guest: GuestDto): AppThunk<Promise<GuestDto>> {
    return async (dispatch, getState) => {
        const data = await GuestsAPI.save(guest);
        const type = guest.id ? GUEST_ACTIONS.SET_GUEST : GUEST_ACTIONS.ADD_GUEST;
        dispatch({
            type,
            payload: {
                ...guest,
                id: guest.id || data.id,
            },
        });
        return data;
    };
}

function DELETE(id: number): AppThunk<Promise<GuestDto>> {
    return async (dispatch) => {
        const data = await GuestsAPI.delete(id);
        dispatch({
            type: GUEST_ACTIONS.DELETE_GUEST,
            payload: id,
        });
        return data;
    };
}

function SET_PAGE(page: number): AppThunk<ReturnType<typeof GuestsAPI.getAll>> {
    return async (dispatch) => {
        dispatch({ type: GUEST_ACTIONS.SET_PAGE_NUMBER, payload: page });
        const data = await dispatch(GET_ALL());
        return data;
    };
}

export const GuestActions = {
    GET_ALL,
    SAVE,
    DELETE,
    SET_PAGE,
};

import React, { ReactNode } from 'react';
import { RouteDataContext } from './RouteDataContext';
import { INITIAL_DATA } from '../../utils/createInitialDataScript';

let initialData: any = {};

const getSsrRouteData = () => {
    return initialData;
};

export const getSsrRouteDataAndClearCache = () => {
    const clone = { ...initialData };
    initialData = {};
    return clone;
};

export const setSsrRouteData = (data: any) => {
    initialData = data;
    return initialData;
};

export const setSsrRouteDataKeyValue = (key: string, value: any) => {
    initialData[key] = value;
    return initialData;
};

export const getClientRouteData = () => {
    return (window as any)[INITIAL_DATA] || {};
};

export const setClientRouteDataKeyValue = (key: string, value: any) => {
    (window as any)[INITIAL_DATA] = { ...getClientRouteData(), [key]: value };
    return getClientRouteData();
};

interface ClientRouteDataProviderProps {
    children: ReactNode | ReactNode[];
}

export function SsrRouteDataProvider({ children }: ClientRouteDataProviderProps) {
    const data = getSsrRouteData();
    return <RouteDataContext.Provider value={data}>{children}</RouteDataContext.Provider>;
}

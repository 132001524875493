import React from 'react';
import { getIsUserAdmin, useLoggedInAdmin } from 'hooks/useLoggedInUser';
import loadable from '@loadable/component';
import { GetDataOptions } from 'types/types';
import { selectFilterQuery } from 'store/selectors/filters';
import { GuestActions } from 'actions/Guests';

const GuestsViews = loadable(
    () =>
        import(
            /* webpackChunkName: "GuestsView" */
            './GuestsView'
        )
);

export function LazyGuestsView() {
    const admin = useLoggedInAdmin();

    if (!admin) {
        return <div style={{ minHeight: '100vh' }} />;
    }

    return <GuestsViews />;
}

LazyGuestsView.getData = async ({ store }: GetDataOptions) => {
    const state = store.getState();
    const isAdmin = getIsUserAdmin(state.loggedInUser);

    if (isAdmin) {
        const filters = selectFilterQuery(state);
        const data = await store.dispatch(GuestActions.GET_ALL(filters));
        return data;
    }
    return undefined;
};

import { ReactPageDto } from "dtos/ReactPageDto";
import { createSelector } from "reselect";
import { getState, RootState } from "store/selectors/state";

export const selectPages = createSelector(
  getState,
  (state) => state.pages || {}
);

export function selectCurrentPage<PDto = ReactPageDto>(slug: string) {
  return createSelector(selectPages, (pages) => pages[slug]) as unknown as (
    state: RootState
  ) => PDto | undefined;
}

import { useSelector } from 'react-redux';

export function useLoggedInUser() {
    return useSelector((state) => (state as any).loggedInUser) || undefined;
}

export const getIsUserAdmin = (user: any) => {
    return user?.role?.type === 'admin';
};

export function useLoggedInAdmin() {
    const user = useLoggedInUser();
    return getIsUserAdmin(user) ? user : undefined;
}

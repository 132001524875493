import { getFetcher } from "store/actions/fetcher";
import { EventDto } from "dtos/EventDto";
import { MealDto } from "dtos/MealDto";
import { RsvpDto } from 'dtos/RsvpDto';
import { ContactDto } from 'dtos/ContactDto';

type ContactDtoWithRsvp = ContactDto & { rsvp?: RsvpDto };

function fetchMeals(){
    return getFetcher<MealDto[]>().get('/api/meals');
}

function fetchEvents(){
    return getFetcher<EventDto[]>().get('/api/events');
}

function fetchContact(id: string | number){
    return getFetcher<ContactDtoWithRsvp>().get(`/api/contacts/${id}`);
}

export interface RsvpFormDataProps {
    contact: ContactDtoWithRsvp,
    meals: MealDto[],
    events: EventDto[],
}

export async function getRsvpFormData(contactId: string | number): Promise<RsvpFormDataProps>{
    const contact = await fetchContact(contactId);
    const meals = await fetchMeals();
    const events = await fetchEvents();

    return {
        contact,
        meals,
        events,
    }
}
import React from 'react';
import loadable from '@loadable/component';
import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import { Box, Container, Paper } from '@mui/material';
import { useStickyHeaderDispatch } from 'hooks/useStickyHeader';
import { renderLabel } from 'utils/renderLabel';
import { AddLabelButton } from './AddLabelButton';

// This dynamic import will not be processed server-side
export const LabelTool = loadable(
    () =>
        import(
            /* webpackChunkName: "LabelTool" */
            './LabelTool'
        )
);

export function LazyLabelTool() {
    const adminUser = useLoggedInAdmin();
    useStickyHeaderDispatch();

    if (!adminUser) {
        return null;
    }

    return (
        <div>
            <Container sx={{ my: 5 }} maxWidth="md">
                <Paper sx={{ py: 2, px: 2 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <h1>{renderLabel('LABELS')}</h1>
                        <AddLabelButton />
                    </Box>
                    <LabelTool />
                </Paper>
            </Container>
        </div>
    );
}

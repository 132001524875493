import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MealDto } from '../../dtos/MealDto';
import { ContactDto } from '../../dtos/ContactDto';
import { getFetcher } from '../../store/actions/fetcher';
import { useRouteDataSelection } from '../ReactPage/hooks/useRouteDataSelection';

interface RsvpFormContextInterface {
    meals?: MealDto[];
    contact: ContactDto;
}

const RsvpFormContext = createContext<RsvpFormContextInterface | undefined>(undefined);

export function useRsvpFormContext() {
    const context = useContext(RsvpFormContext);

    if (!context) {
        throw new Error('component is not a child of RsvpFormProvider');
    }

    return context;
}

const fetchMeals = () => getFetcher<MealDto[]>().get('/api/meals');

export const RsvpFormProvider: React.FC<RsvpFormContextInterface> = ({ contact, children }) => {
    const [initialMeals] = useRouteDataSelection('meals');
    const [meals, setMeals] = useState<MealDto[]>(initialMeals || []);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const getMeals = useCallback(() => {
        fetchMeals()
            .then(setMeals)
            .catch((err) => {
                timeoutRef.current = setTimeout(() => {
                    getMeals();
                }, 1000);
            });
    }, []);

    useEffect(() => {
        if (initialMeals?.length) {
            return;
        }

        getMeals();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [initialMeals]);

    return <RsvpFormContext.Provider value={{ meals, contact }}>{children}</RsvpFormContext.Provider>;
};

import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { clientRoutes } from "routes/routes";

export function AppRoutes() {
  const history = useHistory();
  React.useEffect(() => {
    (window as any).appHistory = history;
  }, [history]);
  return (
    <Switch>
      {clientRoutes.map((route, index) => {
        const { path, exact = true, component: RouteComponent } = route;
        return (
          <Route key={`${path}-${index}`} exact={exact} path={path}>
            <RouteComponent />
          </Route>
        );
      })}
    </Switch>
  );
}

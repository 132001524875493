import React, { useEffect } from 'react';
import { FETCH_STATUS, GetDataOptions } from '../../types/types';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { GuestDto } from '../../dtos/GuestDto';
import { Box, Button, CircularProgress } from '@mui/material';
import { renderLabel } from 'utils/renderLabel';
import { toListString } from '../../utils/toListString';
import { useRouteDataSelection } from '../ReactPage/hooks/useRouteDataSelection';
import { setSsrRouteDataKeyValue } from '../../providers/DataProvider/RouteDataProvider.server';
import { getFetcher } from '../../store/actions/fetcher';
import { RsvpPageWrapper } from '../../components/RsvpPageWrapper/RsvpPageWrapper';
import { setCookie } from '../../hooks/useCookie';

interface ConfirmViewProps {
    guest: GuestDto;
    setGuest: (guest?: GuestDto) => void;
}

const getGuestId = (guestId: number | string) => {
    return `guest${guestId}`;
};

const fetchGuest = (guestId: string | number) => getFetcher<GuestDto>().get(`/api/guests/${guestId}`);

export function ConfirmView() {
    const history = useHistory<Record<string, any>>();
    const { guestId } = useParams<Record<string, string>>();
    const {
        location: { state: guestData },
    } = history;
    const [guest, status] = useRouteDataSelection<GuestDto>(
        getGuestId(guestId),
        async () => {
            if (guestData?.id?.toString() !== guestId) {
                return fetchGuest(guestId);
            } else {
                return guestData;
            }
        },
        [guestId]
    );

    const { contact } = guest || {};

    if (status === FETCH_STATUS.LOADING || !contact) {
        return <CircularProgress size={32} />;
    }

    const onYesClick = () => {
        setCookie('contactId', contact.id, 350);
        history.push(`/rsvp/${contact.id}`);
    };

    const onNoClick = () => {
        history.push(`/rsvp`);
    };

    return (
        <RsvpPageWrapper>
            <p>
                <b>{renderLabel('IS_THIS_YOU')}</b>
            </p>
            <p>{toListString(contact.guests.map((g) => g.fullName))}</p>
            <p>
                <Button autoFocus onClick={onYesClick} sx={{ minHeight: 48 }} fullWidth variant="contained">
                    {renderLabel('YES_CONTINUE')}
                </Button>
            </p>
            <p>
                {renderLabel('NOT_YOU')}
                <Button onClick={onNoClick} variant="text">
                    {renderLabel('PICK_DIFFERENT_NAME')}
                </Button>
            </p>
        </RsvpPageWrapper>
    );
}

ConfirmView.getData = async ({ match }: GetDataOptions) => {
    const guestId = match.params?.guestId;
    if (guestId) {
        setSsrRouteDataKeyValue(getGuestId(guestId), await fetchGuest(guestId));
    }
};

import { Box, Grid } from '@mui/material';
import { WrappedFormField } from 'components/InputText/WrappedInputText';
import React, { FC, Fragment, useCallback, useMemo } from 'react';
import styles from './PluginEditorForm.module.scss';
import { InputComponentProps } from 'components/InputText/InputComponentProps';
import { useFormikContext } from 'formik';
import { useDeviceContext } from 'hooks/useDeviceContext';
import { BaseInlineInput } from '../fields/InlineInput/InlineInput';

export interface FieldColumn<TValue = any> {
    name: string;
    label: string;
    sm?: number;
    type?: 'text' | 'number' | 'color' | 'checkbox';
    options?: any[];
    clearable?: boolean;
    autoComplete?: string;
    component?: FC<InputComponentProps<TValue>>;
    autoFocus?: boolean;
    validate?: (val: TValue) => string | undefined;
}

export type ColumnItem = FieldColumn | FieldColumn[] | false | any;

export type ColumnItemCallbackFunction = (values: any) => ColumnItem | ColumnItem[];

export type FieldsArray = Array<ColumnItem | ColumnItemCallbackFunction>;

export type PluginEditorFormProps = {
    flex?: boolean;
    columns?: FieldsArray;
    clearable?: boolean;
    inline?: boolean;
    isInlineForm?: boolean;
    cellClassName?: string;
};

const renderFormField = (columnConfig, { values, inline, isInlineForm }: any) => {
    let column: any = columnConfig;

    if (typeof columnConfig === 'function') {
        column = columnConfig(values) as ColumnItem;
    }

    if (!column) {
        return null;
    }

    if (Array.isArray(column)) {
        return <PluginEditorForm columns={column} flex inline={inline} clearable={false} />;
    }

    const {
        name,
        label,
        validate,
        component: customComponent,
        autoFocus,
        clearable,
        ...componentProps
    } = column as FieldColumn;

    let component = customComponent;

    if (!component && inline) {
        component = BaseInlineInput as any;
    }

    return (
        <WrappedFormField
            clearable={clearable}
            {...componentProps}
            autoFocus={autoFocus}
            name={name}
            component={component}
            label={label}
            validate={validate}
            isInlineForm={isInlineForm}
            className={isInlineForm ? styles.inlineFormField : styles.inputField}
        />
    );
};

export function PluginEditorForm({
    columns,
    flex,
    inline,
    clearable = true,
    isInlineForm,
    cellClassName,
}: PluginEditorFormProps = {}) {
    const { values } = useFormikContext();
    const device = useDeviceContext();

    const isMobile = device === 'mobile';

    const renderField = useCallback(
        (columnConfig: ColumnItem | ColumnItemCallbackFunction, idx: number) => {
            const { name, sm = 12, width } = columnConfig;
            const key = `${name}-${idx}`;
            const field = renderFormField(columnConfig, { values, inline: inline || isInlineForm, isInlineForm });

            if (isInlineForm) {
                return (
                    <Box key={key} className={cellClassName} minWidth={width || 200}>
                        {field}
                    </Box>
                );
            }

            if (inline) {
                return <Fragment key={key}>{field}</Fragment>;
            }
            return (
                <Grid item xs={12} sm={isMobile ? 12 : sm} key={key}>
                    {field}
                </Grid>
            );
        },
        [isMobile, inline, values]
    );

    const fields = useMemo(() => {
        return columns.map(renderField);
    }, [renderField, columns]);

    if (inline || isInlineForm) {
        return <Fragment>{fields}</Fragment>;
    }

    return (
        <Grid spacing={1.5} container>
            {fields}
        </Grid>
    );
}

import canUseDOM from 'helpers/canUseDom';
import React, { ReactNode } from 'react';
import { StaticRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

type AppRouterProps = {
    children?: ReactNode | ReactNode[];
    location?: string;
    history?: any;
};

export default function AppRouter({ children, location, history }: AppRouterProps) {
    if (canUseDOM()) {
        // @ts-ignore
        return <ConnectedRouter history={history}>{children}</ConnectedRouter>;
    }
    return <StaticRouter location={location}>{children as any}</StaticRouter>;
}

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsMobile } from '../store/selectors/env';
import { useEffect } from 'react';
import { debounce } from '../utils/debounce';

export function useIsMobile(): boolean {
    const isMobileExp = useSelector(selectIsMobile);
    const [isMobile, setIsMobile] = useState(isMobileExp);

    useEffect(() => {
        setIsMobile(window.innerWidth < 960);
        const handleResize = debounce(() => {
            setIsMobile(window.innerWidth < 960);
        }, 100);
        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize, false);
        };
    }, []);

    return isMobile;
}

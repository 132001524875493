import { UserDto } from 'dtos/UserDto';
import { PageDto } from 'dtos/PageDto';
import { ReactPageDto } from 'dtos/ReactPageDto';
import { NavDto } from 'dtos/NavDto';
import { MediaDto } from 'dtos/MediaDto';
import { ActivityDto } from 'dtos/ActivityDto';
import { FiltersDto, ListPayload } from 'dtos/ListPayload';
import { GuestDto } from 'dtos/GuestDto';
import { FETCH_STATUS } from 'types/types';
import { HouseDto } from '../../dtos/HouseDto';
import { HousingItem } from 'widgets/hotels/types/types';
import { FeatureDto } from 'dtos/FeatureDto';

interface FetchStatusItem {
    status: FETCH_STATUS;
    data?: any;
    error?: Error;
}

interface FeaturesMap {
    [key: string]: FeatureDto;
}

export interface RootState {
    router: {
        action: string;
        location: {
            pathname: string;
            search: string;
            query: FiltersDto;
            params: { [key: string]: string | undefined };
        };
    };
    env: { isMobile?: boolean; [key: string]: any };
    pages: { [key: string]: ReactPageDto | PageDto | undefined };
    menus: { [key: string]: NavDto | undefined };
    users: UserDto[];
    hotels: HousingItem[];
    attachments: MediaDto[];
    features: FeaturesMap;
    activities: ListPayload<ActivityDto[]>;
    guests: ListPayload<GuestDto[]>;
    fetchStatus: {
        [key: string]: undefined | FetchStatusItem;
    };
    loggedInUser: UserDto;
}

export function getState(state: RootState) {
    return state;
}

import React from 'react';
import { routerMiddleware } from 'connected-react-router';
import { loadableReady } from '@loadable/component';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import createStore from 'store/createStore';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from 'utils/createEmotionCache';
import { history } from 'utils/createHistory';
import App from './appClient';
import { ClientRouteDataProvider } from '../providers/DataProvider/RouteDataProvider.client';

const initialState = (window as any)['__INITIAL_STATE__'] || {};

const getRoot = () => document.getElementById('root') as HTMLDivElement;

const root = getRoot();

const cache = createEmotionCache();

const renderMethod = hydrate;

function renderWhenReady() {
    const jsx = (
        <ClientRouteDataProvider>
            <Provider store={createStore(initialState, [routerMiddleware(history)], history)}>
                <CacheProvider value={cache}>
                    <App history={history} />
                </CacheProvider>
            </Provider>
        </ClientRouteDataProvider>
    );

    renderMethod(jsx, root);
}

loadableReady(renderWhenReady)
    .then(() => {})
    .catch((err) => {
        alert(err.toString());
    });

import { HOTEL_ACTIONS } from 'store/actionTypes';
import { HouseDto } from '../../dtos/HouseDto';
import { uniqueArray } from '../../utils/uniqArray';

export const HOTELS_INTIAL_STATE: HouseDto[] = [];

export function hotelsReducer(state = HOTELS_INTIAL_STATE, action) {
    switch (action.type) {
        case HOTEL_ACTIONS.GET_ALL_HOTELS: {
            return uniqueArray([...state, ...action.payload]);
        }
        case HOTEL_ACTIONS.SET_HOTEL: {
            const { id } = action.payload;

            const inState = state.some((h) => h.id === id);

            if (inState) {
                return state.map((hotel) => {
                    return hotel.id === id ? action.payload : hotel;
                });
            } else {
                return [...state, action.payload];
            }
        }
        case HOTEL_ACTIONS.DELETE_HOTEL: {
            return state.filter((activity) => activity.id !== action.payload);
        }
        case HOTEL_ACTIONS.ADD_HOTEL: {
            return uniqueArray([action.payload, ...state]);
        }
        default:
            return state;
    }
}

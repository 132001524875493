import React, { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router';
import { renderLabel } from 'utils/renderLabel';
import { EventDto } from 'dtos/EventDto';
import { RsvpDto } from '../../dtos/RsvpDto';
import { ContactDto } from '../../dtos/ContactDto';
import { FormWrapper } from '../../forms/FormWrapper/FormWrapper';
import { Event } from '../../components/RsvpStepperForm/steps/Events/Event';
import { FETCH_STATUS } from '../../types/types';
import styles from './RsvpContactPage.module.scss';
import { API_COLLECTIONS, ClientAPI } from '../../server/utils/createServerCollectionAPI';
import { FormikHelpers } from 'formik';
import { removeCookie } from '../../hooks/useCookie';
import { toListString } from '../../utils/toListString';
import { FormSubmitButton } from '../../providers/FormSubmitButton';
import { RsvpFormProvider } from './RsvpFormProvider';
import { TextareaField } from '../../components/InputText/TextareaField';
import { PluginEditorForm } from '../../providers/PluginEditorForm';
import { DEFAULT_FIELD_BORDER } from '../../theme';
import { MealDto } from '../../dtos/MealDto';
import { Image } from '../../atoms/Image/Image';

interface NewRsvpFormProps {
    contact: ContactDto;
    meals?: MealDto[];
    events?: EventDto[];
    onUpdate?: (rsvp?: RsvpDto) => void;
}

const RsvpAPI = new ClientAPI<RsvpDto>(API_COLLECTIONS.RSVPS);

interface EventsFormWrapperProps {
    contact: ContactDto & { rsvp?: RsvpDto };
    events: EventDto[];
    meals?: MealDto[];
    onUpdate?: (rsvp?: RsvpDto) => void;
}

const fields = [
    (values: RsvpDto) => {
        if (values.reception || values.welcomeDrinks || values.sundayBrunch) {
            return {
                name: 'lodging',
                label: 'Where will you be staying?',
                required: true,
                showError: true,
            };
        }
    },
    { name: 'comments', label: 'Additional comments', component: TextareaField },
];

const validateForm = (values: any, helpers: FormikHelpers<any>) => {
    const isAttending = values.reception || values.welcomeDrinks || values.sundayBrunch;

    if (isAttending && !values.lodging) {
        helpers.setFieldError('lodging', 'Please enter where you will be staying.');
        return false;
    }

    return true;
};

function EventsFormWrapper({ events, contact, meals, onUpdate }: EventsFormWrapperProps) {
    const { rsvp, guests } = contact;

    const onSubmit = useCallback(async (values: RsvpDto, helpers: FormikHelpers<RsvpDto>) => {
        if (validateForm(values, helpers)) {
            helpers.setStatus(FETCH_STATUS.LOADING);
            await RsvpAPI.save(values)
                .then((res) => {
                    helpers.setStatus(FETCH_STATUS.COMPLETE);
                    helpers.setFieldValue('id', res.id);
                    if (res.id) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                })
                .catch((err) => {
                    helpers.setStatus(FETCH_STATUS.ERROR);
                });
        }
    }, []);

    return (
        <RsvpFormProvider meals={meals} contact={contact}>
            <Box className={styles.newRsvpForm}>
                <FormWrapper<RsvpDto>
                    onSubmit={onSubmit}
                    initialValues={{
                        reception: true,
                        welcomeDrinks: true,
                        sundayBrunch: true,
                        ...rsvp,
                        contactId: contact.id,
                    }}
                >
                    {events.map((event) => {
                        return <Event event={event} key={event.id} />;
                    })}
                    <Box mb={2} mt={0.5} padding={2} border={DEFAULT_FIELD_BORDER} borderRadius={1}>
                        <Box className={styles.heading1} component="h1">
                            Additional info
                        </Box>
                        <PluginEditorForm columns={fields} />
                    </Box>
                    <FormSubmitButton fullWidth>{renderLabel('SUBMIT_RSVP')}</FormSubmitButton>
                </FormWrapper>
            </Box>
        </RsvpFormProvider>
    );
}

export function NewRsvpForm({ contact, meals, events, onUpdate }: NewRsvpFormProps) {
    const history = useHistory();

    const { addressHeading, guests, customHeading, customPhoto } = contact;

    const guestNames = guests.map((g) => g.fullName);

    const title = toListString(guestNames);

    const formTitle = renderLabel('RSVP_FOR_CUSTOM', customHeading || title);

    const onNoClick = () => {
        removeCookie('contactId');
        history.push('/rsvp');
    };

    return (
        <div className={styles.rsvpFormPage}>
            <h3 className={styles.heading}>{formTitle}</h3>
            {!!customHeading && <span>({title})</span>}
            {customPhoto && <Image image={customPhoto} rounded />}
            <Box>
                {renderLabel('NOT_YOU')}
                <Button autoFocus sx={{ textDecoration: 'underline' }} onClick={onNoClick} variant="text">
                    {renderLabel('PICK_DIFFERENT_NAME')}
                </Button>
            </Box>
            <EventsFormWrapper events={events} contact={contact} meals={meals} onUpdate={onUpdate} />
        </div>
    );
}

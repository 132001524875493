import loadable from '@loadable/component';

const EventTracker = loadable(
    () =>
        import(
            /* webpackChunkName: "EventTracker" */
            './TrackingProvider'
        )
);

export default EventTracker;

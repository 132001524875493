import React, { useCallback, useEffect, FocusEvent } from 'react';
import styles from './InlineInput.module.scss';
import { InputComponentProps } from 'components/InputText/InputComponentProps';
import { withInputWrapper } from 'fields/InlineFieldWrapper/InlineFieldWrapper';
import { getValueFromResponsiveField, isObject } from 'fields/SxField/helpers';
import { ResponsiveFieldValue } from 'fields/SxField/SxFieldItem';
import clsx from 'clsx';

export function BaseInlineInput({
    field,
    label,
    helpers,
    meta,
    className,
    isInlineForm,
    type,
    disabled,
}: InputComponentProps<string>) {
    const { value } = field;
    const { setValue } = helpers;
    useEffect(() => {
        if (isObject(value)) {
            setValue(getValueFromResponsiveField(value as ResponsiveFieldValue) as string);
        }
    }, [value, setValue]);

    const onFocus = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            if (isInlineForm) {
                const { currentTarget } = e;
                currentTarget.setSelectionRange(0, currentTarget.value.length);
            }
        },
        [isInlineForm]
    );

    return (
        <input
            className={clsx(className, !className && styles.input)}
            placeholder={label}
            disabled={disabled}
            {...field}
            type={type}
            onFocus={onFocus}
        />
    );
}

export const InlineInput = withInputWrapper(BaseInlineInput);

import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { getIsMobileEnv } from 'components/AppHeader/selectors';
import { createPortal } from 'react-dom';

const MobileAppFooter = loadable(() => import(/* webpackChunkName: "MobileAppFooter" */ './MobileAppFooter'));

export function LazyMobileAppFooter() {
    const [showFooter, setShowFooter] = useState(false);
    const isMobile = useSelector(getIsMobileEnv);

    useEffect(() => {
        setShowFooter(isMobile);
    }, [isMobile]);

    if (!showFooter) {
        return null;
    }

    return createPortal(
        <div id="app-footer">
            <MobileAppFooter />
        </div>,
        document.body
    );
}

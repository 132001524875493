import fs from 'fs';
import path from 'path';
import { getBundlesDirPath } from '../helpers';
import { API_COLLECTIONS, createServerCollectionAPI } from '../utils/createServerCollectionAPI';
import { BaseDto } from '../../utils/getDtoId';
import { getDtoMap } from '../../utils/getDtoMap';
// @ts-ignore
import { getSchemaFilePaths } from '../utils/schemas';
import { isProduction } from 'helpers/env';

const getFilePathHelper = (baseDir: string, fileName: string | number) => {
    const filePath = fileName.toString().includes('.json') ? fileName.toString() : `${fileName}.json`;
    return path.join(baseDir, filePath);
};

const isFile = (path: string) => {
    try {
        return fs.lstatSync(path).isFile();
    } catch (err) {
        return false;
    }
};

const getFileJson = (filePath: string) => {
    if (isFile(filePath)) {
        return JSON.parse(fs.readFileSync(filePath, { encoding: 'utf-8' }));
    } else {
        throw new Error('File does not exist');
    }
};

export const getCollectionPluralName = (collection: API_COLLECTIONS) => {
    if (!collection) {
        return collection;
    }
    if (!collection.endsWith('s')) {
        return `${collection}s`;
    }
    return collection;
};

const setupDirectory = (collection: API_COLLECTIONS) => {
    const directory = getBundlesDirPath(getCollectionPluralName(collection));

    if (!fs.existsSync(directory)) {
        fs.mkdirSync(directory);
    }

    return directory;
};

const SCHEMAS = getSchemaFilePaths();

export class CollectionStore<TDto extends BaseDto = BaseDto, TStateDto = any> {
    collection: string;
    collectionDir: string;
    state: TStateDto;
    primaryKey: keyof TDto;
    initialized: boolean;
    API: any;
    params: any;
    allFields: any[];
    schema: any;

    constructor(collection: API_COLLECTIONS, primaryKey: string = 'id', params: any = {}) {
        this.collection = collection;
        this.API = createServerCollectionAPI<TDto>(collection);
        this.collectionDir = setupDirectory(collection);
        this.primaryKey = primaryKey as keyof TDto;
        this.params = params;
        this.schema = SCHEMAS[collection];
    }

    initialize() {
        try {
            this.state = this.loadState();
        } catch (err) {
            this.refresh();
        }
    }

    get stateMap() {
        const { state } = this;
        return getDtoMap(state as any, this.primaryKey as string);
    }

    get data() {
        return this.state || ([] as any);
    }

    refresh(item?: TDto) {
        console.log(`UPDATING ${this.collection.toUpperCase()} STORE...`);
        return this.API.getAll(this.params)
            .then(({ data }) => {
                this.state = this.transformListResponse(data);
                this.saveAll(this.state);
                console.log(`${this.collection.toUpperCase()} STORE UPDATED`);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getFilePath(fileName: string | number) {
        const { collectionDir } = this;
        return getFilePathHelper(collectionDir, fileName);
    }

    getOne(key: string | number) {
        const filePath = this.getFilePath(key);
        return this.loadFileData(filePath);
    }

    loadFileData(filePath: string) {
        return getFileJson(filePath);
    }

    saveOne(key: string | number, data: any) {
        const filePath = this.getFilePath(key);
        fs.writeFileSync(filePath, JSON.stringify(data, null, 2), { encoding: 'utf-8' });
        return data;
    }

    loadState() {
        const filePath = this.getFilePath('index.json');
        return getFileJson(filePath);
    }

    getAll(...args: any) {
        return this.state;
    }

    saveAll(data: any) {
        const filePath = this.getFilePath('index.json');
        fs.writeFileSync(filePath, JSON.stringify(data, null, 2), { encoding: 'utf-8' });
        return data;
    }

    transformListResponse(data: TDto[]): TStateDto {
        return data as TStateDto;
    }

    transformSingleResponse(data: TDto): TDto {
        return data as any;
    }

    get fields() {
        const attributes = this.schema?.attributes;
        if (attributes) {
            return Object.keys(attributes).reduce((fields: any[], key: string) => {
                const field = attributes[key];
                return [...fields, { ...field, name: key }];
            }, []);
        }
        return [];
    }
}

import React from 'react';
import nodemailer from 'nodemailer';
import fetch from 'node-fetch';
import { ContactDto } from '../../dtos/ContactDto';
import { RsvpDto } from '../../dtos/RsvpDto';
import { services } from '../collections/services';
import { renderToString } from 'react-dom/server';
import { EmailTemplate } from './EmailTemplate';
import { isDev } from '../../helpers/env';

const EMAIL_ADDRESS = 'henrylatour@gmail.com';
const EMAIL_APP_PASSWORD = 'jiclviyszxwbxjaz';
const SERVER_KEY = '6LeNgLUZAAAAAIhrzmJS3dHrWk4JvROmC3YcC_Hv';

const SEND_EMAILS_TO = !isDev() ? [EMAIL_ADDRESS, 'cody.suher@gmail.com', 'rsuher@nyc.rr.com'] : [EMAIL_ADDRESS];

const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
        user: EMAIL_ADDRESS,
        pass: EMAIL_APP_PASSWORD,
    },
});
// verify connection configuration
transporter.verify(function (error, success) {
    if (error) {
        console.log(error);
    } else {
        console.log('Server is ready to take our messages');
    }
});

export const verifyToken = (req, res) => {
    const { token } = req.body;
    const url = `https://www.google.com/recaptcha/api/siteverify?secret=${SERVER_KEY}&response=${token}`;
    fetch(url, { method: 'POST' })
        .then((res) => res.json())
        .then((google_response) =>
            res.send({
                success: true,
                google_response,
            })
        )
        .catch((error) => {
            res.send({
                success: false,
                error,
            });
        });
};

type EmailProps = ContactDto & { rsvp: RsvpDto };

const buildEmail = (props: EmailProps) => {
    const meals = services.meals.getAll();
    const events = services.events.getAll();

    return renderToString(<EmailTemplate contact={props} meals={meals} events={events} showAdminButton={false} />);
};

const getEmailAddresses = (emailAddress: string) => {
    return [emailAddress, ...SEND_EMAILS_TO].filter(Boolean).join(',');
};

const sendEmail = (payload: EmailProps) => {
    const { fullName, emailAddress, addressHeading } = payload;
    const mailOptions = {
        from: EMAIL_ADDRESS,
        to: getEmailAddresses(emailAddress),
        subject: `Wedding RSVP for ${addressHeading || fullName}`,
        html: buildEmail(payload),
    };
    return new Promise((resolve, reject) => {
        transporter.sendMail(mailOptions, function (error, info) {
            if (error) {
                reject(error);
            } else {
                resolve('Message Sent Successfully');
            }
        });
    });
};

export default sendEmail;

import React, { useMemo } from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Box, Drawer } from '@mui/material';
import { CALENDER_ITEMS } from './constants';
import { useIsMobile } from '../../hooks/useIsMobile';

interface CalendarDrawerProps {
    open?: boolean;
    onClose: (e?: any) => void;
    anchor?: string;
}

export function CalendarDrawer({ open, onClose, anchor }: CalendarDrawerProps) {
    const isMobile = useIsMobile();

    const anchorPos: any = useMemo(() => {
        if (isMobile) {
            return 'bottom';
        }
        return anchor || 'right';
    }, [anchor, isMobile]);

    return (
        <Drawer anchor={anchorPos} onClose={onClose} open={open}>
            <MenuList component="div">
                {CALENDER_ITEMS.map((item) => {
                    const { href, label, key, Icon } = item;
                    return (
                        <MenuItem
                            sx={{ justifyContent: 'center', alignItems: 'center' }}
                            component="a"
                            target="_blank"
                            rel="noreferrer noopener"
                            href={href}
                            key={key}
                        >
                            <Box display="flex" gap={2} alignItems="center">
                                <Icon />
                                <span>{label}</span>
                            </Box>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Drawer>
    );
}

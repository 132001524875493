export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SET_SITE_CONFIG = 'SET_SITE_CONFIG';

export enum BOOK_ACTIONS {
    SET_BOOKS = 'SET_BOOKS',
    REMOVE_BOOKS = 'REMOVE_BOOKS',
}

export enum PAGE_ACTIONS {
    SET_PAGES = 'SET_PAGES',
    SET_PAGE = 'SET_PAGE',
    SET_SINGLE_PAGE = 'SET_SINGLE_PAGE',
    SET_PAGE_TYPE = 'SET_PAGE_TYPE',
}

export enum FEATURE_ACTIONS {
    SET_FEATURES = 'SET_FEATURES',
    SET_FEATURE = 'SET_FEATURE',
}

export enum ENV_ACTIONS {
    SET_ENV_VARIABLE = 'SET_ENV_VARIABLE',
}

export enum MENU_ACTIONS {
    SET_MENU = 'SET_MENU',
    SET_MENUS = 'SET_MENUS',
}

export enum USER_ACTIONS {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    REGISTER = 'REGISTER',
    SET_USER = 'SET_USER',
}

export enum GLOBAL_USER_ACTIONS {
    SET_USERS = 'SET_USERS',
}

export enum FETCH_STATUS_ACTIONS {
    SET_STATUS = 'SET_STATUS',
}

export enum ATTACHMENT_ACTIONS {
    ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
    SET_ATTACHMENTS = 'SET_ATTACHMENTS',
    SET_ATTACHMENT = 'SET_ATTACHMENT',
    DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
}

export enum ACTIVITY_ACTIONS {
    GET_ALL = 'GET_ALL',
    ADD_ACTIVITY = 'ADD_ACTIVITY',
    SET_ACTIVITY = 'SET_ACTIVITY',
    DELETE_ACTIVITY = 'DELETE_ACTIVITY',
}

export enum GUEST_ACTIONS {
    GET_ALL = 'GET_ALL_GUESTS',
    ADD_GUEST = 'ADD_GUEST',
    SET_GUEST = 'SET_GUEST',
    DELETE_GUEST = 'DELETE_GUEST',
    SET_GUESTS_FILTERS = 'SET_GUESTS_FILTERS',
    SET_GUESTS_PAGINATION = 'SET_GUESTS_PAGINATION',
    SET_GUESTS_SORT = 'SET_GUESTS_SORT',
    SET_GUESTS_COLUMNS = 'SET_GUESTS_COLUMNS',
    SET_GUESTS_FILTER = 'SET_GUESTS_FILTER',
    SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
}

export enum ROUTER_ACTIONS {
    SET_ROUTER_LOCATION = 'SET_ROUTER_LOCATION',
}

export enum HOTEL_ACTIONS {
    GET_ALL_HOTELS = 'GET_ALL_HOTELS',
    ADD_HOTEL = 'ADD_HOTEL',
    SET_HOTEL = 'SET_HOTEL',
    DELETE_HOTEL = 'DELETE_HOTEL',
}


export enum HOUSE_ACTIONS {
    GET_ALL_HOUSES = 'GET_ALL_HOUSES',
    ADD_HOUSE = 'ADD_HOUSE',
    SET_HOUSE = 'SET_HOUSE',
    DELETE_HOUSE = 'DELETE_HOUSE',
}

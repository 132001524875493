import { GLOBAL_USER_ACTIONS } from "store/actionTypes";

export default function usersReducer(state = [], action) {
  switch (action.type) {
    case GLOBAL_USER_ACTIONS.SET_USERS: {
      if(Array.isArray(action.payload)){
        return action.payload;
      }
      return state;
    }
    default:
      return state;
  }
}

import React, { createContext, useContext, ReactNode } from 'react';
import { isDev } from 'helpers/env';
import io from 'socket.io-client';

const ioAddress = !isDev() ? 'wss://codyandhenry.com' : 'ws://localhost:6969';

export const webSocket = io(ioAddress, { autoConnect: false });

export const SocketContext = createContext(webSocket);

export function useSocketManager() {
    return useContext(SocketContext);
}

interface SocketProviderProps {
    children: ReactNode | ReactNode[];
}

export function SocketProvider({ children }: SocketProviderProps) {
    return <SocketContext.Provider value={webSocket}>{children}</SocketContext.Provider>;
}

import React, { ReactNode, useCallback } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { CtaButtonList } from 'components/CtaButtonList/CtaButtonList';
import { BannerDto } from 'dtos/components/BannerDto';
import { FETCH_STATUS } from 'types/types';
import { renderLabel } from 'utils/renderLabel';
import SiteMeta from 'components/SiteMeta/SiteMeta';
import { Image } from 'components/Image/Image';
import styles from './ReactPageHeader.module.scss';
import { PRIMARY_COLOR } from 'theme';

interface ReactPageHeaderProps {
    header?: BannerDto;
    status: FETCH_STATUS;
    children?: ReactNode | ReactNode[];
}

export function ReactPageHeader({ header = {} as BannerDto, status, children }: ReactPageHeaderProps) {
    const renderContent = useCallback(() => {
        if (header === null) {
            return null;
        }
        const { heading, content, ctas = [], headerBg, xs } = header;
        const sx = xs || {};
        switch (status) {
            case FETCH_STATUS.LOADING: {
                return (
                    <>
                        <Container maxWidth="lg">
                            <SiteMeta title={renderLabel('LOADING')} />
                            <CircularProgress size={100} sx={{ color: '#fff' }} />
                        </Container>
                    </>
                );
            }

            case FETCH_STATUS.ERROR: {
                return (
                    <Container maxWidth="xl">
                        <SiteMeta
                            title={renderLabel('404_NOT_FOUND')}
                            description={renderLabel('CONTENT_NOT_FOUND_MESSAGE')}
                        />
                        <Typography color="inherit" sx={{ fontSize: '1.75rem', fontWeight: 'bold' }} variant="h1">
                            {renderLabel('404_NOT_FOUND')}
                        </Typography>
                        <Typography color="inherit">{renderLabel('CONTENT_NOT_FOUND_MESSAGE')}</Typography>
                    </Container>
                );
            }

            case FETCH_STATUS.COMPLETE: {
                return (
                    <Container maxWidth="lg">
                        <SiteMeta title={heading} description={content} image={headerBg} />
                        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                        {content && <p className={styles.content} dangerouslySetInnerHTML={{ __html: content }}></p>}
                        <CtaButtonList ctas={ctas} />
                        {children}
                    </Container>
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, header, status]);

    if (!header) {
        return null;
    }

    const { heading, content, ctas = [], headerBg, xs } = header;
    const sx = xs || {};

    return (
        <Box
            sx={{
                backgroundColor: PRIMARY_COLOR,
                color: '#fff',
                pt: 15,
                pb: 15,
                ...sx,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
        >
            {headerBg && (
                <Box position="absolute" top={0} bottom={0} right={0} left={0} zIndex={0} overflow="hidden">
                    <Image useOriginal {...headerBg} className="headerTop" />
                </Box>
            )}

            <Box position="relative" zIndex={1} textAlign="center">
                {renderContent()}
            </Box>
        </Box>
    );
}

import { HOTEL_ACTIONS } from 'store/actionTypes';
import { AppThunk } from 'types/types';
import { HousingItem } from '../widgets/hotels/types/types';
import { getFetcher } from 'store/actions/fetcher';
import { MediaDto } from 'dtos/MediaDto';
import { Attachments } from 'store/actions/attachments';

interface GetResponse {
    data: HousingItem;
    attachments: MediaDto[];
}

interface GetAllResponse {
    data: HousingItem[];
    attachments: MediaDto[];
}

class LodgingApi {
    async _getAll(type: HousingItem['type']): Promise<GetAllResponse> {
        const isHotels = type !== 'HOUSE';
        const comparator = !isHotels ? '$eq' : '$ne';
        return getFetcher().get('/api/houses', {
            params: {
                type: isHotels ? 'HOTEL' : 'HOUSE',
                filters: {
                    type: {
                        [comparator]: 'HOUSE',
                    },
                },
            },
        });
    }
    async _get(slug: string): Promise<GetResponse> {
        return getFetcher().get(`/api/houses/${slug}`);
    }
    async _save(data: HousingItem): Promise<HousingItem> {
        return getFetcher().post(`/api/houses/save`, { data });
    }
    async _delete(id: number): Promise<HousingItem> {
        return getFetcher().delete(`/api/houses/${id}`);
    }

    getAll(type: HousingItem['type']): AppThunk<Promise<HousingItem[]>> {
        return async (dispatch) => {
            const { data, attachments } = await this._getAll(type);
            dispatch(Attachments.ADD(attachments));
            dispatch({
                type: HOTEL_ACTIONS.GET_ALL_HOTELS,
                payload: data.sort(
                    (a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
                ),
            });

            return data;
        };
    }

    get(slug: string): AppThunk<Promise<HousingItem>> {
        return async (dispatch) => {
            const { data, attachments } = await this._get(slug);
            dispatch(Attachments.ADD(attachments));
            dispatch({ type: HOTEL_ACTIONS.SET_HOTEL, payload: data });
            return data;
        };
    }

    update(payload: HousingItem): AppThunk<void> {
        return (dispatch) => {
            dispatch({
                type: HOTEL_ACTIONS.SET_HOTEL,
                payload,
            });
        };
    }

    save(data: HousingItem): AppThunk<Promise<HousingItem>> {
        return async (dispatch) => {
            const { id } = await this._save(data);
            dispatch({ type: HOTEL_ACTIONS.SET_HOTEL, payload: { ...data, id } });
            return { ...data, id };
        };
    }

    remove(id: number): AppThunk<Promise<void>> {
        return async (dispatch) => {
            await this._delete(id);
            dispatch({ type: HOTEL_ACTIONS.DELETE_HOTEL, payload: id });
        };
    }
}

export const LodgingAPI = new LodgingApi();

import { useEffect, useState } from 'react';
import canUseDOM from '../helpers/canUseDom';

export function useIsClient() {
    const [isClient, setIsClient] = useState<boolean>(() => canUseDOM());

    useEffect(() => {
        setIsClient(canUseDOM());
    }, []);

    return isClient;
}

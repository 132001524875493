export const debounce = (callback: Function, wait: number) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, wait);
    };
};

export const debouncePromise = (callback: Function, wait: number) => {
    let timeoutId = null;
    return (...args) => {
        return new Promise((resolve, reject) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(async () => {
                try {
                    const data = await callback.apply(null, args);
                    resolve(data);
                } catch (err) {
                    reject(err);
                }
            }, wait);
        });
    };
};

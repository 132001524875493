import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { createSelector } from 'reselect';
import { getState } from 'store/selectors/state';
import { HouseDto } from 'dtos/HouseDto';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FETCH_STATUS } from 'types/types';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LodgingAPI } from 'api/LodgingAPI';
import { HousingItem } from 'widgets/hotels/types/types';
import { HOTEL_ACTIONS } from 'store/actionTypes';
import { webSocket } from 'providers/SocketProvider/SocketProvider';

const getLodging = createSelector(getState, (state) => state.hotels || []);

const getLodgingItem = (slug: string) =>
    createSelector(getLodging, (items) => {
        return items.find((i) => i.slug === slug);
    });

interface RouteParams {
    slug: string;
}

export function useLodgingItem() {
    const { slug } = useParams<RouteParams>();
    const item = useSelector(getLodgingItem(slug));
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState<FETCH_STATUS>();
    const timeoutRef = useRef<NodeJS.Timeout>(null);
    const shouldSave = useRef<boolean>(false);

    useEffect(() => {
        webSocket.connect();
        return () => {
            webSocket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!shouldSave.current) {
            return;
        }
        timeoutRef.current = setTimeout(() => {
            webSocket.emit('SAVE_HOUSE', item);
            shouldSave.current = false;
        }, 2000);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [item, dispatch]);

    const setItem = useCallback(
        (payload: HousingItem) => {
            dispatch({
                type: HOTEL_ACTIONS.SET_HOTEL,
                payload,
            });
            shouldSave.current = true;
        },
        [dispatch]
    );

    useEffect(() => {
        setStatus(FETCH_STATUS.LOADING);
        dispatch(LodgingAPI.get(slug))
            .then(() => {
                setStatus(FETCH_STATUS.COMPLETE);
            })
            .catch((err) => {
                setStatus(FETCH_STATUS.ERROR);
            });
    }, [slug, dispatch]);

    return { item, setItem, status };
}

import { ENV_ACTIONS } from "store/actionTypes";

export default function envReducer(state = {}, action) {
    switch (action.type) {
        case ENV_ACTIONS.SET_ENV_VARIABLE:
            {
                const { key, value } = action.payload;
                if (key) {
                    return {
                        ...state,
                        [key]: value
                    }
                }
                return state;
            }
        default:
            return state;
    }
}

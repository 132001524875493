import loadable from "@loadable/component";

export const LazyLoginFormModal = loadable(
  () =>
    import(
      /* webpackChunkName: "LoginFormModal" */
      "./LoginFormModal"
    )
);

export default LazyLoginFormModal;

import { Box, BoxProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import styles from './CustomCard.module.scss';
import clsx from 'clsx';

interface CustomCardProps extends Omit<BoxProps, 'title'> {
    title?: ReactNode | ReactNode[];
    outlined?: boolean;
}

export const CustomCard: FC<CustomCardProps> = ({ className, title, children, outlined = true, ...boxProps }) => {
    return (
        <Box {...boxProps} className={clsx(styles.card, { [styles.outlined]: true }, className)}>
            {title && (
                <Box className={styles.heading}>
                    <Box component="h1" className={styles.heading1}>
                        {title}
                    </Box>
                </Box>
            )}
            <Box className={styles.body}>{children}</Box>
        </Box>
    );
};

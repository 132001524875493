import { createContext, useContext } from 'react';

interface RouteDataContextInterface {
    [key: string]: any;
}

export const RouteDataContext = createContext<RouteDataContextInterface>({});

export function useRouteData() {
    const context = useContext(RouteDataContext);

    if (!context) {
        throw new Error('Route data context provider never rendered');
    }

    return context;
}

import canUseDOM from 'helpers/canUseDom';
import { CookieContext } from 'providers/ServerCookieProvider';
import { createCookiesObject } from 'providers/utils';
import { useContext, useState, useEffect, useCallback } from 'react';

export function setCookie(name: string, value: string, days: number = 30) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string, cookie: string = '') {
    var nameEQ = name + '=';
    var ca = cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        // eslint-disable-next-line eqeqeq
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        // eslint-disable-next-line eqeqeq
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function getCookieNumber(...args){
    const value = parseInt(getCookie(args[0], args[1]), 10);
    return !isNaN(value) ? value : null;
}

export function removeCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

interface CookieOptions {
    numDays?: number;
}

export function useCookie(key: string, defaultValue?: any, { numDays = 15 }: CookieOptions = {}) {
    let cookieStr = useContext(CookieContext);
    if (canUseDOM()) {
        cookieStr = document.cookie;
    }

    const [value, setValue] = useState(() => {
        const val = getCookie(key, cookieStr);
        let currentValue;
        try {
            currentValue = JSON.parse(val || String(defaultValue));
        } catch (error) {
            currentValue = defaultValue || val;
        }
        return currentValue;
    });

    const setCookieValue = useCallback(
        (newValue: any) => {
            setCookie(key, JSON.stringify(newValue));
            setValue(newValue);
        },
        [key]
    );

    const deleteCookie = useCallback(() => {
        removeCookie(key);
    }, [key]);

    useEffect(() => {
        // setCookie(key, value, numDays);
    }, [key, value, numDays]);

    return [value, setCookieValue, deleteCookie];
}

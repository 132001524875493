import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useStickHeaderListener } from 'hooks/useStickyHeader';
import { useSelector } from 'react-redux';
import { getIsMobileEnv } from './selectors';

const sharedStyles = {
    position: 'sticky',
    transition: 'top ease-in-out .25s',
    borderBottom: '1px solid rgba(0, 0, 0, .12)',
    fontSize: '2rem',
    zIndex: 9,
};

export const stickyStyles = {
    ...sharedStyles,
    top: 0,
    backgroundColor: 'var(--paper-bg)',
    color: '#000',
};

function setWrapperTopPosition(wrapper: HTMLDivElement | null, isSticky: boolean) {
    if (!wrapper) {
        return;
    }

    wrapper.style.top = !isSticky ? `-${wrapper.offsetHeight}px` : '0px';
}

const stickyPaths = ['tools/labels', 'login'];

const isInitialSticky = (location: Location) => {
    return stickyPaths.some((p) => location.pathname.indexOf(p) > -1);
};

const useStickyMultiplier = () => {
    const isMobile = useSelector(getIsMobileEnv);
    return isMobile ? 0 : 2;
};

export function useAppBarStyles(forceSticky = false) {
    const ref = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const multiplier = useStickyMultiplier();

    const [isSticky, setIsSticky] = useState(isInitialSticky(location as any));

    useStickHeaderListener(setIsSticky);

    useEffect(() => {
        const wrapper = ref.current;
        if (!wrapper) {
            return;
        }
        const handleScroll = () => {
            if (forceSticky || isInitialSticky(location as any)) {
                return;
            }
            setIsSticky(window.scrollY > wrapper.offsetHeight * multiplier);
        };

        window.addEventListener('scroll', handleScroll, false);
        return () => {
            window.removeEventListener('scroll', handleScroll, false);
        };
    }, [forceSticky, multiplier, location]);

    useEffect(() => {
        setWrapperTopPosition(ref.current, isSticky || forceSticky);
    }, [isSticky, forceSticky]);

    const appBarHeight = useMemo(() => {
        if (ref.current) {
            return ref.current.offsetHeight;
        }
        return 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSticky, ref]);

    return {
        ref,
        isSticky,
        setIsSticky,
        appBarHeight,
        // appBarStyles: isSticky ? stickyStyles : defaultStyles,
        appBarStyles: stickyStyles,
    };
}

import { useSelector } from "react-redux";
import { UserDto } from "dtos/UserDto";
import { getState } from "helpers/getState";
import { createSelector } from "reselect";

const getUsers = createSelector(getState, (state): UserDto[] => state.users);

const getMenus = createSelector(getState, (state) => state.menus);

export function useFooterSelections() {
  const users = useSelector(getUsers);
  const menus = useSelector(getMenus);

  return {
    users,
    menus,
  };
}

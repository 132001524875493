import { AttachmentsAPI } from 'api/AttachmentsAPI';
import { ListPayload } from 'dtos/ListPayload';
import { MediaDto } from 'dtos/MediaDto';
import { getFetcher } from 'store/actions/fetcher';

export enum API_COLLECTIONS {
    GUESTS = 'guests',
    ACTIVITIES = 'activities',
    CONTACTS = 'contacts',
    GALLERIES = 'galleries',
    FEATURES = 'features',
    ROOMS = 'rooms',
}

interface AnyDTO {
    id?: number;
    attachments?: MediaDto[];
}

export type GetParams<TDto> = Partial<Omit<ListPayload<TDto>, 'data'>>;

const createFormData = (file: File) => {
    const formData = new FormData();
    formData.set('files', file);
    return formData;
};

async function uploadAllFiles(fileList: FileList) {
    const files = Array.from(fileList);

    const promises = files.map(async (file) => {
        const [upload] = await AttachmentsAPI.upload([file] as unknown as FileList);
        return upload;
    });

    const allFiles = await Promise.all(promises);

    return allFiles.filter(Boolean);
}

export function createCollectionApi<TDto extends AnyDTO>(collection: API_COLLECTIONS, baseUrl = '', headers: any = {}) {
    const API = {
        getAll(params: GetParams<TDto> = {}): Promise<ListPayload<TDto[]>> {
            return getFetcher().get(`${baseUrl}/api/${collection}`, {
                headers,
                params,
            });
        },
        get(id: number, params: GetParams<TDto> = { populate: ['deep,2'] }): Promise<ListPayload<TDto>> {
            return getFetcher().get(`${baseUrl}/api/${collection}/${id}`, {
                headers,
                params,
            });
        },
        async upload(data: TDto, files: FileList): Promise<ListPayload<TDto>> {
            const uploadedFiles = await uploadAllFiles(files);
            const { attachments = [] } = data;

            const newIds = uploadedFiles.map((f) => f.id);

            await getFetcher().put(
                `/api/${collection}/${data.id}`,
                {
                    data: {
                        ...data,
                        attachments: [...attachments, ...newIds],
                    },
                },
                {
                    params: {
                        populate: ['deep,2'],
                    },
                }
            );

            return API.get(data.id);
        },
        update(data: TDto): Promise<TDto> {
            return getFetcher()
                .put(`/api/${collection}/${data.id}`, { data })
                .then((result) => result.data);
        },
        create(data: TDto): Promise<TDto> {
            return getFetcher()
                .post(`/api/${collection}`, { data })
                .then(({ data: resultData }) => {
                    return { ...data, id: resultData.id };
                });
        },
        async save(data: TDto): Promise<TDto> {
            const saveMethod = data.id ? API.update : API.create;
            const { id } = await saveMethod(data);
            const results = await API.get(id);
            return results.data;
        },
        delete(id: number): Promise<TDto> {
            return getFetcher().delete(`/api/${collection}/${id}`);
        },
    };

    return API;
}

import React, { ReactNode } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FETCH_STATUS } from 'types/types';

export const renderLoadingIcon = (status: FETCH_STATUS, defaultIcon?: ReactNode) => {
    switch (status) {
        case FETCH_STATUS.LOADING: {
            return <CircularProgress size={16} sx={{ color: 'inherit' }} />;
        }
        case FETCH_STATUS.COMPLETE: {
            return <CheckIcon />;
        }
        case FETCH_STATUS.ERROR: {
            return <ErrorOutlineIcon />;
        }
        default:
            return defaultIcon;
    }
};

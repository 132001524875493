import React, { ForwardedRef, Fragment, ReactNode, useCallback, useEffect, useRef, FormEvent } from 'react';
import { Form, Formik, FormikConfig, FormikProps, useFormikContext } from 'formik';
import { deepEquals } from '@react-page/editor';

export interface FormWrapperProps<TValues = any> extends Omit<FormikConfig<TValues>, 'initialValues' | 'onSubmit'> {
    initialValues?: Partial<TValues>;
    autoComplete?: 'on' | 'off';
    onChange?: (values: TValues) => void;
    onSubmit?: FormikConfig<TValues>['onSubmit'];
    debounceTime?: number;
    children: ReactNode | ReactNode[];
    enableReinitialize?: boolean;
    withForm?: boolean;
    validateOnMount?: boolean;
}

export function PluginValuesUpdater<TValue>({
    initialValues,
    onChange,
    debounceTime = 250,
}: Partial<FormWrapperProps<TValue>>) {
    const { values } = useFormikContext<TValue>();
    const runOnce = useRef(false);
    const prevValues = useRef(values);
    const onChangeRef = useRef(onChange);
    const timeoutRef = useRef<NodeJS.Timeout>(null);

    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    useEffect(() => {
        if (!runOnce.current) {
            runOnce.current = true;
            return;
        }

        timeoutRef.current = setTimeout(() => {
            if (!deepEquals(values, prevValues.current)) {
                onChangeRef.current(values);
                prevValues.current = values;
            }
        }, debounceTime);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [values]);

    return null;
}

const createSubmitHandler = (submitForm: () => Promise<void>) => (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return submitForm();
};

function FormWrappeMarkup<TValues = any>(
    {
        initialValues = {},
        onChange,
        onSubmit,
        children,
        withForm = true,
        autoComplete = 'on',
        debounceTime = 250,
        enableReinitialize,
        validateOnMount,
    }: FormWrapperProps<TValues>,
    ref: ForwardedRef<HTMLFormElement>
) {
    const renderMarkup = useCallback(() => {
        if (onChange) {
            return (
                <Fragment>
                    {children}
                    <PluginValuesUpdater
                        debounceTime={debounceTime}
                        initialValues={initialValues}
                        onChange={onChange}
                    />
                </Fragment>
            );
        }

        if (!withForm) {
            return children;
        }

        return ({ submitForm }: FormikProps<TValues>) => {
            return (
                <Form autoComplete={autoComplete} ref={ref} onSubmit={createSubmitHandler(submitForm)}>
                    {children}
                </Form>
            );
        };
    }, [children, initialValues, debounceTime, autoComplete, onChange, withForm, ref]);

    return (
        <Formik<TValues>
            enableReinitialize={enableReinitialize}
            initialValues={initialValues as TValues}
            onSubmit={onSubmit}
            validateOnMount={validateOnMount}
        >
            {renderMarkup()}
        </Formik>
    );
}

export const FormWrapper = React.forwardRef(FormWrappeMarkup) as <TValues = any>(
    props: FormWrapperProps<TValues> & { ref?: React.Ref<HTMLFormElement> }
) => ReturnType<typeof FormWrappeMarkup>;

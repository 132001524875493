export function isProduction() {
    return process.env.NODE_ENV === 'production';
}

export function isDev() {
    return process.env.NODE_ENV === 'development';
}

export function isProdCache() {
    return isProduction() && process.env.REACT_APP_PROD_CACHE === 'true';
}

export function isProdProxy() {
    return !!process.env.PROXY_PATH;
}

export function getProxyUrl() {
    return isProduction() ? 'http://192.155.89.193:1337' : 'http://localhost:1337';
}

import React, { useEffect, useRef, Suspense, lazy, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppRouter from 'components/AppRouter/AppRouter';
import { AppRoutes } from 'components/AppRouter/AppRoutes';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { useInitializeUser } from 'hooks/useInitializeUser';
import { ThemeProvider } from '@mui/material';
import getTheme from 'theme';
import { AppFooter } from 'components/AppFooter/AppFooter';
import LazyLoginFormModal from 'components/LoginForm';
import { LazyMobileAppFooter } from 'components/MobileAppFooter';
import { LazyApiMessages } from 'components/ApiMessages/LazyApiMessages';
import 'App.scss';
import { useIsMobile } from './hooks/useIsMobile';
import { useIsClient } from './hooks/useIsClient';
import EventTracker from './providers/TrackingProvider';

const RsvpFormDialog = lazy(() => import('components/RsvpForm/RsvpFormDialog'));

function ScrollFixer() {
    const location = useLocation();
    const isMobile = useIsMobile();

    useEffect(() => {
        const topPos = isMobile ? 2 : 0;
        setTimeout(() => {
            window.scrollTo(0, topPos);
        }, 10);
    }, [location.pathname]);

    return null;
}

const App: React.FC<{ location?: string; history?: any }> = ({ location, history }) => {
    useInitializeUser();
    const isClient = useIsClient();

    return (
        <ThemeProvider theme={getTheme('light')}>
            <AppRouter history={history} location={location}>
                {isClient && <ScrollFixer />}
                <AppHeader />
                <AppRoutes />
                <AppFooter />
                {isClient && (
                    <Suspense fallback={null}>
                        <RsvpFormDialog />
                    </Suspense>
                )}
                <LazyLoginFormModal />
                <LazyMobileAppFooter />
                <LazyApiMessages />
                {isClient && <EventTracker />}
            </AppRouter>
        </ThemeProvider>
    );
};

export default App;

import React, { useEffect, useMemo, useState } from 'react';
import { GuestsProvider } from '../ContactsView/ContactsList/GuestsProvider';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ContactDto, GuestDto } from '../../dtos/ContactDto';
import { getFetcher } from '../../store/actions/fetcher';
import { Container, MenuItem } from '@mui/material';
import { FormWrapper } from '../../forms/FormWrapper/FormWrapper';
import { PluginEditorForm } from '../../providers/PluginEditorForm';
import { GuestsField } from '../../fields/GuestsField/GuestsField';
import { mapInvitesToGuest } from './rdUtils';
import { SendEmailButton } from './SendEmailButton';

const columns = [{ name: 'guestInvites', component: GuestsField, hideValues: true }];

export const RehearsalDinner: React.FC = () => {
    const [guestInvites, setGuestInvites] = useLocalStorage('rd', []);
    const [guests, setGuests] = useState<GuestDto[]>([]);
    const [contacts, setContacts] = useState<ContactDto[]>([]);

    useEffect(() => {
        getFetcher().get('/api/guests').then(setGuests);
        getFetcher().get('/api/contacts').then(setContacts);
    }, []);

    const handleChange = (values: any) => {
        setGuestInvites(values.guestInvites);
    };

    const mappedGuests = useMemo(() => {
        return mapInvitesToGuest(guestInvites, guests, contacts);
    }, [guestInvites, guests, contacts]);

    console.log(mappedGuests);

    return (
        <Container maxWidth="md">
            <GuestsProvider>
                <FormWrapper<any> onChange={handleChange} initialValues={{ guestInvites }}>
                    <PluginEditorForm columns={columns} />
                </FormWrapper>
            </GuestsProvider>
            {mappedGuests.map((c) => {
                const { fullName, id, allEmails } = c;
                return (
                    <MenuItem key={id}>
                        {fullName} - {allEmails.join(', ')}
                        <SendEmailButton guest={c} />
                    </MenuItem>
                );
            })}
        </Container>
    );
};

import { Button, ButtonProps } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { renderLoadingIcon } from '../components/renderLoadingIcon';

export function FormSubmitButton({
    children,
    type = 'submit',
    variant = 'contained',
    color = 'primary',
    size = 'large',
    disabledSubmitThreshold = 0,
    ...buttonProps
}: ButtonProps & { disabledSubmitThreshold?: number }) {
    const { status, errors, submitCount } = useFormikContext();

    const hasErrors = useMemo(() => {
        return Object.keys(errors).length > 0;
    }, [errors]);

    return (
        <Button
            {...buttonProps}
            type={type}
            disabled={hasErrors && submitCount > disabledSubmitThreshold}
            startIcon={renderLoadingIcon(status)}
            variant={variant}
            color={color}
            size={size}
        >
            {children}
        </Button>
    );
}

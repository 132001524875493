import React, { HTMLProps } from 'react';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { getImageProps, SizeTypes } from 'helpers/getImageProps';
import { renderLabel } from 'utils/renderLabel';
import { MediaDto } from 'dtos/MediaDto';
import styles from './Image.module.scss';
import clsx from 'clsx';

export interface ImageProps {
    image: MediaDto | undefined;
    size?: SizeTypes;
    className?: string;
    style?: HTMLProps<HTMLImageElement>['style'];
    rounded?: boolean;
    elevated?: boolean;
}

export function Image({ image, className, size = SizeTypes.DEFAULT, style = {}, rounded, elevated }: ImageProps) {
    if (!image) {
        return (
            <div className={styles.noImage}>
                <NoPhotographyIcon />
                <p>{renderLabel('NO_IMAGE_FOUND')}</p>
            </div>
        );
    }

    const imgProps = getImageProps(image, size);

    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
            {...(imgProps as any)}
            style={style}
            className={clsx(styles.image, {
                [styles.rounded]: rounded,
                [styles.elevated]: elevated,
            })}
            data-upload-id={image?.id}
        />
    );
}

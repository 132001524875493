import { UserDto } from 'dtos/UserDto';
import { getProxyUrl } from 'helpers/env';
import { getFetcher } from 'store/actions/fetcher';
import uuid4 from 'uuid4';
import { CollectionStore } from './CollectionStore';
import { API_COLLECTIONS, headers } from 'server/utils/createServerCollectionAPI';
import { getIsUserAdmin } from 'hooks/useLoggedInUser';

const getSessionExpirationDate = (numDays = 15) => {
    return Date.now() + numDays * 24 * 60 * 60 * 1000;
};

const isSessionExpired = (expiry: number) => {
    return Date.now() > expiry;
};

type LoginPayload = {
    identifier: string;
    password: string;
};

interface LoggedInUserDto {
    user: UserDto;
    jwt: string;
    expiry: number;
    sessionId: string;
}

const API = {
    getOne: (id: number) => {
        return getFetcher().get(`${getProxyUrl()}/api/users/${id}`, {
            headers,
            params: {
                populate: ['role'],
            },
        });
    },
    LOGIN: (body: LoginPayload) => {
        return getFetcher().post(`${getProxyUrl()}/api/auth/local?populate[0]=role`, body);
    },
};

export class SessionsStore extends CollectionStore<UserDto, LoggedInUserDto[]> {
    constructor() {
        super(API_COLLECTIONS.SESSIONS);
        this.state = [];
        this.API = API;
    }

    initialize() {
        try {
            this.state = this.loadState();
        } catch (err) {
            this.state = [];
            this.saveAll(this.state);
        }
    }

    refresh(item?: UserDto) {}

    storeSession(session: LoggedInUserDto) {
        this.state.push(session);
        this.saveAll(this.state);
    }

    clearAll() {
        this.state = [];
        this.saveAll(this.state);
    }

    async login(payload: LoginPayload): Promise<LoggedInUserDto> {
        try {
            const { user, jwt } = await this.API.LOGIN(payload).catch((err) => {
                console.log(err);
                return {};
            });
            const fullUser = await this.API.getOne(user.id);
            fullUser.isAdmin = getIsUserAdmin(fullUser);
            const loggedInUser = { user: fullUser, jwt, expiry: getSessionExpirationDate(), sessionId: uuid4() };
            this.storeSession(loggedInUser);
            return loggedInUser;
        } catch (err) {
            console.log(err.toString());
            throw new Error(err);
        }
    }

    logout(sessionId) {
        this.state = this.state.filter((session) => session.sessionId !== sessionId);
        this.saveAll(this.state);
    }

    getLoggedInUser(sessionId: string): UserDto | undefined {
        const session = this.state.find((session) => session.sessionId === sessionId);
        if (session && !isSessionExpired(session.expiry)) {
            return session.user;
        }
        return undefined;
    }
}

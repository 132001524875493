import React, { useCallback, useEffect, useMemo } from 'react';
import { WrappedFormField } from 'components/InputText/WrappedInputText';
import { InputComponentProps } from 'components/InputText/InputComponentProps';
import { ResponsiveFieldValue, SxItemProps } from './SxFieldItem';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';
import { InlineInput } from 'fields/InlineInput/InlineInput';

export const BREAKPOINTS: Array<keyof ResponsiveFieldValue> = ['xs', 'sm', 'md', 'lg', 'xl'];

const useComponentType = () => {
    const {
        values: { type },
    } = useFormikContext<SxItemProps>();
    return useMemo(() => {
        switch (type) {
            case 'string':
            case 'object':
                return undefined;
            default:
                return type;
        }
    }, [type]);
};

export function SxResponsiveField({ field, helpers, meta }: InputComponentProps<ResponsiveFieldValue>) {
    const type = useComponentType();
    const { value = {}, name } = field;
    const { setValue } = helpers;

    useEffect(() => {
        if (value !== undefined && typeof value !== 'object') {
            setValue({ xs: value as any });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFieldName = useCallback(
        (breakpoint: keyof ResponsiveFieldValue) => {
            return `${name}.${breakpoint}`;
        },
        [name]
    );

    if (typeof value !== 'object') {
        return null;
    }

    return (
        <Box display="flex" border="1px solid #ddd" flexDirection="column">
            {BREAKPOINTS.map((breakpoint) => {
                return (
                    <WrappedFormField
                        key={breakpoint}
                        component={InlineInput as any}
                        type={type}
                        name={getFieldName(breakpoint)}
                        label={breakpoint}
                    />
                );
            })}
        </Box>
    );
}

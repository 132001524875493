import { Box, Container, Paper } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import styles from './RsvpPageWrapper.module.scss';
import { useIsMobile } from '../../hooks/useIsMobile';

interface RsvpPageWrapperProps {
    className?: string;
    isOutlined?: boolean;
}

export const RsvpPageWrapper: React.FC<RsvpPageWrapperProps> = ({ className, isOutlined = true, children }) => {
    const isMobile = useIsMobile();
    const wrapperClassName = isMobile ? styles.wrapperMobile : styles.wrapper;
    return (
        <Box className={clsx(wrapperClassName, className)} py={2}>
            <Container
                elevation={0}
                variant={isOutlined ? 'outlined' : undefined}
                className={styles.container}
                component={Paper}
                maxWidth="sm"
            >
                <Box className={styles.content}>{children}</Box>
            </Container>
        </Box>
    );
};

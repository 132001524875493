export const menuState = {
    primary: {
        id: 1,
        title: 'Links',
        slug: 'primary',
        createdAt: '2023-02-26T20:52:48.852Z',
        updatedAt: '2023-07-07T05:17:26.435Z',
        items: [
            {
                id: 1,
                label: 'Home',
                href: '/',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 4,
                label: 'Our Story',
                href: '/our-story',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 3,
                label: 'Hotels',
                href: '/accomodations',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 18,
                label: 'Travel',
                href: '/travel',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 19,
                label: 'Things To Do',
                href: '/activities',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 15,
                label: 'FAQs',
                href: '/faqs',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 5,
                label: 'RSVP',
                href: '#rsvp',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
        ],
    },
    user: {
        id: 2,
        title: 'Forms',
        slug: 'user',
        createdAt: '2023-02-27T22:17:16.764Z',
        updatedAt: '2023-07-07T05:08:01.061Z',
        items: [
            {
                id: 8,
                label: 'Contact Info',
                href: '/address',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
            {
                id: 7,
                label: 'Login',
                href: '#login',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                className: null,
                fontSize: null,
                cId: null,
            },
        ],
    },
};

import React, { createContext, ReactNode } from 'react';

export const CookieContext = createContext<string | undefined>(undefined);

interface ServerCookieProviderProps {
    children: ReactNode | ReactNode[];
    cookies: string;
}

export function ServerCookieProvider({ cookies, children }: ServerCookieProviderProps) {
    return <CookieContext.Provider value={cookies}>{children}</CookieContext.Provider>;
}

import clsx from 'clsx';
import React, { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { InputComponentProps } from './InputComponentProps';
import styles from './InputText.module.scss';
import { IconButton } from '@mui/material';

interface InputTextProps {
    clearable: boolean;
    autoComplete?: string;
}

export const InputText = forwardRef(function InputText(
    {
        focused,
        autoComplete,
        field,
        meta,
        helpers,
        label,
        required,
        type,
        labelProps,
        autoFocus,
        className,
        endIcon,
        clearable,
        clearWithUndefined,
        size,
        showError,
    }: InputComponentProps<string> & InputTextProps,
    inputRef?: ForwardedRef<HTMLInputElement>
) {
    const { hasError, error } = meta;
    const { value } = field;
    const { setValue } = helpers;

    const labelTop = focused || !!value;

    const setRef = useCallback(
        (el: HTMLInputElement) => {
            if (typeof inputRef === 'function' && el) {
                inputRef(el);
            }
            if (autoFocus && el) {
                setTimeout(() => {
                    el.focus();
                }, 25);
            }
        },
        [inputRef, autoFocus]
    );

    const onClear = useCallback(() => {
        setValue(clearWithUndefined ? undefined : '');
    }, [setValue, clearWithUndefined]);

    const endIconMarkup = useMemo(() => {
        if (endIcon) {
            return endIcon;
        }
        if (value?.length && clearable) {
            return (
                <IconButton onClick={onClear}>
                    <CloseIcon />
                </IconButton>
            );
        }
        return null;
    }, [clearable, endIcon, value, onClear]);

    return (
        <>
            <div
                className={clsx(
                    'textInput',
                    styles.formField,
                    {
                        [styles.focused]: focused,
                        [styles.labelTop]: labelTop,
                        [styles.hasError]: hasError,
                        [styles.small]: size === 'small',
                    },
                    className
                )}
            >
                <label {...labelProps} className={styles.label}>
                    {label}
                </label>
                <input
                    ref={setRef}
                    {...field}
                    value={field.value || ''}
                    autoComplete={autoComplete}
                    aria-required={required}
                    type={type}
                    className={styles.input}
                />
                {endIconMarkup}
            </div>
            {showError && hasError && <small className="field-error-message">{error}</small>}
        </>
    );
});

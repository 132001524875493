import { MediaDto } from "dtos/MediaDto";
import { getFetcher } from "store/actions/fetcher";

function getAll(): Promise<MediaDto[]> {
    return getFetcher().get('/api/upload/files?sort[0]=updatedAt:DESC');
}

function deleteAttachment(id: number): Promise<MediaDto> {
    return getFetcher().delete(`/api/upload/files/${id}`);
}

function uploadAttachments(files: FileList): Promise<MediaDto[]> {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.set('files', files[i]);
    }
    return getFetcher().post('/api/upload', formData);
}

function saveAttachment(file: MediaDto): Promise<MediaDto> {
    const form = new FormData();
    const { caption, alternativeText, id } = file;
    form.append('fileInfo', JSON.stringify({ caption, alternativeText }));
    return getFetcher().post(`/api/upload`, form, { params: { id } });
}

export const AttachmentsAPI = {
    getAll,
    delete: deleteAttachment,
    upload: uploadAttachments,
    save: saveAttachment,
}
import { AttachmentsAPI } from "api/AttachmentsAPI";
import { MediaDto } from "dtos/MediaDto";
import { ATTACHMENT_ACTIONS } from "store/actionTypes";
import { AppThunk } from "types/types";

function ADD(attachments: MediaDto[]): AppThunk<void>{
    return (dispatch) => {
        if(Array.isArray(attachments) && attachments.length){
            dispatch({ 
                type: ATTACHMENT_ACTIONS.ADD_ATTACHMENTS,
                payload: attachments,
            });
        }
    }
}

function GET_ALL(): AppThunk<Promise<MediaDto[]>>{
    return async (dispatch) => {
        const attachments = await AttachmentsAPI.getAll();
        dispatch({
            type: ATTACHMENT_ACTIONS.SET_ATTACHMENTS,
            payload: attachments,
        });
        return attachments;
    }
}

function SAVE(file: MediaDto): AppThunk<Promise<MediaDto>> {
    return async (dispatch) => {
        const saved = await AttachmentsAPI.save(file);
        dispatch({
            type: ATTACHMENT_ACTIONS.SET_ATTACHMENT,
            payload: file,
        });
        return file;
    }
}

function UPLOAD(files: FileList): AppThunk<Promise<MediaDto[]>> {
    return async (dispatch) => {
        const attachments = await AttachmentsAPI.upload(files);
        dispatch(ADD(attachments));
        return attachments;
    }
}

function DELETE(id: number): AppThunk<Promise<MediaDto>>{
    return async (dispatch) => {
        const deleted = AttachmentsAPI.delete(id);
        dispatch({
            type: ATTACHMENT_ACTIONS.DELETE_ATTACHMENT,
            payload: id,
        });
        return deleted;
    }
}

export const Attachments = {
    ADD,
    GET_ALL,
    SAVE,
    UPLOAD,
    DELETE,
}
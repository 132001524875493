import { API_COLLECTIONS, createServerCollectionAPI } from '../utils/createServerCollectionAPI';
import { CollectionStore } from './CollectionStore';
import { ContactDto } from '../../dtos/ContactDto';
import { services } from './services';

export class ContactsStore extends CollectionStore<ContactDto> {
    constructor() {
        super(API_COLLECTIONS.CONTACTS, 'id', { url: '/api/store/contacts' });
    }

    getOne(key: string | number) {
        const contact = this.stateMap[key];
        if (!contact) {
            throw new Error('contact does not exist');
        }
        const guestsMap = services.guests.stateMap;
        const rsvps = services.rsvps.data;
        const { guests } = contact as any;

        return {
            ...contact,
            guests: guests
                ?.map((gId) => {
                    const guest = guestsMap[gId];
                    if (guest) {
                        return { ...guest, contact: undefined };
                    }
                    return false;
                })
                .filter(Boolean),
            rsvp: rsvps.find((r) => r.contactId === contact.id),
        };
    }
}

import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ContactDto, GuestDto } from '../../dtos/ContactDto';
import { FormWrapper } from '../../forms/FormWrapper/FormWrapper';
import { PluginEditorForm } from '../../providers/PluginEditorForm';
import { getFetcher } from '../../store/actions/fetcher';
import { RehearsalDinnerTemplate } from '../../server/services/RehearsalDinnerTemplate';

type Props = {
    guest: GuestDto & { contactGuests: GuestDto; allEmails: string[]; contact: ContactDto };
};

const fields = [{ name: 'fullName' }, { name: 'emailAddress' }];

export const SendEmailButton: React.FC<Props> = ({ guest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [emailPayload, setEmailPayload] = useState<any>({
        fullName: guest.contact?.addressHeading || guest.fullName,
        emailAddress: guest.allEmails.join(','),
    });

    const toggleOpen = () => setIsOpen(!isOpen);

    const sendEmail = () => {
        getFetcher()
            .post('/api/email/rd', emailPayload)
            .then(() => {
                setEmailSent(true);
                setIsOpen(false);
            })
            .catch((err) => {
                alert(err.toString());
            });
    };

    return (
        <div>
            <Button disabled={emailSent} variant="contained" onClick={toggleOpen}>
                Send email
            </Button>
            <Dialog open={isOpen} onClose={toggleOpen}>
                <DialogContent>
                    <FormWrapper<any> onChange={setEmailPayload} initialValues={emailPayload}>
                        <PluginEditorForm columns={fields} />
                    </FormWrapper>
                    <RehearsalDinnerTemplate fullName={emailPayload.fullName} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={sendEmail}>Send invite</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

import { DataTType } from '@react-page/editor';
import { Box } from '@mui/material';
import React from 'react';
import getTheme, { ERROR_COLOR, PRIMARY_COLOR } from 'theme';
import { InputComponentProps } from 'components/InputText/InputComponentProps';
import styles from './InlineFieldWrapper.module.scss';
import clsx from 'clsx';

const normalStyles = {
    fontSize: { xs: 16, sm: 14 },
    color: '#000',
};

const focusedStyles = {
    color: PRIMARY_COLOR,
    borderBottom: '1px solid',
    borderColor: PRIMARY_COLOR,
    boxShadow: `inset 0 0 0 2px ${PRIMARY_COLOR}`,
};

const errorStyles = {
    color: ERROR_COLOR,
    borderColor: ERROR_COLOR,
};

const focusedErrorStyles = {
    boxShadow: `inset 0 0 0 2px ${ERROR_COLOR}`,
};

export function withInputWrapper<TValue = any>(Component: React.FC<InputComponentProps<TValue>>) {
    class InlineFieldWrapper extends React.PureComponent<InputComponentProps<TValue>, any> {
        render() {
            const { props } = this;
            const {
                focused,
                meta: { hasError },
                flex,
            } = props;

            return (
                <Box
                    className={clsx(styles.inlineWrapper, { [styles.flex1]: flex })}
                    sx={[
                        normalStyles,
                        focused && focusedStyles,
                        hasError && errorStyles,
                        hasError && focused && focusedErrorStyles,
                    ]}
                >
                    <Component {...props} />
                </Box>
            );
        }
    }
    return InlineFieldWrapper;
}

import { ActivityDto } from 'dtos/ActivityDto';
import { MediaDto } from 'dtos/MediaDto';
import { ACTIVITY_ACTIONS } from 'store/actionTypes';
import { getFetcher } from 'store/actions/fetcher';
import { selectActivitiesApiParams } from 'store/selectors/activities';
import { selectAttachmentsMap } from 'store/selectors/attachments';
import { AppThunk } from 'types/types';

const buildImages = (attachmentsMap: ReturnType<typeof selectAttachmentsMap>, imageIds: number[] = []) => {
    const buildImage = (image: number | MediaDto) => (typeof image === 'object' ? image : attachmentsMap[image]);

    return imageIds.map(buildImage).filter(Boolean);
};

const ActivitiesAPI = {
    getAll(params: any = {}): Promise<ActivityDto[]> {
        return getFetcher().get('/api/activities', {
            params,
        });
    },
    update(activity: ActivityDto): Promise<ActivityDto> {
        return getFetcher()
            .put(`/api/activities/${activity.id}`, { data: activity })
            .then(({ data }) => {
                return data;
            });
    },
    create(activity: ActivityDto): Promise<ActivityDto> {
        return getFetcher()
            .post(`/api/activities`, { data: activity })
            .then(({ data }) => {
                return { ...activity, id: data.id };
            });
    },
    save(data: ActivityDto): Promise<ActivityDto> {
        const saveMethod = data.id ? ActivitiesAPI.update : ActivitiesAPI.create;
        return saveMethod(data);
    },

    delete(id: number): Promise<ActivityDto> {
        return getFetcher().delete(`/api/activities/${id}`);
    },
};

function GET_ALL(): AppThunk<Promise<ActivityDto[]>> {
    return async (dispatch, getState) => {
        const params = selectActivitiesApiParams(getState());
        const data = await ActivitiesAPI.getAll(params).catch(() => []);
        dispatch({
            type: ACTIVITY_ACTIONS.GET_ALL,
            payload: data,
        });
        return data;
    };
}

function SAVE(activity: ActivityDto): AppThunk<Promise<ActivityDto>> {
    return async (dispatch, getState) => {
        const attachments = selectAttachmentsMap(getState());
        const data = await ActivitiesAPI.save(activity);
        const type = activity.id ? ACTIVITY_ACTIONS.SET_ACTIVITY : ACTIVITY_ACTIONS.ADD_ACTIVITY;
        dispatch({
            type,
            payload: {
                ...activity,
                id: activity.id || data.id,
                images: buildImages(attachments, activity.images as any),
            },
        });
        return data;
    };
}

function DELETE(id: number): AppThunk<Promise<ActivityDto>> {
    return async (dispatch) => {
        const data = await ActivitiesAPI.delete(id);
        dispatch({
            type: ACTIVITY_ACTIONS.DELETE_ACTIVITY,
            payload: id,
        });
        return data;
    };
}

export const ActivityActions = {
    GET_ALL,
    SAVE,
    DELETE,
};

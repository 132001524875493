import React from 'react';
import loadable from '@loadable/component';
import { Container, Paper } from '@mui/material';
import { useStickyHeaderDispatch } from 'hooks/useStickyHeader';
import { renderLabel } from 'utils/renderLabel';
import styles from './LoginPage.module.scss';

// This dynamic import will not be processed server-side
export const LoginForm = loadable(
    () =>
        import(
            /* webpackChunkName: "LoginForm" */
            'components/LoginForm/LoginForm'
        )
);

export function LazyLoginPage() {
    useStickyHeaderDispatch();

    return (
        <div className={styles.page}>
            <Container sx={{ my: 5 }} maxWidth="sm">
                <Paper sx={{ py: 2, px: 2 }}>
                    <h1 className={styles.heading}>{renderLabel('LOGIN')}</h1>
                    {renderLabel('WELCOME_BACK_LOGIN')}
                    <LoginForm />
                </Paper>
            </Container>
        </div>
    );
}

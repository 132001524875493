import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { InputComponentProps } from './InputComponentProps';
import styles from './InputText.module.scss';

export const TextareaField = forwardRef(function TextareaField(
    { focused, className, autoComplete, field, meta, label, required, type, labelProps }: InputComponentProps<string>,
    inputRef?: ForwardedRef<HTMLInputElement>
) {
    const { hasError } = meta;
    const { value } = field;

    const labelTop = focused || !!value;

    return (
        <div
            className={clsx(styles.formField, {
                [styles.focused]: focused,
                [styles.labelTop]: labelTop,
                [styles.hasError]: hasError,
            }, className)}
        >
            <label {...labelProps} className={styles.label}>
                {label}
            </label>
            <TextareaAutosize {...(field as any)} className={clsx(styles.input, styles.textareaField)} ref={inputRef} />
        </div>
    );
});

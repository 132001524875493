import { MENU_ACTIONS } from "store/actionTypes";
import { menuState } from "./baseMenuState";

export default function menus(state = menuState, action) {
	switch (action.type) {
		case MENU_ACTIONS.SET_MENU: {
            const { slug } = action.payload;
			return {
                ...state,
                [slug]: action.payload,
            }
		}
		case MENU_ACTIONS.SET_MENUS: {
			if(action.payload && Object.keys(action.payload).length > 0){
				return action.payload;
			}
			return state;
		}
		default:
			return state;
	}
}

import { Box, Container } from '@mui/material';
import React from 'react';
import { FETCH_STATUS } from 'types/types';
import { ReactPageHeader } from 'views/ReactPage/ReactPageHeader';

export function NotFound() {
    return (
        <Box>
            <ReactPageHeader status={FETCH_STATUS.ERROR} />
        </Box>
    );
}

import axios, { AxiosError, AxiosResponse } from 'axios';
import { isProduction } from 'helpers/env';
import canUseDOM from '../../helpers/canUseDom';

const baseURL = '';

let fetcher = axios.create({ baseURL });

const clientIntercepter = (response: AxiosResponse<any, any>) => {
    if (response?.data) return response.data;
    return response;
};

interface ErrorsItem {
    path: string[];
    message: string;
    name: string;
}

class FetchError extends Error {
    details: any;
    errors: ErrorsItem[];
    status: number;
    constructor({ message, name, status, details }: any = {}) {
        super();
        this.name = name || 'FetchError';
        this.message = message || 'Fetch error';
        this.errors = details?.errors || [];
        this.status = status;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchError);
        }
    }
}

class CustomEndpointError extends Error {
    status: number;

    constructor({ message, name, status, details }: any = {}) {
        super();
        this.name = 'ERROR';
        this.message = message;
        this.status = status;

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomEndpointError);
        }
    }
}

const clientErrorInterceptor = (error: AxiosError<any, any>) => {
    const message = error?.response?.data;
    const status = error?.response?.status;

    if (!canUseDOM()) {
        import(/* webpackIgnore: true */ 'server/services/errors').then(({ saveAxiosError }) =>
            saveAxiosError(error, { config: error.config, code: error.code })
        );
    }

    if (error?.response?.data?.error) {
        throw new FetchError(error?.response?.data?.error);
    }

    if (typeof message === 'string' && status) {
        throw new CustomEndpointError({ message, status });
    }

    throw error;
};

fetcher.interceptors.response.use(clientIntercepter, clientErrorInterceptor);

type Fetcher<TResponse> = {
    get: (...args) => Promise<TResponse>;
    post: (...args) => Promise<TResponse>;
    put: (...args) => Promise<TResponse>;
    delete: (...args) => Promise<TResponse>;
};

export function getFetcher<TResponse = any>() {
    return fetcher as Fetcher<TResponse>;
}

export function setupFetcher() {
    return (req: any, res: any, next: any) => {
        let baseURL = `${req.protocol}://${req.hostname}:${process.env.PORT}`;

        if (isProduction()) {
            baseURL = 'https://codyandhenry.com';
        }
        fetcher = axios.create({ baseURL });
        fetcher.interceptors.response.use(clientIntercepter, clientErrorInterceptor);
        req.fetcher = fetcher;
        next();
    };
}

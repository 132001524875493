import React from 'react';
import { Container, Grid, ThemeProvider, Box } from '@mui/material';
import getTheme, { PRIMARY_COLOR_LIGHT } from 'theme';
import { AppFooterMenu } from './AppFooterMenu';
import { useFooterSelections } from './useFooterSelections';
import { useLoggedInAdmin } from 'hooks/useLoggedInUser';
import { LogoutButton } from 'components/LogoutButton/LogoutButton';
export function AppFooter() {
    const { menus } = useFooterSelections();
    const isAdmin = useLoggedInAdmin();

    return (
        <ThemeProvider theme={getTheme('dark')}>
            <Box
                pt={{ xs: 1, sm: '24px' }}
                sx={{
                    backgroundColor: 'var(--blue-200)',
                    color: '#fff',
                    pb: 4,
                    a: { color: '#fff', fontSize: 16 },
                    textAlign: { xs: 'center', sm: 'left' },
                }}
            >
                <Container maxWidth="lg">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md="auto">
                            <AppFooterMenu menu={menus.primary} />
                        </Grid>
                        <Grid item xs={12} md="auto">
                            <AppFooterMenu menu={menus.user} />
                            {isAdmin && <LogoutButton />}
                        </Grid>
                        {isAdmin && (
                            <Grid item xs={12} md="auto">
                                <AppFooterMenu menu={menus.admin} menuId="admin" />
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

import { SET_SITE_CONFIG } from "store/actionTypes";

export default function siteConfigReducer(state = {}, action) {
	switch (action.type) {
		case SET_SITE_CONFIG: {
			return action.payload;
		}
		default:
			return state;
	}
}

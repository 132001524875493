import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { MediaDto } from 'dtos/MediaDto';
import { useRouteMatch } from 'react-router-dom';

type SiteMetaProps = {
    image?: MediaDto;
    title?: string;
    description?: string;
    favicon?: MediaDto | string;
};

const getBaseUrl = (url: string) => {
    return ['https://codyandhenry.com', url].join('');
};

const getImageURL = (url: string) => {
    const imgUrl = new URL('https://codyandhenry.com');
    imgUrl.pathname = url;
    return imgUrl.toString();
};

const DEFAULT_TITLE = 'Cody Suher & Henry Latour';

const getTitleProp = (title?: string) => {
    return [stripHtml(title), DEFAULT_TITLE].filter(Boolean).join(' | ');
};

const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
const stripHtml = (str: string = '') => {
    return str.replace(htmlRegexG, '');
};

function renderMetaImage(image?: MediaDto) {
    if (image) {
        return [
            <meta key={1} property="og:image" content={getImageURL(image.url)} />,
            <meta key={2} property="og:image:type" content={image.mime} />,
            <meta key={3} property="og:image:width" content={`${image.width}px`} />,
            <meta key={4} property="og:image:height" content={`${image.height}px`} />,
        ];
    }

    return [
        <meta key={1} property="og:image" content="https://codyandhenry.com/uploads/Cody_And_Henry_932ebbfd87.jpeg" />,
        <meta key={2} property="og:image:type" content="image/jpeg" />,
        <meta key={3} property="og:image:width" content="2400px" />,
        <meta key={4} property="og:image:height" content="1600px" />,
    ];
}

export default function SiteMeta({ image, title, description, favicon }: SiteMetaProps) {
    const match = useRouteMatch();

    return (
        <Helmet key={Math.random()}>
            <title>{getTitleProp(title)}</title>
            <meta property="og:title" content={getTitleProp(title)} />
            {description && <meta name="description" content={stripHtml(description)} />}
            {renderMetaImage(image)}
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href={getBaseUrl(match.url)} />
        </Helmet>
    );
}

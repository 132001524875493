import { USER_ACTIONS } from '../actionTypes';
import { AppThunk } from '../../types/types';
import { getFetcher } from 'store/actions/fetcher';
import { UserDto } from 'dtos/UserDto';
import { removeCookie, setCookie } from 'hooks/useCookie';

const STORAGE_KEY = 'auth';

interface SuccesResponse {
    jwt: string;
    user: UserDto;
}

export interface LoginPayload {
    email: string;
    password: string;
}

function login({ email, password }: LoginPayload): Promise<SuccesResponse> {
    return getFetcher().post('/api/auth/local', { identifier: email, password });
}

function register(user: UserDto): Promise<SuccesResponse> {
    return getFetcher().post('/api/auth/local/register', user);
}

function getUserById(id: number): Promise<UserDto> {
    return getFetcher().get(`/api/users/${id}`, {
        params: {
            populate: 'deep,2',
        },
    });
}

export function getLoginStatus(jwt: string) {
    return getFetcher().get('/api/users/me', {
        params: {
            fields: ['id', 'fullName'],
            populate: ['role'],
        },
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    });
}

export function initializeUser(jwt: string): AppThunk<Promise<void>> {
    return async (dispatch) => {
        try {
            const user = await getLoginStatus(jwt);
            dispatch({ type: USER_ACTIONS.SET_USER, payload: user });
        } catch (err) {
            return undefined;
        }
    };
}

export function loginUser(payload: LoginPayload): AppThunk<Promise<UserDto>> {
    return async (dispatch) => {
        const { jwt, user } = await login(payload);
        setCookie('auth', jwt, 15);
        dispatch({ type: USER_ACTIONS.LOGIN, payload: user });
        return user;
    };
}

export function logoutUser(): AppThunk<void> {
    return (dispatch, getState) => {
        getFetcher().post(`/api/logout`);
        return dispatch({
            type: USER_ACTIONS.LOGOUT,
        });
    }
}

const createRegisterPayload = (payload: any): any => {
    const { fullName } = payload;
    const username = (fullName || '')
        .split(' ')
        .map((part) => part.trim().toLowerCase())
        .join('.');
    return { ...payload, username };
};

export function registerUser(payload: UserDto): AppThunk<Promise<UserDto>> {
    return async (dispatch) => {
        const { jwt, user } = await register(createRegisterPayload(payload));
        setCookie('auth', jwt, 15);
        dispatch({
            type: USER_ACTIONS.REGISTER,
            payload: user,
        });
        return user;
    };
}

import React, { createContext, useState, useEffect, ReactNode, useContext, useCallback } from 'react';

import { getFetcher } from '../../../store/actions/fetcher';
import { GuestDto } from '../../../dtos/GuestDto';

export interface GuestOption {
    label: string;
    value: number;
}

const fetchGuestOptions = (): Promise<GuestOption[]> => {
    return getFetcher().get('https://codyandhenry.com/api/options/guests');
};

function saveGuest(fullName: string): Promise<GuestDto> {
    return getFetcher().post('/api/guests', { data: { fullName } });
}

interface GuestsContextInterface {
    guests: GuestOption[];
    setGuests: (guests: GuestOption[]) => void;
    createGuest: (name: string) => Promise<GuestOption | undefined>;
}

const GuestsContext = createContext<GuestsContextInterface | undefined>(undefined);

export function useGuestsContext() {
    const context = useContext(GuestsContext);

    if (!context) {
        throw new Error('Guests Provider not rendered');
    }

    return context;
}

interface GuestsProviderProps {
    children: ReactNode | ReactNode[];
}

export function GuestsProvider({ children }: GuestsProviderProps) {
    const [guests, setGuests] = useState<GuestOption[]>([]);

    const createGuest = useCallback(async (fullName: string) => {
		const data = await saveGuest(fullName);
		if (data && data.id) {
			const newGuest = { label: fullName, value: data.id };
			setGuests(prev => [...prev, newGuest]);
			return newGuest;
		}
		return undefined;
    }, []);

    useEffect(() => {
        fetchGuestOptions().then(setGuests);
    }, []);

    return <GuestsContext.Provider value={{ guests, setGuests, createGuest }}>{children}</GuestsContext.Provider>;
}

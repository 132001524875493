import { MediaDto } from 'dtos/MediaDto';
import { ATTACHMENT_ACTIONS } from '../actionTypes';
import { AnyAction } from 'redux';
import { uniqueArray } from 'utils/uniqArray';

export function attachmentsReducer(state: MediaDto[] = [], action: AnyAction) {
    switch (action.type) {
        case ATTACHMENT_ACTIONS.SET_ATTACHMENTS: {
            return action.payload;
        }
        case ATTACHMENT_ACTIONS.SET_ATTACHMENT: {
            return state.map((attachment) => {
                if (attachment.id === action.payload?.id) {
                    return action.payload;
                }
                return attachment;
            });
        }
        case ATTACHMENT_ACTIONS.ADD_ATTACHMENTS: {
            return uniqueArray([...state, ...action.payload]);
        }
        case ATTACHMENT_ACTIONS.DELETE_ATTACHMENT: {
            return state.filter(attachment => attachment.id !== action.payload);
        }
        default: 
            return state;
    }
}

import React from 'react';
import { useSelector } from 'react-redux';
import { AppHeaderResponsive } from './AppHeaderResponsive';

function getPrimaryMenu(state: any) {
    return state.menus?.['primary'] || {};
}

export function AppHeader() {
    const state = useSelector(getPrimaryMenu);
    return <AppHeaderResponsive pages={state.items} />;
}
